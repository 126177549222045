import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {getInspectionResult, deleteInspectionImage} from "../../services/inspectionService"
import {FaTrashAlt, FaEdit} from "react-icons/fa";

class InspectionModalViewImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      urls: [],
    }
  }

  componentDidMount() {
    // Do this componentDidMount InspectionModalViewImagesonly first time
    this.setModalState()
    this.init()
  }

  init() {
  }


  componentDidUpdate(prevProps) {
    // Do this componentDidUpdate only props change
    if (prevProps.id !== this.props.id) {
      console.log("componentDidUpdate InspectionModalViewImages only props change")
      this.setModalState()
    }
  }

  setModalState() {
    this.setState({id: this.props.id, name: this.props.name},() => {
      this.getPictureUrls()
    });
  }
 
  getPictureUrls = () => {
    getInspectionResult(this.state.id).then((result)=>{
      this.setState({urls: result.data.data.attributes.attach_image_urls});
    })
  }

  handleDeleteClick = (id)  => {
    const confirmBox = window.confirm(
      "Haluatko poistaa?"
    )
    if (confirmBox === true) {
      deleteInspectionImage(id).then((result) => {
        if (result.data.result === "ok") {
          this.getPictureUrls()
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-8 text-left">
            <h2>{this.state.name}</h2>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <button className="btn btn-info"  onClick={this.props.closeModal}>X sulje</button>
          </div>
        </div>
        <div className="w-100 d-flex flex-wrap">
          {this.state.urls.map((url, idx) => (
              <div  key={idx} >
                <div
                  className="position-relative border p-2 m-1"
                  style={{
                    height: "200px",
                    width: "200px",
                  }}
                >
                  <img
                    src={url.url}
                    alt={url.id}
                    className="cover-img"
                  />
                  <div
                    onClick={()=>this.handleDeleteClick(url.id)}
                    className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
                  >
                    <FaTrashAlt />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

InspectionModalViewImages.propTypes = {

};

export default InspectionModalViewImages;