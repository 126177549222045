const env_variable = {
  auth_api_url: process.env.REACT_APP_AUTH_API_URL,
  auth_api_path: process.env.REACT_APP_AUTH_API_ENDPOINT,
  easyad_api_url: process.env.REACT_APP_EASYAD_API,
  logistics_api_url: process.env.REACT_APP_LOGISTICS_API,
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_scope: process.env.REACT_APP_CLIENT_SCOPE,
  redirect_url: process.env.REACT_APP_REDIRECT_URL,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  google_api_key: process.env.REACT_APP_GOOGLE_MAP_DIRECTIONS_API_KEY,
};

export default env_variable;
