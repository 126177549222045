import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FaPenAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { dtUtcToLocalString, tzUtcToLocalHm, secToHMString, dtToYMD } from '../../services/formatService';
import { saveWorkActual } from '../../services/logisticsService';

export const AlertDayEnd = (props) => {
  const [show, setShow] = useState(props.show);
  const [selectOption, setSelectOption] = useState(null);
  const [message, setMessage] = useState(props.message);
  const [type, setType] = useState(props.type);

  useEffect(() => {
    setShow(props.show);
  }, [props]);

  useEffect(() => {
    if (show !== props.show) {
      props.setShow(show, selectOption);
    }
  }, [show]);

  useEffect(() => {
    // setShow(false)
    props.setShow(show, selectOption);
  }, [selectOption]);

  const saveTaskDescription = (e) => {
    console.log('handleChangeTaskDescription', e.target.value);
    let params = {
      date: dtToYMD(new Date()),
      day_task_description: e.target.value,
    }
    saveWorkActual(params).then((result) => {
      console.log("handleChangeTaskDescription result", result);
    });
  }

  return (
    <>
      <Alert show={show} variant='primary'>
        <div className="d-flex justify-content-between">
          <Alert.Heading>Päivän yhteenveto</Alert.Heading>
          <Button onClick={() => setShow(false)} >
            X 
          </Button>
        </div>
        <hr />
    
        <div className="d-flex justify-content-between">
          <div>
          {message}
          </div>
          <div>
            <Button className='me-1 btn-sm' onClick={() => setSelectOption(1)} variant={selectOption === 1 ? 'primary' : 'outline-primary'}>
            {props.option1}
            </Button>
            <Button className='me-1 btn-sm' onClick={() => setSelectOption(2)} variant={selectOption === 2 ? 'primary' : 'outline-primary'}>
            {props.option2}
            </Button>
          </div>
        </div>
        <div className="">
          <div>Tehtävä:</div>
          <textarea
            rows="2"
            className="form-control no-shadow-btn  mb-1 input, image, both"
            placeholder='Tehtäväkuvaus'
            onKeyUp={saveTaskDescription}
          />
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          
          {props.daySummary && <div>
            <strong>Aika: {dtUtcToLocalString(props.daySummary.start_time)} - {tzUtcToLocalHm(props.daySummary.stop_time)} </strong><br />
            <strong className='text-sm'>
              Päivälisä(6-18) {secToHMString(props.daySummary.day_time)} 	<br />
              Iltalisä(18-23)	{secToHMString(props.daySummary.evening_time)} <br />
              Yölisä(23-6) {secToHMString(props.daySummary.night_time)} </strong>
          </div>}
          <div>
          <Link to={`/admin/user`} className="btn btn-warning me-1 text-sm">
             <FaPenAlt /> Korjaa aika</Link>
          </div>
        </div>
      </Alert>
    </>
  );
}