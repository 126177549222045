import React, { useState, useEffect } from 'react';
import {FaSave, FaAngleRight, FaReceipt, FaPaperPlane, FaFilePdf, FaAngleLeft} from "react-icons/fa";
import { dayForInput, isPresent } from "../../services/formatService";
import {getCurrentUser} from "../../services/authService";
import { ClipLoader } from "react-spinners";
import { createInvoice, getInvoice, updateInvoice, netvisorStatusText } from '../../services/invoiceService';
import InvoiceAttachImage from './InvoiceAttachImage';
import pdfIcon from "../../images/pdf-icon.svg";
import Select from 'react-select';

const InvoiceModalForm = props => {
  const [invoice, setInvoice] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [isStep2, setIsStep2] = useState(false);
  const [isStep2Ready, setIsStep2Ready] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [receiptUrls, setReceiptUrls] = useState([]);

  useEffect(() => {
    if (props.invoice) {
      console.log("props.invoice", props.invoice)
      setIsDisable(true);
      setInvoice(props.invoice)
      getInvoice(props.invoice.id).then((result)=>{
        console.log("getInvoice result", result);
        if (result) {
          setReceiptUrl(result.attach_picture_url)
          setPdfUrl(result.attach_pdf_url)
          setReceiptUrls(result.receipt_urls)
        }
      })
    } else {
      setIsDisable(false);
      setInvoice({
                  invoice_number: null,
                  invoice_type: "logistiikka",
                  invoice_date: dayForInput(),
                  amount: null,
                  bank_reference_number: "autolle kululasku logistiikka",
                  reason: "",
                  shop_name: "",
                  task_id: null,
                  plate: "",
                  vat: 24,
                  buyer: {
                      city: props.city,
                      iban: props.iban,
                      name: getCurrentUser().fullname,
                      email: getCurrentUser().email,
                      phone: getCurrentUser().phone,
                      post_number: props.postNumber,
                      address_line: props.addressLine
                  },
                  status: "draft",
                  product_code: "4030",
      })
    }

  }, [props]);

  useEffect(() => {
    // Wait to get the invoice id from the server before start step 2 (attach image)
    if (invoice && invoice.id && isStep2===true) {
      setIsStep2Ready(true);
    } else {
      setIsStep2Ready(false);
    }
  }, [invoice, isStep2]);

  useEffect(() => {
    console.log("isStep2Ready", isStep2Ready)
  }, [isStep2Ready]);

  const pageTitle = props.modalType === "new" ? "Uusi lasku" : `Lasku`
  const divStyle = {
    fontSize: '12px',
    minHeight: '100vh'
  };

  const handleChangeSelect = (e) => {
    console.log("handleChangeSelect", e)
    setInvoice({ ...invoice, ['product_code']: e.value });
  };

  const handleChange = (e) => {
    setInvoice({ ...invoice, [e.target.name]: e.target.value });
  };
  const handleChangeBuyer = (e) => {
    let buyer = {...invoice["buyer"], [e.target.name]: e.target.value}
    setInvoice({ ...invoice, ["buyer"]: buyer });
  };
  const handleSave = () => {
    console.log("handleSave invoice", invoice)
    setIsSaving(true);
    if (invoice.id) {
      setIsStep2(true)
    } else {
      createInvoice(invoice, null).then((result)=>{
        console.log("handleSave result", result)
        if (result.result === 'success') {
          setInvoice({ ...invoice, ...{id: result.data.id, invoice_number: result.data.invoice_number}});
          setErrorText(null);
        } else if ( result.result === 'error') {
          if (result.data) {
            setErrorText(result.data.join(", "));
          }
        }
        setIsStep2(true)
    });
    }

    setIsSaving(false);
  };
 
  const handleConfirm = () => {
    const updated_invoice = { ...invoice, ["status"]: 'confirm' }
    setInvoice(updated_invoice)
    updateInvoice(updated_invoice).then((result) => {
      console.log("handleConfirm result", result)
    })
  }

  const selectFile = (e) => {
    setFile(e.target.files[0])
  }

  const productOptions = [
    { value: "4030", label: "Ajoneuvojen muut siirtokulut" },
    { value: "4010", label: "Ajoneuvojen poltto- ja voiteluaineet" },
    { value: "40201", label: "Ajoneuvojen fiksaukset" },
    { value: "4000", label: "Muu" },
  ];


  let showReceipts = receiptUrls.map((file, idx) => {
    let imgSrc = file.url;
    if (imgSrc.includes('.pdf')) {
      imgSrc = pdfIcon;
    } 
    return (
      <div key={idx} >
      <div
        className="position-relative border p-2 m-1"
        style={{
          height: "100px",
          width: "100px",
        }}
      >
        <a href={file.url} target="_blank" rel="noreferrer">
          <img
            src={imgSrc}
            alt="Kuitti"
            className="cover-img"
          />
        </a>
      </div>
    </div>
    )
  });



  return (
    <div style={divStyle}> 
      <div className="row">
        <div className="col-8 text-left">
          <h4 className='text-primary'>{pageTitle} {invoice && invoice.invoice_number}</h4>
        </div>
        <div className="col-4 d-flex justify-content-end">
            <button className="btn btn-primary text-white btn-sm"  onClick={props.closeModal}>X sulje</button>
        </div>
      </div>
      {(!isStep2Ready && invoice) && <div className="w-100 rounded-3 px-0">
        <span>{invoice.task_id && `Rek.: ${invoice.task_id}`}</span>
        <div className="mb-1">
          <label className="form-label">
            Ostopäivä *
          </label>
          <input
            type="date"
            className="form-control no-shadow-btn"
            name="invoice_date"
            onChange={handleChange}
            value={invoice.invoice_date}
            disabled={isDisable}
            />
        </div>
        <div className="mb-1">
          <label className="form-label">
            Syy *
          </label>
          <input
            type="text"
            className="form-control no-shadow-btn"
            name="reason"
            onChange={handleChange}
            value={invoice.reason}
            disabled={isDisable}
          />
        </div>        
        <div className="mb-1">
          <label className="form-label">
          Ostopaikka *
          </label>
          <input
            type="text"
            className="form-control no-shadow-btn"
            name="shop_name"
            onChange={handleChange}
            value={invoice.shop_name}
            disabled={isDisable}
          />
        </div>    
        <div className="mb-1">
          <label className="form-label">
          Rekisterinumero *
          </label>
          <input
            type="text"
            className="form-control no-shadow-btn"
            name="plate"
            onChange={handleChange}
            value={invoice.plate}
            disabled={isDisable}
          />
        </div>    
        <div className="mb-1">
          <label className="form-label">Oletustili: {invoice.product_code}</label>
          <Select
            name="product_code"
            isDisabled={isDisable}
            options={productOptions}
            onChange={handleChangeSelect}
            value={productOptions.filter(option => option.value === invoice.product_code)}
          />
        </div>    
        <div className="d-flex align-items-center">
        <div className="mb-1">
          <label className="form-label">
          Määrä € (sis. alv.) *
          </label>
          <input
            type="number"
            className="form-control no-shadow-btn"
            name="amount"
            onChange={handleChange}
            value={invoice.amount}
            disabled={isDisable}
          />
        </div>   
        <div className="mb-1">
          <label htmlFor="vat" className="form-label">
          %Alv. (10, 12, 24) * 
          </label>
          <input
            type="number"
            className="form-control no-shadow-btn"
            name="vat"
            onChange={handleChange}
            value={invoice.vat}
            disabled={isDisable}
          />
        </div>   
        </div>   
        {(receiptUrls.length > 0) &&
              <aside>
                <h5>Ladatut kuitit ({receiptUrls.length})</h5>
                <div className="w-100 d-flex flex-wrap">{showReceipts}</div>
              </aside>}
        {receiptUrl &&
          <div className="mb-2">xx{receiptUrls.length}
            <label className="form-label">
            Kuitit
            </label>
            <br />
            <img src={receiptUrl} alt="Kuitti"  width="120"/> 
            {/* <input
              type="file"
              className="form-control no-shadow-btn"
              name="receipt_picture"
              onChange={selectFile}
            /> */}
          </div>}
          {pdfUrl && ( <a target='_blank' href={pdfUrl}>
                        <FaFilePdf />
                        Näytä kuitti (PDF)
                      </a>) }

          {invoice.netvisor_id 
          ? <div className="my-2">
            <label style={{fontWeight: 'bold'}} className="me-2">Netvisor id: {invoice.netvisor_id} </label>
            <label className="me-2">Tila: {netvisorStatusText(invoice.netvisor_approval_status)}</label>
            <label className="me-2">{(invoice.netvisor_paid_amount > 0) ? `Maksettu${invoice.netvisor_paid_amount}`: ''}</label><br />
          </div> 
          : <></> 
          }
        <hr />
        <h6 className='text-primary'>Saaja:  {invoice.buyer.name} / {invoice.buyer.email} </h6>
        <input type="hidden" className="form-control no-shadow-btn" 
              name="name"
              value={invoice.buyer.name}
              />
        <input type="hidden" className="form-control no-shadow-btn" 
          name="email"
          value={invoice.buyer.email}/>              
        <div className="d-flex align-items-center mt-2">
          <div className="mb-2">
            <label className="form-label">Phone *</label>
            <input type="text" className="form-control no-shadow-btn" 
              name="phone"
              onChange={handleChangeBuyer}
              value={invoice.buyer.phone}
              disabled={isDisable}/>
          </div>   
          <div className="mb-2 flex-fill">
          <label className="form-label">IBAN (FI000111234567) *</label>
          <input type="text" className="form-control no-shadow-btn" 
            name="iban"
            onChange={handleChangeBuyer}
            value={invoice.buyer.iban}
            disabled={isDisable}/>
        </div>   
        </div>


        <div className="d-flex align-items-center">
          <div className="mb-2">
            <label className="form-label">Osoite *</label>
            <input type="text" className="form-control no-shadow-btn" 
              name="address_line"
              onChange={handleChangeBuyer}
              value={invoice.buyer.address_line}
              disabled={isDisable}/>
          </div>
          <div className="mb-2">
            <label className="form-label">Postinumero *</label>
            <input type="text" className="form-control no-shadow-btn" 
              name="post_number"
              onChange={handleChangeBuyer}
              value={invoice.buyer.post_number}
              disabled={isDisable}/>
          </div>   
          <div className="mb-2">
            <label className="form-label">Kaupunki *</label>
            <input type="text" className="form-control no-shadow-btn" 
              name="city"
              onChange={handleChangeBuyer}
              value={invoice.buyer.city}
              disabled={isDisable}/>
          </div>
        </div>
        <div className="d-flex align-items-center ">


        </div>
        {errorText && <div className="text-danger w-100 text-center">{errorText}</div>}
        <div className="my-3">
          {(invoice.status === 'confirm' && invoice.netvisor_id) 
            ? <button className="w-100 h-100 btn btn-primary align-items-center text-white"  onClick={props.closeModal}>X sulje</button>
            : <div className='d-flex align-items-center justify-content-center'>
                <button
                  onClick={handleSave}
                  className="btn btn-primary align-items-center px-5 me-1"
                  disabled={!isPresent(invoice.reason) || !isPresent(invoice.shop_name) || !isPresent(invoice.plate) || !isPresent(invoice.amount)}
                  // disabled={ !isPresent(invoice.id) || ( !isPresent(invoice.reason) && !isPresent(invoice.amount) && !isPresent(invoice.plate) && !isPresent(invoice.shop_name)) }
                >
                  { isSaving ? <ClipLoader color={'#ffffff'} loading={true} css={''} size={15}/> : <FaReceipt color={'#ffffff'} />}
                  <span className="ms-1 text-white" >
                    {isSaving 
                      ? 'Tallentaa' 
                      : (receiptUrl || pdfUrl) 
                        ? 'Vaihta kuitin kuva' 
                        : 'Tallenna ja jatka'}</span>
                </button>
                {(receiptUrl || pdfUrl) && <button
                  onClick={handleConfirm}
                  className="btn btn-danger align-items-center  px-5"
                  >
                    { isSaving ? <ClipLoader color={'#ffffff'} loading={true} css={''} size={15}/> : <FaPaperPlane color={'#ffffff'} />}
                    <span className="ms-1 text-white" >{isSaving ? 'Tallentaa' : 'Vahvista'}</span>
                </button>}
            </div>
          }
        </div>
      </div>}
      {(isStep2Ready && invoice) && 
        <InvoiceAttachImage 
          invoiceId={invoice.id} i
          nvoiceNumber={invoice.invoice_number} 
          closeModal={props.closeModal} 
          receiptUrl={receiptUrl} 
          pdfUrl={pdfUrl} 
          receiptUrls={receiptUrls}
          setIsStep2={setIsStep2}
          />
      }
    </div>
  )
}

export default InvoiceModalForm;