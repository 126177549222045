import React from "react";
import { Link } from "react-router-dom";
import logo_black from "../images/autolle_logo-black.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="w-100 border-top py-2">
          <div className="row">
            <div className="col-12">
              <div className="mt-2 d-flex align-items-center">
                <Link className="navbar-brand" to="/admin">
                  <img src={logo_black} alt="" height="20" />
                </Link>
                <p className="fs-12 mb-0 mt-3">Copyright &copy;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
