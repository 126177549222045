import React from "react";
import { Link } from "react-router-dom";
import logo_black from "../../images/autolle_logo-black.svg";
import auth from "../../services/authService";
import { FaSignOutAlt, FaCalendarAlt, FaBusinessTime, FaHome, FaMoneyBillWave } from "react-icons/fa";
const AdminDrawerLinks = ({ drawerOpen, setDrawerOpen, adminNavNames }) => {
  const currentUser = auth.getCurrentToken();
  const user = currentUser ? currentUser.user : null;
  const fullname = user ? user.fullname : null;
  const handleClick = () => {
    auth.logout();
    window.location = "/";
  };

  return (
    <div className="w-100 max-width-280 height-100 bg-primary text-white">
      <div
        className={
          drawerOpen
            ? `height-72 d-flex align-items-center justify-content-between p-3 border-bottom`
            : `d-none`
        }
      >
        <Link className="navbar-brand" to="/admin">
          <img src={logo_black} alt="" height="20" />
        </Link>
        {/* <div className="box-size-30 d-flex align-items-center fs-20">
          <FaTimes />
        </div> */}
      </div>
      <ul
        className={
          drawerOpen
            ? "w-100 list-unstyled drawer-delay"
            : "hide-drawer-content"
        }
      >
        {adminNavNames.map((item) => (
          <li className="d-flex border-bottom py-3 px-3 " key={item.label}>
            <Link
              className="w-100 d-block-inline text-decoration-none text-white"
              to={`/admin/${item.url}`}
              key={item}
            >
              {item.label}
            </Link>
          </li>
        ))}
        <li className="d-flex border-bottom py-3 px-3 ">
          <Link
            className="w-100 d-block-inline text-decoration-none text-white"
            to="/admin"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <FaHome />
            <span className="ms-1">Etusivu</span>
          </Link>
        </li>
        <li className="d-flex border-bottom py-3 px-3 ">
          <Link
            className="w-100 d-block-inline text-decoration-none text-white"
            to="/admin/invoice"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <FaMoneyBillWave />
            <span className="ms-1">Kululasku</span>
          </Link>
        </li>
        <li className="d-flex border-bottom py-3 px-3 ">
          <Link
            className="w-100 d-block-inline text-decoration-none text-white"
            to="/admin/report"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <FaBusinessTime />
            <span className="ms-1">Raportti</span>
          </Link>
        </li>
        <li className="d-flex border-bottom py-3 px-3 ">
            <Link
              className="w-100 d-block-inline text-decoration-none text-white"
              to="/admin/user"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <FaCalendarAlt />
              <span className="ms-1">Työaikataulu</span>
            </Link>
          </li>
        <li className="d-flex border-bottom py-3 px-3 ">
            <Link
              className="w-100 d-block-inline text-decoration-none text-white"
              onClick={handleClick}
            >
              <FaSignOutAlt />
              <span className="ms-1">Kirjaudu ulos</span>
            </Link>
          </li>
      </ul>
    </div>
  );
};

export default AdminDrawerLinks;
