import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FaPenAlt, FaEye, FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { saveTask, statusText } from "../../services/logisticsService";

export const AlertDayStart = props => {
  const [show, setShow] = useState(null);
  const [startedId, setStartedId] = useState(null);

  useEffect(() => {
    setShow(props.show);
  }, [props]);

  // useEffect(() => {
  //   if (show !== props.show) {
  //     props.setShow(show);
  //   }
  // } , [show]);

  const startClick = (id) => {
    console.log(startClick, id)
    setStartedId(id);
    saveTask(id, 'suoritetaan').then((response) => {
      console.log("saveTask response", response)
    });
  }

  return (
    <div key={props.key}>
      <Alert show={show} variant='success'>
        <div className="d-flex justify-content-between">
          <Alert.Heading>Valitse tehtävä ({props.tasks && props.tasks.length})</Alert.Heading>
          {/* <Button onClick={() => setShow(false)} variant="outline-success">X </Button> */}
        </div>
        <hr />
        {props.tasks && props.tasks.map(task => (
          <div className="d-flex mt-1 overflow-hidden align-items-center">
            {((startedId === task.id) ||(task.status === "suoritetaan")) ? (
              <Link to={`/admin/task/${task.id}`} className="btn btn-danger me-1 text-sm">
                <FaPenAlt /> Toimituksessa</Link>
            ) : (
              <Button className="text-sm me-1" onClick={() => startClick(task.id)} variant="success">
                <FaPlay /> Alkaa nyt
              </Button>)}
            <Link className="text-sm" to={`/admin/task/${task.id}`}>
              {task.id}: {task.target_reg_num} {task.pickup_location}{task.delievery_location ? `-${task.delievery_location}` : ''}
            </Link>
          </div>
        ))}
      </Alert>
    </div>
  );

}