import React , {useState, useEffect} from 'react';
import { secToHourMinute } 
from "../../services/formatService";

const InputHourMinute = props => {
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);


  useEffect(() => {
    const hourMinute = secToHourMinute(props.sec)
    setHour(hourMinute[0])
    setMinute(hourMinute[1])
  }, [props]);

  useEffect(() => {
    if (props.setChangeSec) {
      props.setChangeSec((hour * 3600) + (minute * 60))
    }
  }, [hour, minute ]);

  return (
        <div className="d-flex align-items-center justify-content-start " >
          <div className="" >
            <input
              type="number"
              className="form-control no-shadow-btn"
              name="hour"
              onChange={e => setHour(e.target.value)}
              value={hour}
              disabled={props.disabled}
            /> 
          </div>
          <div className="" > : </div>
          <div className="" >                   
            <input
              type="number"
              className="form-control no-shadow-btn"
              name="minute"
              onChange={e => setMinute(e.target.value)}
              value={minute}
              disabled={props.disabled}
            />
          </div>
        </div>
)
};
export default InputHourMinute;