import React , {useState, useEffect} from 'react';

const InputText = props => {
  const [textValue, setTextValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
    placeholder: '',
  });
  const notValidStyle = {borderColor: 'red', borderWidth: 1}

  useEffect(() => {
    const getDefaultValue = defaule_code => {
      const default_values = props.inspectionStartInfo
       // 'PLATE, MAKE, MILEAGE, MODEL, MODEL_YEAR'
      switch (defaule_code) {
        case 'PLATE':
          return default_values.plate
        case 'MAKE':
          return default_values.make
        case 'MILEAGE':
          return default_values.mileage
        case 'MODEL':
          return default_values.model
        case 'MODEL_YEAR':
          return default_values.model_year
        default:
          return defaule_code
      }
    }
    if (!textValue.value && props.item.inspection_result.data.attributes.auto_fill==="inspection") {
      setTextValue({ ...textValue, value: getDefaultValue(props.item.default_value)});
    }
  }, []);

  const handleChange = (e) => {
    setTextValue({ ...textValue, value: e.target.value });
    props.setFormResults({ ...props.formResults, [e.target.name]: e.target.value });
  };

  return (
    <>
       <textarea
          rows="4"
          className="form-control no-shadow-btn  mb-1 input, image, both"
          value={textValue.value}
          name={props.item.inspection_result.data.id}
          onChange={handleChange}
          style={props.mandatoryInputs.includes(props.item.inspection_result.data.id) ? notValidStyle : null}
          placeholder={textValue.placeholder}
        />
    </>
  );
};

export default InputText;