import React from "react";
import {FaTrashAlt, FaEdit, FaFilePdf} from "react-icons/fa";
import {parseISOString, isoFormatDMY} from "../../services/formatService"
import {Link} from "react-router-dom";

const InspectionRow = ({ inspection, setInspections, inspections }) => {

  const inspection_detail = inspection.attributes

  const inspection_status = {
    waiting_for_inspection: "Odottaa tarkastusta",
    waiting_for_acceptance: "Odottaa hyväksymistä",
    accepted: "Valmis"
  } 
  const deleteInspection = (id) => {
    console.log("deleteInspection", id)
    // const new_inspections = inspections.map(inspection => {
    //   if(inspection.id === id){
    //     const old_inspection = inspection;
    //     old_inspection.attributes.mileage = "123"
    //     return old_inspection;
    //   }
    //   return inspection
    // })

    // setInspections(new_inspections)
  }
  return (
    <tr className="border border-start-0  border-end-0">
        <td>
          <Link
                to={{
                  pathname: "/admin/inspection_start",
                  state: {
                    displayType: "edit" ,
                    plate: inspection_detail.plate,
                    place_name: inspection_detail.place_name,
                    phase_name: inspection_detail.phase_name,
                    make: inspection_detail.make,
                    model: inspection_detail.model,
                    vm: inspection_detail.model_year,
                    mileage: inspection_detail.mileage,
                    id: inspection_detail.id,
                    info: inspection_detail.info,
                  }
                }}
                >
                    <button
                      className="map-button w-100 h-100 btn btn-secondary"
                      >
                      <FaEdit />
                    </button>
              </Link>
      </td>
      <td>
        { inspection_detail.status==="accepted" && (
          <Link
            to={{
              pathname: "/admin/inspection_start",
              state: {
                displayType: "edit" ,
                plate: inspection_detail.plate,
                place_name: inspection_detail.place_name,
                phase_name: inspection_detail.phase_name,
                make: inspection_detail.make,
                model: inspection_detail.model,
                vm: inspection_detail.model_year,
                mileage: inspection_detail.mileage,
                id: inspection_detail.id,
                info: inspection_detail.info,
              }
            }}
            >
                <button
                  className="map-button w-100 h-100 btn btn-secondary"
                  >
                  <FaFilePdf />
                </button>
          </Link>
        )
        }
      </td>
      {/* <td>
        <div 
        className="d-flex align-item-center justify-content-center border border-primary rounded-3 hoverable-bg ">
        <button
            className="btn fs-16 border-none text-primary no-shadow-btn outline-none"
                onClick={() => deleteInspection(inspection_detail.id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      </td> */}
      <td>{inspection_detail.plate}</td>
      <td>{inspection_detail.make}</td>
      <td>{inspection_detail.model}</td>
      <td>{inspection_detail.place_name}</td>
      <td>{inspection_detail.phase_step} {inspection_detail.phase_name} {inspection_detail.place_name}</td>
      <td>{inspection_status[inspection_detail.status]}</td>
      <td>
        Luo {isoFormatDMY(parseISOString(inspection_detail.created_at))} 
        <br/> Päivitä {isoFormatDMY(parseISOString(inspection_detail.updated_at))}</td>
      <td>{inspection_detail.status==="accepted" && isoFormatDMY(parseISOString(inspection_detail.updated_at))}</td>  
    </tr>
  );
};

export default InspectionRow;
