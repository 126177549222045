import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from 'react-router-dom';
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "reactstrap";
import auth from "../../services/authService";
import { deleteTask } from "../../services/logisticsService";

const currentUser = auth.getCurrentUser();
const email = currentUser ? currentUser.email : null;

class TaskTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: this.props.tasks,
      table_columns: [
        {
          formatter: this.editButton,
          dataField: 'edit',
          text: 'Edit',
          classes: 'hoverable-bg',
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              e.nativeEvent.preventDefault()
              e.stopPropagation()
              this.editClick(row)
            }
          },
          headerStyle: (column, colIndex) => {
            return { width: '80px' };
          },
          csvExport: false,
        }, {
          formatter: this.deleteButton,
          dataField: 'delete',
          text: 'Delete',
          classes: 'd-flex justify-content-center p-2',
          events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                  e.nativeEvent.preventDefault()
                  e.stopPropagation()
                  this.deleteClick(row)
              }
          },
          csvExport: false,
        }, {
          dataField: 'id',
          text: 'Id',
          classes: 'text-cell text_nowrap'
        }, {
          dataField: 'formatted.delievery_time',
          text: 'Toimitusaika',
          classes: 'text-cell text_nowrap'
        }, {
          dataField: 'formatted.vehicle',
          text: 'Kohde',
          classes: 'text-cell text_nowrap'
        }, {
          dataField: 'formatted.pickup',
          text: 'Nouto osoite',
          classes: 'text-cell text_nowrap'
        }, {
          dataField: 'formatted.delivery',
          text: 'Toimitus osoite',
          classes: 'text-cell text_nowrap'
        }, {
          dataField: 'formatted.status_text',
          text: 'Status',
          classes: 'text-cell text_nowrap'
        }],
      table_row_classes: "text-sm",
      pagination_options: {},
    }
  }

  rowClick(e, row, index) {
    this.props.history.push(
      {
        pathname:   `/admin/task/${row.id}`,
        state: {
          id: row.id,
          formatted: row.formatted
        }
      }
    )
  }

  editClick = (row) => {
    this.props.history.push(
      {
        pathname: '/admin/task/' + row.id,
        state: {
          id: row.id,
          formatted: row.formatted
        }
      }
    )
  }

  deleteClick = (row) => {
    console.log("deleteClick row",row.id)
    if (window.confirm(`Haluatko poistaa ${row.id}?` )) {
      console.log("Start Delete task...")
      deleteTask(row.id).then(() => {
        window.location = "/admin"
      });
    }
  }

  editButton() {
    return <Button className={'map-button btn-sm h-100'}><FaEdit /></Button>
  }

  deleteButton(cell) {
    return <Button className={'map-button btn-sm ßh-100'}><FaTrash /></Button>
  }

  render() {
    return (this.state.tasks?.length > 0 && <BootstrapTable
      key='id'
      keyField='id'
      data={this.state.tasks}
      columns={this.state.table_columns}
      rowClasses={this.state.table_row_classes}
      pagination={paginationFactory(this.state.pagination_options)}
      rowEvents={{ onClick: this.rowClick.bind(this) }}
      classes='bg-white'
      hover
    />)
  }
}

export default withRouter(TaskTable);