import { postRequest, getRequest , deleteRequest, postFormDataRequest} from "./httpServices";
import config from "../config";
import auth from "./authService";
import {parseISOString, isoFormatDMY, dtUtcToLocalString} from "./formatService"
import { func } from "prop-types";

const mainUrl = config.logistics_api_url;
const currentUser = auth.getCurrentUser();
const email = currentUser ? currentUser.email : null;
const fullname = currentUser ? currentUser.fullname : null;
const phone = currentUser ? currentUser.phone : null;


export function tasksUrl() {
  let url = `${mainUrl}/api/v1/tasks?auth=y`;
  return url
}

export function taskUrl(id) {
  let url = `${mainUrl}/api/v1/task/${id}`;
  return url
}

export function driverUrl() {
  let url = `${mainUrl}/api/v1/drivers`;
  return url
}

export async function getTasks(driver = null, status = null, plate = null) {
  let params = {}
  let url = tasksUrl()
  if (driver) {
    url = `${url}&driver_email=${driver}`;
  }
  if (status) {
    url = `${url}&status=${status}`;
  }

  if (plate) {
    url = `${url}&plate=${plate}`;
  }

  let result = await getRequest(url, params);
  if (result) {
    let datas = result['data']['tasks'];
    for (let i = 0; i < datas.length; i++) {
      datas[i].formatted = formatTaskData(datas[i]);
    }
    return datas;
  } else {
    return null;
  }
}

export async function getDrivers() {
  let params = {}
  let url = driverUrl()
  let result = await getRequest(url, params);
  if (result) {
    return result['data']['data'];
  } else {
    return null;
  }
}

export async function getTask(id) {
  let params = {}
  let url = taskUrl(id)
  let result = await getRequest(url, params);
  if (result) {
    return result['data']['task'];
  } else {
    return null;
  }
}

export const statusOptions = [
  { value: 'avoin', label: ' Avoin' },
  { value: 'odottaa_toimitusta', label: ' Odottaa toimitusta' },
  { value: 'suoritetaan', label: ' Alkaa' },
  { value: 'suoritettu', label: ' Päättyy' },
  { value: 'tauolla', label: ' Tauolla' },
  { value: 'toimitettu', label: ' Toimitettu' },
  { value: 'keskeytynyt', label: ' Keskeytynyt' },
  { value: 'all', label: ' Kaikki' },
]

export const statusFormOptions = [
  { value: 'avoin', label: ' Avoin' },
  { value: 'odottaa_toimitusta', label: ' Odottaa toimitusta' },
  { value: 'suoritetaan', label: ' Alkaa' },
  { value: 'suoritettu', label: ' Päättyy' },
  { value: 'keskeytynyt', label: ' Keskeytynyt' },
]


function formatTaskData(data) {
  let status_result = statusOptions.find(x => x.value === data.status);
  let status_text = (status_result) ? status_result.label : data.status
  let delievery_time = (data.delievery_time) ? isoFormatDMY(parseISOString(data.delievery_time))  : ''
  return {
    vehicle: `${data.target_reg_num} ${data.target_manufacturer}`,
    status_text: status_text,
    delievery_time: delievery_time,
    pickup: `${data.pickup_address} ${data.pickup_location}`,
    delivery: `${data.delivery_address} ${data.delivery_location}`,
  }
}


export function statusText(status) {
  let status_result = statusOptions.find(x => x.value === status);
  let status_text = (status_result) ? status_result.label : status

  if (status === 'suoritettu_muokattu') {
    status_text = "Työpäivä päättyy (muokattu)"
  }
  return status_text
}


export async function saveTaskInspection(taskId, phaseId, inspectionId, step, status = null, description = null) {
  // let createParams = Object.assign(dimensionParams, {user_email: email});
  let params = {  id: taskId,
                  phase_id: phaseId,
                  inspection_id: inspectionId, 
                  status: status,
                  step: step,
                  description: description
              }
  let url = `${mainUrl}/api/v1/update_task_inspection`;
  let result = await postRequest(url, params);
  return result;
}


export async function saveTask(
  taskId, 
  status = null, 
  statusId = null, 
  totalSecond = null,
  totalSecondActual = null, 
  reason = null, 
  totalTimeList = null,
  eventOnlyText = null, 
  eventOnlyTime = null,
  lat_long = null,
  driverNote = null,
  taskTypes = null) {
  if (status) {
    if (status==='Tehtävä hyväksytty') { statusId = 201 }
    if (status==='odottaa_toimitusta') { statusId = 200 }
    if (status==='suoritettu') { statusId = 205 }
  } else {
    statusId = 200
  }
  let params = {  task_id: taskId,
                  status: status,
                  statusid: statusId,
                  actual_second: totalSecondActual
                } 
  if (status) {
    params['status'] = status 
  }              
  if (statusId) {
    params['statusid'] = statusId 
  }              
  if (totalSecond) {
    params['total_task_time_second'] = totalSecond 
  }
  if (reason) {
    params['reason'] = reason 
  }
  if (totalTimeList) {
    params['total_time_list'] = totalTimeList 
  }
  if (eventOnlyText) {
    params['event_only_text'] = eventOnlyText 
  }
  if (eventOnlyTime) {
    params['event_only_time'] = eventOnlyTime 
  }
  if (lat_long) {
    params['lat_long'] = lat_long 
  }
  if (driverNote) {
    params['driver_note'] = driverNote 
  }
  if (taskTypes) {
    params['task_types'] = taskTypes
  }

  let url = `${mainUrl}/api/v1/update_task`;
  let result = await postRequest(url, params);
  return result;
}

export async function deleteTaskInspection(taskId, phaseId) {
  let params = {  id: taskId,
    phase_id: phaseId,
  }
  let url = `${mainUrl}/api/v1/delete_task_inspection`;
  let result = await postRequest(url, params);
  return result;
}

export async function createTask(taskParams) {
  let params = {  
    driver_email: email,
    driver_name: fullname,
    driver_phone: phone,
    target_reg_num: taskParams.plate,
    pickup_address: taskParams.pickAddress,
    delievery_address: taskParams.deliveryAddress,
    customer_firstname: taskParams.cutomer1FirstName,
    customer_lastname: taskParams.cutomer1LastName,
    account_customer_firstname: taskParams.cutomer2FirstName,
    account_customer_lastname: taskParams.cutomer2LastName,
    transport_info: taskParams.info,
    target_manufacturer: '',
    target_model: '',
    target_make: '',
    inspection_phase_name: 'Muu työ',
    delievery_time: dtUtcToLocalString(new Date())
  }
  let url = `${mainUrl}/api/v1/tasks`;
  let result = await postRequest(url, params);
  return result;
}

export async function deleteTask(id) {
  const url = `${mainUrl}/api/v1/tasks/${id}`;
  const result = await deleteRequest(url);
  return result;
}

export async function getUserReport(start = null, end = null) {
  const url = `${mainUrl}/api/v1/driver_report`;
  let params = {
    driver_email: email
  }
  // "2021-12-31"
  if (start) { params['date_start'] = start }
  if (end) { params['date_end'] = end }
  const result = await getRequest(url, params);
  return result['data']['data'];
}

export function uploadPicture(file, task_id, picture_type) {
  const formData = new FormData();
  formData.append('file', file)
  formData.append('task_id', task_id)
  formData.append('picture_type', picture_type)
  const url = `${mainUrl}/api/v1/task_save_image`;  
  return postFormDataRequest(url, formData)
}

export async function startTodayTime(driver_email, time=null, type='start', latLong=null) {
  // console.log("startTodayTime driver_email", driver_email)
  console.log("startTodayTime latLong=!!!!", latLong)
  const url = `${mainUrl}/api/v1/today_start`;
  const params = {
    email: driver_email,
    type: type
  }
  if (time) {
    params['time'] = time
  }
  if (latLong) {
    params['location'] = latLong.join(",")
  }
  const result = await getRequest(url, params)
  return result['data']
}

export async function getMonthSchedule(email,dt) {
  const url = `${mainUrl}/api/v1/month_schedule`;

  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()

  const params = {
    email: email,
    month: m,
    year: y
  }
  const result = await getRequest(url, params)
  return result['data']
}

export async function updatedSchedules(schedules) {
  const url = `${mainUrl}/api/v1/month_schedule`;
  const result = await postRequest(url, {schedules: schedules})
  return result['data']
}

export async function saveWorkActual(params) {
  const url = `${mainUrl}/api/v1/work_actual_record`;
  // const params = {
  //   date: '2022-08-17',
  //   start_time: '10:00:00',
  //   end_time: '10:00:00',
  // }
  const result = await postRequest(url, params)
  return result['data']
}

export async function getDailyAllowance(email, dailyAllowance) {
  const url = `${mainUrl}/api/v1/daily_allowance`;
  const params = {  
    email: email,
    daily_allowance: dailyAllowance
  }
  const result = await getRequest(url, params)
  return result['data']
}

export async function copyScheduleWeek(year, fromWeek, toWeek) {
  const url = `${mainUrl}/api/v1/copy_schedule_week`;
  const params = {  
    year: year,
    from_week: fromWeek,
    to_week: toWeek,
    email: email
  }
  const result = await getRequest(url, params)
  return result['data']
}

export async function getAdditionalSales() {
  const url = `${mainUrl}/api/v1/additional_sales`;
  const params = {}
  const result = await getRequest(url, params)
  return result['data']
};


export default {
  getTasks, getDrivers, statusOptions
};