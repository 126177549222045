import React, { useState } from 'react';
import { ButtonGroup , ToggleButton } from 'react-bootstrap'
import {FaTimes, FaCheck} from  "react-icons/fa";

const InputState = props => {
  const [radioValue, setRadioValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
    reason: props.item.inspection_result.data.attributes.value_text
  });


  const handleChange = (e) => {
    let new_value = "x"
    if (e.target.value === "1" ) {
      new_value = radioValue.value === true  ?  null : true
    } else if (e.target.value === "0") {
      new_value = radioValue.value === false  ?  null : false
    }
    if (new_value !== "x") {
      setRadioValue({ ...radioValue, value: new_value });
      props.setFormResults({ ...props.formResults, [e.target.name]: new_value });
    }
  };


  const handleChangeReason = (e) => {
    console.log("handleChangeReason", e.target.value);
    setRadioValue({ ...radioValue, reason: e.target.value });
    props.setFormResults({ ...props.formResults, [e.target.name]: e.target.value });
  };

  return (
    <>
      <ButtonGroup toggle className="w-100 mb-1 button-group">
      <ToggleButton
            key="1"
            type="radio"
            variant="light"
            name={props.item.inspection_result.data.id}
            value="1"
            checked={radioValue.value === true || radioValue.value === "1" }   
            onClick={handleChange}
            className="input-state"
          >
            <FaCheck/> Ok
          </ToggleButton>
          <ToggleButton
            key="2"
            type="radio"
            variant="light"
            name={props.item.inspection_result.data.id}
            value="0"
            checked={radioValue.value === false || radioValue.value === "0" }
            onClick={handleChange}
            className="input-state"
          >
            <FaTimes/> -
          </ToggleButton>
      </ButtonGroup>
      {(radioValue.value === false || radioValue.value === "0") && <input
              type="text"
              name={`reason${props.item.inspection_result.data.id}`}
              className="form-control no-shadow-btn mb-1"
              placeholder="Havainnot"
              value={radioValue.reason}
              onChange={handleChangeReason}
            />}
    </>
  );
};



export default InputState;