import React , { useState, useEffect }from "react";
import { 
  FaUserClock, FaCarSide, FaBusinessTime, FaClock, 
  FaHourglassStart, FaBusAlt, FaHandshake , FaAngleDoubleDown, 
  FaClipboardCheck, FaStarHalf, FaStar, FaUserCog, FaMoon, FaSun, FaCloudSun
} from "react-icons/fa";
import { ScaleLoader } from "react-spinners";
import { getUserReport } from "../../services/logisticsService";
import { secToHourMinute, dtToYMD, dayForInput } from "../../services/formatService";
import { Collapse } from 'react-bootstrap';

const current = new Date
const thisMonth = current.toLocaleString('fi-FI', { month: 'long' }); /* June */
current.setMonth(current.getMonth()-1);
const previousMonth = current.toLocaleString('fi-FI', { month: 'long' });
current.setMonth(current.getMonth()-1);
const previous2Month = current.toLocaleString('fi-FI', { month: 'long' });

const Report = props => {

  const [taskAmount, setTaskAmount] = useState(0);
  const [id, setId] = useState('testCollap');
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [currentReport, setCurrentReport] = useState({ 
    amount: 0, plan: 0, drive: 0, task: 0, 
    time_list_drive: 0, time_list_delivery: 0, time_list_travel: 0, time_list_waiting: 0, time_list_is_delivered: 0 })
  const [lastReport, setLastReport] = useState(null)
  const [last2Report, setLast2Report] = useState(null)
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(dayForInput());
  const [open2, setOpen2] = useState(false)


  useEffect(() => {
    setLoading(true);

    let d = new Date();
    d.setDate(1);
    setStartDate(dayForInput(d));

    // getUserReport().then((result)=>{
    //   console.log("getUserReport pageload", result)
    //   setCurrentReport({ 
    //     amount: result.task_amount, 
    //     plan: result.plan_time, 
    //     drive: result.drive_time, 
    //     task: result.task_time,
    //     time_list_drive: result.time_list_drive,
    //     time_list_delivery: result.time_list_delivery,
    //     time_list_travel: result.time_list_travel,
    //     time_list_waiting: result.time_list_waiting,
    //     time_list_is_delivered: result.time_list_is_delivered,
    //     part_daily_allowance: result.part_daily_allowance,
    //     full_daily_allowance: result.full_daily_allowance,
    //     work_time_record: result.work_time_record,
    //    })
    // }).then(()=>{
    //   setLoading(false);
    // })

    
    // // Report last month
    // let date = new Date();
    // date.setMonth(date.getMonth()-1); 
    // let y = date.getFullYear(), m = date.getMonth();
    // let firstDay = new Date(y, m, 1);
    // let lastDay = new Date(y, m + 1, 0);
    // getUserReport(dtToYMD(firstDay), dtToYMD(lastDay)).then((result)=>{
    //   setLastReport(result)
    // })

    // // Report last 2 months
    // date.setMonth(date.getMonth()-1); 
    // y = date.getFullYear()
    // m = date.getMonth();
    // firstDay = new Date(y, m, 1);
    // lastDay = new Date(y, m + 1, 0);
    // getUserReport(dtToYMD(firstDay), dtToYMD(lastDay)).then((result)=>{
    //   setLast2Report(result)
    // })

  }, [props]);

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true);
      getUserReport(startDate, endDate).then((result)=>{
        console.log("getUserReport after change date result", result)
        setCurrentReport({ 
          amount: result.task_amount, 
          plan: result.plan_time, 
          drive: result.drive_time, 
          task: result.task_time,
          time_list_drive: result.time_list_drive,
          time_list_delivery: result.time_list_delivery,
          time_list_travel: result.time_list_travel,
          time_list_waiting: result.time_list_waiting,
          time_list_is_delivered: result.time_list_is_delivered,
          part_daily_allowance: result.part_daily_allowance,
          full_daily_allowance: result.full_daily_allowance,
          work_time_record: result.work_time_record,
          work_time_record_day: result.work_time_record_day,
          work_time_record_evening:  result.work_time_record_evening,
          work_time_record_night:  result.work_time_record_night
        })
      }).then(()=>{
        setLoading(false);
      })
    }
  }, [startDate, endDate]);
  const ValueRow = props => {
    const allSec = props.value ? props.value : 0
    const hourMinute = secToHourMinute(allSec)
    const hour = hourMinute[0]
    const minute = (hourMinute[1] < 10 ) ? `0${hourMinute[1]}`: hourMinute[1]

    return (
      <div>
        <div className="row p-2">
 
          <div className="col-4 px-2 d-flex align-items-center justify-content-end pe-3">
            {(props.iconNumber === "1") && <FaBusinessTime size={40} color={"gray"} />}
            {(props.iconNumber === "2") && <FaCarSide size={40} color={"gray"}/>}
            {(props.iconNumber === "3") && <FaUserClock size={40} color={"gray"}/>}
            {(props.iconNumber === "4") && <FaClock size={40} color={"gray"}/>}
            {(props.iconNumber === "5") && <FaHourglassStart size={40} color={"gray"}/>}
            {(props.iconNumber === "6") && <FaBusAlt size={40} color={"gray"}/>}
            {(props.iconNumber === "7") && <FaHandshake size={40} color={"gray"}/>}
            {(props.iconNumber === "8") && <FaClipboardCheck size={40} color={"gray"}/>}
            {(props.iconNumber === "9") && <FaStarHalf size={40} color={"gray"}/>}
            {(props.iconNumber === "10") && <FaStar size={40} color={"gray"}/>}
            {(props.iconNumber === "11") && <FaUserCog size={40} color={"gray"}/>}
            {(props.iconNumber === "12") && <FaMoon size={40} color={"gray"}/>}
            {(props.iconNumber === "13") && <FaSun size={40} color={"gray"}/>}
            {(props.iconNumber === "14") && <FaCloudSun size={40} color={"gray"}/>}
          </div>
          <div className="col-8 px-2">
            <span className="">{props.name}</span><br/>
            <span className="text-primary text-lg">{props.showNumber ?  allSec : `${hour}.${minute}`}</span><br/>
            {(props.showUnit) && <span className="text-primary text-sm">tunti.minuutti</span>}
          </div>
        </div>
        {(!props.hideHr) && 
        <div className="col-12 d-flex align-items-center justify-content-center ">
          <div className="border-top pt-2 pe-2 w-75" ></div>
        </div>}
      </div>
    )
  }
  const OldMOnth = props => {
    const hourMinute1 = secToHourMinute(props.report.plan_time)
    const hour1 = hourMinute1[0]
    const minute1 = (hourMinute1[1] < 10 ) ? `0${hourMinute1[1]}`: hourMinute1[1]

    const hourMinute2 = secToHourMinute(props.report.task_time)
    const hour2 = hourMinute2[0]
    const minute2 = (hourMinute2[1] < 10 ) ? `0${hourMinute2[1]}`: hourMinute2[1]

    const hourMinute3 = secToHourMinute(props.report.time_list_delivery + props.report.time_list_drive +  props.report.time_list_travel + props.report.time_list_waiting)
    const hour3 = hourMinute3[0]
    const minute3 = (hourMinute3[1] < 10 ) ? `0${hourMinute3[1]}`: hourMinute3[1]


    console.log("OldMOnth", props.monthName, props.report.task_amount, props.report.time_list_travel)
    return (
      <div className="col bg-white m-3 py-2">
      
      <span className="text-primary text-lg text-capitalize">{props.monthName} ({props.report.task_amount})</span>
      <div className="text-left ps-4">
        <FaBusinessTime color={"gray"} />
        <span className="text-primary ms-2">{hour1}.{minute1}</span>
        <br/>
        <FaUserClock color={"gray"} />
        <span className="text-primary ms-2">{hour2}.{minute2}</span>
        <br/>
        <FaClock color={"gray"} />
        <span className="text-primary ms-2">{hour3}.{minute3}</span>
        <br/>
        <FaClipboardCheck color={"gray"} />
        <span className="text-primary ms-2">{props.report.time_list_is_delivered}</span>
        <br/>
      </div>
    </div>
    )
  }
  return (
    <div className='container'>
      <h1>Raportti</h1>
      <div className="container bg-light">
          <div className="d-flex align-items-center ">
          <input
            type="date"
            className="form-control no-shadow-btn"
            name="invoiceStartDate"
            id="invoiceStartDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            />
          <input
            type="date"
            className="form-control no-shadow-btn"
            name="invoiceEndDate"
            id="invoiceEndDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            />
        </div>
          <div className="row">
            <div className="col bg-white m-3">
              <div className="row p-2">
                <span className="text-primary text-lg text-capitalize">
                  {currentReport.amount} tehtävää
                </span>
              </div>
              <div className="w-100">
                <div className="px-1 d-flex flex-column justify-content-around">
                  <ValueRow key="11" name="Kertyneet tunnit" value={currentReport.work_time_record} iconNumber="3" showUnit={false} hideHr={true}/>
                  <button 
                    onClick={() => setOpen2(!open2)}
                    className="btn btn-sm btn-ligth"> 
                      <FaAngleDoubleDown />
                      Näytä
                  </button>
                  <>
                  <Collapse in={open2}>
                    <div id="collapse-2">
                      <div className="px-5 d-flex flex-column justify-content-center">
                      <ValueRow key="14" name="Päivä(6-18)" value={currentReport.work_time_record_day} iconNumber="13" hideHr={true}/>
                      <ValueRow key="13" name="Iltalisä(18-23)" value={currentReport.work_time_record_evening} iconNumber="14" hideHr={true}/>
                      <ValueRow key="12" name="Yölisä(23-6)" value={currentReport.work_time_record_night} iconNumber="12" hideHr={true}/>
                      </div>
                    </div>
                  </Collapse>
                  </>
                  <div className="col-12 d-flex align-items-center justify-content-center ">
                  <div className="border-top pt-2 pe-2 w-75" ></div>
                </div>
                  <ValueRow key="1" name="Tavoiteaika" value={currentReport.plan} iconNumber="11" showUnit={false} />
                  {/* <ValueRow key="2" name="Tehtävä kokonaisaika" value={currentReport.task} iconNumber="1" showUnit={false} /> */}
                  <ValueRow key="8" name="Toimitettu" value={currentReport.time_list_is_delivered} iconNumber="8" showUnit={false} showNumber={true}  />
                  <ValueRow key="9" name="Osapäiväraha" value={currentReport.part_daily_allowance} iconNumber="9" showUnit={false} showNumber={true}  />
                  <ValueRow key="10" name="Kokopäiväraha" value={currentReport.full_daily_allowance} iconNumber="10" showUnit={false} showNumber={true}  />
                  {/* <ValueRow key="3" name="Tuntien kirjaus yhteensä" 
                  value={currentReport.time_list_drive + currentReport.time_list_delivery + currentReport.time_list_travel + currentReport.time_list_waiting} 
                  iconNumber="4" showUnit={false} hideHr={true}/>
                  <div className="text-center pb-3">
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      <FaAngleDoubleDown />
                      Näytä
                    </Button>
                  </div>  
                  <>
                    <Collapse in={open}>
                    <div id="example-collapse-text">
                    <div className="px-5 d-flex flex-column justify-content-center">
                    <ValueRow key="4" name="Ajoaika" value={currentReport.time_list_drive} iconNumber="2" hideHr={true}/>
                    <ValueRow key="5" name="Luovutusaika" value={currentReport.time_list_delivery} iconNumber="7"  hideHr={true}/>
                    <ValueRow key="6" name="Matkustusaika" value={currentReport.time_list_travel} iconNumber="6" hideHr={true}/>
                    <ValueRow key="7" name="Odotusaika" value={currentReport.time_list_waiting} iconNumber="5" hideHr={true}/>
                    </div>
                    </div>
                    </Collapse>
                  </>  */}
                </div>
              </div>
            </div>
          </div>
           <div className="row">
            {lastReport && <OldMOnth monthName={previousMonth} report={lastReport} />}
            {last2Report && <OldMOnth monthName={previous2Month} report={last2Report} />}
          </div> 

          { false && <div className="row">
            <div className="col bg-white m-3 pb-4">
              <div className="row px-2">
                <div className="col-6 px-1 d-flex justify-content-end">
                  from
                </div>
                <div className="col-6 px-1 d-flex justify-content-end">
                  to
                </div>
              </div>
              <div className="row px-2">
                <div className="col px-1">
                  <input
                    type="date"
                    className="form-control no-shadow-btn"
                    name="t"
                    id="selected_date1"
                    />
                </div>
                <div className="col px-1">
                  <input
                    type="date"
                    className="form-control no-shadow-btn"
                    name="t"
                    id="selected_date"
                    />
                </div>
              </div>
             {loading && <div className="row w-100 text-center py-2">
                <ScaleLoader color={'blue'} size={150} />
              </div>}
              {isReady && <>
                <div className="row p-2">
                  <span className="text-primary">
                    1.12.2021-15.12.2021 ({0} tehtävää)
                  </span>
                </div>
                <div className="row w-100 d-flex justify-content-end">
                  <div className="col-6 px-1 d-flex justify-content-end">
                  </div>
                  <div className="col-6 px-1 d-flex justify-content-start">
                    <span className="text-primary text-sm">tunti.minuutti</span>
                  </div>
                </div>
              </>}
            </div>
          </div>}

      </div>
    </div>
  );
};

export default Report;
