import React, { useState, useEffect } from 'react';
import { getTask, statusOptions, deleteTaskInspection } from "../../services/logisticsService";
import TabBar from '../navbar/TabBar';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";
import { getPlaces, deleteInspection } from "../../services/inspectionService";

const TaskLogger = props => {
  const [id, setId] = useState('');
  const [taskParams, setTaskParams] = useState(null);
  const [places, setPlaces] = useState([]);
  const [place, setPlace] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]);
  const [phaseOptions, setPhaseOptions] = useState([]);

  useEffect(() => {
    getTask(props.match.params.id).then((response) => {
      if (response) {
        setTaskParams(response)
      }
    });

    getPlaces("Logistiikka").then((response) => {
      // console.log("getPlaces Logistiikka response", response)
      setPlaces(response.data.data);
      setPlace(response.data.data[0].id);

      const thisOptions = response.data.data.map(function (place) {
        const place_detail = place.attributes;
        return { value: place_detail.id, label: place_detail.name , key: place_detail.id};
      });
      setPlaceOptions(thisOptions);

  
      const phase_options = response.data.data.map((p, index) => {
        return {
          key: index,
          label: p.attributes.name,  
          link: p.id,
          options: p.attributes.check_phases.data.map((s, index) => {
            return { label: `${s.attributes.step} ${s.attributes.name}` , value: s.id, link: p.id, key: index}
          })
        }
      })
      setPhaseOptions(phase_options);
    
    });  
  }, [props]);


  const unionPhases = (phases, phases_details) => {
    const arrA = taskParams.inspections
    const arrB = taskParams.inspection_detail.map((a) => (a.phase_id))
    return [...new Set([...arrA, ...arrB])]
  }

  const statusText = (status) => {
    const statusOptions = [
      { value: "waiting", label: "Odottaa" },
      { value: "waiting_for_inspection", label: "Odottaa tarkastusta" },
      { value: "waiting_for_acceptance", label: "Odottaa hyväksymistä" },
      { value: "accepted", label: "Valmis" },
    ];
    const statusArr = statusOptions.filter((i) => i.value === status);
    return statusArr ? statusArr[0].label : status
  };

  const handleDeleteClick = (taskId, phaseId, inspectionId) => {
    deleteTaskInspection(taskId, phaseId).then((response) => {
      // console.log('deleteTaskInspection response=', response)
      getTask(props.match.params.id).then((response) => {
        if (response) {
          setTaskParams(response)
        }
      });
    });
    if (inspectionId) {
      deleteInspection(inspectionId).then((response) => {
        // console.log('deleteInspection response=', response)
      })
    }
  }

  return (
    <main style={{ minHeight: '80vh' }}>
      {taskParams
        ? (<TabBar
          tabNumber={2}
          plate={`${taskParams.kohde_rekisteri}`}
          id={props.match.params.id}
        />)
        : (<TabBar
          tabNumber={2}
          plate=''
        />)}
      <div className="container mt-100">
        <div className="w-100 rounded-3 p-3">
          {taskParams && <Table bordered hover size="sm">
            <thead>
              <tr>
                <th colSpan="2" >
                  
                <div className="d-flex justify-content-between">
                  <span >
                    {`${taskParams.kohde_rekisteri}  ${taskParams.kohde_valmistaja}`}
                  </span>
                  <span >
                    <Link key='new_inspection_from_task'
                      to={{
                        pathname: "/admin/inspection_start",
                        state: {
                          displayType: "new",
                          params_place: place,
                          params_plate: taskParams.kohde_rekisteri,
                          placeOptions: placeOptions,
                          places: places,
                          phase: taskParams.p,
                          taskId: taskParams.id,
                        },
                      }}
                      className="btn btn-secondary text-white fs-14 rounded-pill me-2 text-nowrap "
                    >
                      <span className="fs-12 me-1">
                        <FaPlus />
                      </span>
                      <span className="me-1">Uusi tarkastus</span>
                    </Link>
                  </span>
                </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="20%" className="align-items-end">Tehtävät</td>
                <td>
                  {(phaseOptions && taskParams)
                    && unionPhases(taskParams.inspections, taskParams.inspection_detail).map( (inspection, idx) => {
                    let start_params = {
                      displayType: "new",
                      params_place: place,
                      params_plate: taskParams.kohde_rekisteri,
                      placeOptions: placeOptions,
                      places: places,
                      phase: inspection,
                      taskId: taskParams.id,
                    }
                    let path_to = "/admin/inspection_start"
                    const ckeck_exist = taskParams.inspection_detail.filter(d => d.phase_id === inspection)
                    let inspectionId = null
                    if (ckeck_exist.length > 0) {
                      inspectionId = ckeck_exist[0].inspection_id
                      path_to = `/admin/inspection/${ckeck_exist[0].inspection_id}/${taskParams.id}/${ckeck_exist[0].latest_step}`
                    }
                    let labelPhaseName = null
                    let checkInspectionLabel = null
                    if (phaseOptions && phaseOptions[0]) {
                      checkInspectionLabel = phaseOptions[0].options.filter(option => option.value === inspection)
                    }
                    if (checkInspectionLabel && checkInspectionLabel.length > 0) {
                      labelPhaseName = checkInspectionLabel[0].label
                    } 
                    return (
                    <div key={idx}>
                      <Link 
                        to={{
                          pathname: path_to,
                          state: start_params,
                        }}
                        className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap  mt-1 mb-1"
                        >
                        <span className="fs-12 me-1">
                          <FaEdit />
                        </span>
                        <span className="me-1">
                          {labelPhaseName ? labelPhaseName : idx}
                        </span>
                      </Link>
                      <FaTrashAlt  onClick={() => { handleDeleteClick(taskParams.id, inspection, inspectionId) }}  />
                    </div>
                  )}
                  )}
                </td>
              </tr>
              
            </tbody>
          </Table>}
        </div>
      </div>
    </main>
  );
};

export default TaskLogger;