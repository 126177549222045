import React, { useState, useEffect } from 'react';
import { getTask, statusOptions } from "../../services/logisticsService";
import TabBar from '../navbar/TabBar';
import Table from 'react-bootstrap/Table';
import config from '../../config';
import { FaMapMarkerAlt } from "react-icons/fa";

const TaskMap = props => {
  const [id, setId] = useState('');
  const [taskParams, setTaskParams] = useState(null);

  useEffect(() => {
    getTask(props.match.params.id).then((response) => {
      console.log("getTask response", response)
      if (response) {
        setTaskParams(response)
      }
    });
  }, [props]);

  let map_url = null
  let map_from = ""
  let map_to = ""

  if (taskParams) {
    let pickup_address = (taskParams.pickup_address) ? taskParams.pickup_address : ''
    let pickup_postcode = (taskParams.pickup_postcode) ? taskParams.pickup_postcode : ''
    let pickup_location = (taskParams.pickup_location) ? taskParams.pickup_location : ''
    let delivery_address = (taskParams.delivery_address) ? taskParams.delivery_address : ''
    let delivery_postcode = (taskParams.delivery_postcode) ? taskParams.delivery_postcode : ''
    let delivery_location = (taskParams.delivery_location) ? taskParams.delivery_location : ''

    map_from = `${pickup_address} ${pickup_postcode} ${pickup_location}`
    map_to = `${delivery_address} ${delivery_postcode} ${delivery_location}`

    if (config.google_api_key && map_from && map_to) {
      map_url = "https://www.google.com/maps/embed/v1/directions?key=" + config.google_api_key
      map_url += "&origin=" + map_from
      map_url += "&destination=" + map_to
    }
    //   src="https://www.google.com/maps/embed/v1/directions
    // ?key=AIzaSyDMEQavh3nv19FG9Jvc6mpoSd6RFvokznU
    // &origin=Oslo+Norway
    // &destination=Telemark+Norway"
  }


  return (
    <main style={{ minHeight: '80vh' }}>
      {taskParams
        ? (<TabBar
          tabNumber={3}
          plate={`${taskParams.target_reg_num}`}
          id={props.match.params.id}
        />)
        : (<TabBar
          tabNumber={3}
          plate=''
        />)}
      <div className="container mt-100">
        <div className="w-100 rounded-3 p-3">
          {taskParams && <Table bordered hover size="sm">
            <thead>
              <tr>
                <th colSpan="2">{`${taskParams.target_reg_num}`}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="20%" className="align-items-end">Nouto osoite</td>
                <td>
                  {map_from}
                </td>
              </tr>
              <tr>
                <td width="20%" className="align-items-end">Toimitus osoite</td>
                <td>
                  {map_to}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className='text-center' >
                  {map_to && ( <a target='_blank' href={`https://www.google.fi/maps/dir/${map_from}/${map_to}`}>
                        <FaMapMarkerAlt />
                        Open in Googole Map
                      </a>) }
                </td>
              </tr>
              { map_url && (
                <tr style={{ height: "550px" }} >
                  <td colSpan="2" >
                      <>
                        <a target='_blank' href={`https://www.google.fi/maps/dir/${map_from}/${map_to}`}>
                          Open in Googole Map
                        </a>
                        <iframe
                          width="100%"
                          height="550"
                          style={{ border: 0 }}
                          loading="lazy"
                          allowfullscreen
                          src={map_url}
                        // src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyDMEQavh3nv19FG9Jvc6mpoSd6RFvokznU&origin=Oslo+Norway&destination=Telemark+Norway"
                        /></>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>}
        </div>
      </div>
    </main>
  );
};

export default TaskMap;