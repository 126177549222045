import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {createTask} from "../../services/logisticsService"
import {FaSave} from "react-icons/fa";
import Table from 'react-bootstrap/Table';

class TaskModalNewTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      taskParams: {
        plate: '',
        pickAddress: '',
        deliveryAddress: '',
        cutomer1FirstName: '',
        cutomer1LastName: '',
        cutomer2FirstName: '',
        cutomer2LastName: '',
        info: '',
      },
      saveError: null
    }
  }

  componentDidMount() {
    // Do this componentDidMount first time
    // this.setModalState()
  }

  componentDidUpdate(prevProps) {
    // Do this componentDidUpdate only props change
    if (prevProps.id !== this.props.id) {
      console.log("componentDidUpdate InspectionModalViewImages only props change")
      // this.setModalState()
    }
  }

  handleChange = (e) => {
    console.log("e.target.name",e.target.name);
    console.log("e.target.value",e.target.value);
    let newParams = this.state.taskParams
    newParams[e.target.name] = e.target.value
    
    this.setState({ taskParams: newParams }, function() {
      console.log("taskParams", this.state.taskParams)
    });
    // this.setState({
    //   taskParams: this.state.taskParams.map((key,index) => {
    //     if (key === e.target.name) {
    //       return e.target.value
    //     } else {
    //       return this.state.taskParams[key]
    //     }
    //   })
    // });

  };

  handleSave = () => {
    console.log("createTask result:")
    createTask(this.state.taskParams).then((result) => {
      // console.log("createTask result:", result)
      if (result.status === 200 && result.data.status === 'SUCCESS') {
        this.props.closeModal()
      } else {
        this.setState({ saveError: 'Save error !!' })
      }
    })
  }

  render() {
    const divStyle = {
      fontSize: '12px',
    };
    return (
      <div style={divStyle}>
        <div className="row">
          <div className="col-8 text-left">
            <h5>Uusi tehtävä (Muu työ)</h5>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <button className="btn btn-info"  onClick={this.props.closeModal}>X sulje</button>
          </div>
        </div>
        <div className="w-100 rounded-3 p-1">
          {true && <Table bordered hover size="sm">
            <tbody>
            <tr>
                <td width="20%" className="align-items-end">Rekisternumero</td>
                <td>
                <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="plate"
                    onChange={this.handleChange}
                    value={this.state.plate}
                  />
                </td>
              </tr>
              
              <tr>
                <td>Nouto osoite</td>
                <td>
                  <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="pickAddress"
                    onChange={this.handleChange}
                    value={this.state.pickAddress}
                  />
                  {/* {taskParams.nouto_osoite && taskParams.nouto_osoite}&nbsp;
                  {taskParams.nouto_postinumero && taskParams.nouto_postinumero}&nbsp;
                  {taskParams.nouto_paikkakunta && taskParams.nouto_paikkakunta}&nbsp; */}
                </td>
              </tr>
              <tr>
                <td>Toimitus osoite</td>
                <td>
                <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="deliveryAddress"
                    onChange={this.handleChange}
                    value={this.state.deliveryAddress}
                  />
                  {/* {taskParams.toimitus_osoite && taskParams.toimitus_osoite}&nbsp;
                  {taskParams.toimitus_postinumero && taskParams.toimitus_postinumero}&nbsp;
                  {taskParams.toimitus_paikkakunta && taskParams.toimitus_paikkakunta}&nbsp; */}
                </td>
              </tr>
              <tr>
                <td>Kohde asiakas</td>
                <td>
                  <div className="d-flex">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control no-shadow-btn"
                        name="cutomer1FirstName"
                        onChange={this.handleChange}
                        value={this.state.cutomer1FirstName}
                        placeholder="Etunimi"
                      />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control no-shadow-btn"
                          name="cutomer1LastName"
                          onChange={this.handleChange}
                          value={this.state.cutomer1LastName}
                          placeholder="Sukunimi"
                        />
                    </div>
                   </div>
                  {/* {(taskParams.kohde_asiakas1_etunimi && taskParams.kohde_asiakas1_sukunimi)  && `${taskParams.kohde_asiakas1_etunimi} ${taskParams.kohde_asiakas1_sukunimi}`}
                  <br/>
                  {(taskParams.kohde_asiakas1_yritys && taskParams.kohde_asiakas1_ytunnus) && `${taskParams.kohde_asiakas1_yritys} ${taskParams.kohde_asiakas1_ytunnus}`}
                  <br/>
                  {(taskParams.kohde_asiakas1_osoite && taskParams.kohde_asiakas1_postinumero && taskParams.kohde_asiakas1_toimipaikka) && `${taskParams.kohde_asiakas1_osoite} ${taskParams.kohde_asiakas1_postinumero} ${taskParams.kohde_asiakas1_toimipaikka}`}
                  <br/>
                  {(taskParams.kohde_asiakas1_puhelin && taskParams.kohde_asiakas1_sahkoposti) && `${taskParams.kohde_asiakas1_puhelin} ${taskParams.kohde_asiakas1_sahkoposti}`} */}
                </td>
              </tr>
              <tr>
                <td>Myyntitili asiakas</td>
                <td>
                <div className="d-flex">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control no-shadow-btn"
                        name="cutomer2FirstName"
                        onChange={this.handleChange}
                        value={this.state.cutomer2FirstName}
                        placeholder="Etunimi"
                      />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control no-shadow-btn"
                          name="cutomer2LastName"
                          onChange={this.handleChange}
                          value={this.state.cutomer2LastName}
                          placeholder="Sukunimi"
                        />
                    </div>
                   </div>
                  {/* {taskParams.myyntitili_asiakas1_etunimi && taskParams.myyntitili_asiakas1_etunimi}&nbsp;
                  {taskParams.myyntitili_asiakas1_sukunimi && taskParams.myyntitili_asiakas1_sukunimi}&nbsp;
                  <br/>
                  {taskParams.myyntitili_asiakas1_osoite && taskParams.myyntitili_asiakas1_osoite}&nbsp;
                  {taskParams.myyntitili_asiakas1_postinumero && taskParams.myyntitili_asiakas1_postinumero}&nbsp;
                  {taskParams.myyntitili_asiakas1_toimipaikka && taskParams.myyntitili_asiakas1_toimipaikka}&nbsp;
                  <br/>
                  {taskParams.myyntitili_asiakas1_puhelin && taskParams.myyntitili_asiakas1_puhelin}&nbsp;
                  {taskParams.myyntitili_asiakas1_sahkoposti && taskParams.myyntitili_asiakas1_sahkoposti}&nbsp; */}
                </td>
              </tr>
              
              <tr>
                <td>Toimitus info</td>
                <td>
                <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="info"
                    onChange={this.handleChange}
                    value={this.state.info}
                  />
                  {/* {taskParams.toimitus_info} */}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {this.state.saveError && this.state.saveError}
                  <button
                    onClick={()=>this.handleSave()}
                    className="w-100 h-100 btn btn-secondary"
                  >
                    <FaSave className="me-1" />
                    Save
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>}
        </div>
      </div>
    );
  }
}

TaskModalNewTask.propTypes = {

};

export default TaskModalNewTask;