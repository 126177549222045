import React, { useState } from 'react';
import { ButtonGroup , ToggleButton } from 'react-bootstrap'

const InputRadio = props => {
  const [radioValue, setRadioValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
    reason: props.item.inspection_result.data.attributes.value_text
  });


  const radios =  props.item.inspection_options.map((i) => { return { name: i, value: i } } )
  

  const handleChange = (e) => {
    setRadioValue({ ...radioValue, value: e.target.value });
    props.setFormResults({ ...props.formResults, [e.target.name]: e.target.value });
  };



  return (
    <>
      <ButtonGroup toggle className="w-100 mb-1 button-group">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="light"
            name={props.item.inspection_result.data.id}
            value={radio.value}
            checked={radioValue.value === radio.value}
            onClick={handleChange}
            className="input-radio"
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
};



export default InputRadio;