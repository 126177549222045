import React, {useEffect} from 'react';
import InspectionStartForm from "./InspectionStartForm";
import {Card} from 'react-bootstrap';
import TabBar from '../navbar/TabBar';


const InspectionStart = props => {

  const stateParams = props.location.state
  const pageTitle = stateParams.displayType === "new" ? "Uusi tarkistus" : "Muokkaa tarkistus"
  return (
      <main>
        {props.location.state.id 
          ? (<TabBar 
            tabNumber={2} 
            plate={stateParams.plate}
            inspectionId={props.location.state.id}
            id={stateParams.taskId}
            />)
          : (<TabBar 
            tabNumber={2} 
            plate={stateParams.params_plate}
            id={stateParams.taskId}
            />)}
        <div className="container mt-100">
              <div className="w-100 rounded-3 p-3">
                <Card className='mt-3'>
                  {true && <Card.Header>{pageTitle} {stateParams.params_plate}</Card.Header>}
                  <Card.Body>
                    {props.location.state.id 
                      ? <InspectionStartForm 
                      id={stateParams.id} 
                      plate={stateParams.plate} 
                      place_name={stateParams.place_name} 
                      phase_name={stateParams.phase_name} 
                      make={stateParams.make} 
                      model={stateParams.model} 
                      vm={stateParams.vm} 
                      mileage={stateParams.mileage}
                      taskId={stateParams.taskId} 
                      page={stateParams.page} />
                      : <InspectionStartForm 
                      plate={stateParams.params_plate} 
                      place={stateParams.params_place} 
                      placeOptions={stateParams.placeOptions} 
                      phase={stateParams.phase} 
                      taskId={stateParams.taskId} 
                      places={stateParams.places} />}
                    </Card.Body>  
                </Card>
              </div>
          </div>
    </main>
  );
  
}


export default InspectionStart;