import http from "./httpServices";
import config from "../config";
import jwtDecode from "jwt-decode";

const apiEndpoint = config.auth_api_url + "/auth";

const tokenKey = "token";

http.setToken(getToken());

export async function login(user_info) {
  const { data: token } = await http.post(apiEndpoint, user_info);
  localStorage.setItem(tokenKey, token);
}

export function loginWithToken(token) {
  localStorage.setItem(tokenKey, token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  window.location.href = "/login";
}

export function getCurrentToken() {
  //return {user: {fullname: "TEST"}}
  try {
    const token = localStorage.getItem(tokenKey);
    const contents = jwtDecode(token);
    // console.log("Current token:")
    // console.log(contents)
    return contents;
  } catch (error) {
    return null;
  }
}
export function getCurrentUser() {
  try {
    const token = localStorage.getItem(tokenKey);
    const contents = jwtDecode(token);
    return contents.user;
  } catch (error) {
    return null;
  }
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithToken,
  logout,
  getCurrentUser,
  getCurrentToken,
  getToken,
};
