import React, { Component } from 'react';
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import logisticsService from "../../services/logisticsService";
import Select from 'react-select';
import auth from "../../services/authService";


export default class TaskSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plate: '',
      status: 'odottaa_toimitusta',
      driverOptions: [],
      user_email: '',
      user_roles: []
    }
  }

  componentDidMount() {


    const currentUser = auth.getCurrentUser();
    console.log("currentUser=", currentUser)
    const email = currentUser ? currentUser.email : null;
    const roles = currentUser ? currentUser.roles : null;
    const adminEmails = ['jari.nukari@autolle.com','antti-pekka.pesala@autolle.com','sampo.lamsa@autolle.com','ramadan.begu@autolle.com','kantaree@comille.fi']
    this.setState({
      user_email: email,
      user_roles: roles
    })
    if (!currentUser.roles || currentUser.roles.includes("driver")) {
      console.log("No roles or User Roles is contain driver ", currentUser)
      this.setState({
        driverOptions: [{ value: currentUser.email, label: currentUser.fullname }]
      })
    } else if (currentUser.roles.includes("admin") || adminEmails.includes(email)) {
      console.log("User is admin or User Roles is contain admin", currentUser)
      logisticsService.getDrivers().then((response) => {
        if (response) {
          this.setState({
            driverOptions: response.map((driver) => ({ value: driver.email, label: driver.full_name }))
          })
        }
      });
    } else {
      console.log("User role Error !!!", currentUser)
      this.setState({
        driverOptions: [{ value: currentUser.email, label: currentUser.fullname }]
      })
    }

  }

  handelPlateChange = (e) => {
    this.setState({ plate: e.target.value }, () => {
      this.pushChanges()
    });
  }

  handleChangeEmail = (e) => {
    this.setState({ user_email: e.value }, () => {
      this.pushChanges()
    });
  }

  handleChangeStatus = (e) => {
    this.setState({ status: e.value }, () => {
      this.pushChanges()
    });
  }

  pushChanges() {
    this.fetchTasks(this.state.user_email, this.state.status, this.state.plate)
  }

  fetchTasks(driver, status, plate) {
    this.props.beginFetch();
    logisticsService.getTasks(driver, status, plate).then((response) => {
      this.props.updateTasks(
        response
      );
    });
  }


  statusOptions = logisticsService.statusOptions;


  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <div className="mb-3">
            <label htmlFor="plate" className="form-label">
              Rekisterinumero
            </label>
            <input
              type="text"
              className="form-control no-shadow-btn"
              name="plate"
              onChange={this.handelPlateChange}
              value={this.state.plate}
              disabled={false}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <label className="form-label">Toimitusedustajat</label>
          <Select
            name="status"
            options={this.state.driverOptions}
            onChange={this.handleChangeEmail}
            placeholder='Toimitusedustaja...'
            isSearchable
            value={this.state.driverOptions.filter(option => option.value === this.state.user_email)}
          />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <label className="form-label">Tilanne</label>
          <Select
            name="status"
            options={this.statusOptions}
            onChange={this.handleChangeStatus}
            placeholder='Tlanne...'
            isSearchable
            value={this.statusOptions.filter(option => option.value === this.state.status)}
          />
        </div>
      </div>
    );
  }
}

TaskSelector.propTypes = {
  onChange: PropTypes.func
};