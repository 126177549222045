import React, { useState, useEffect } from "react";

import http, {
  postFormDataRequest,
  deleteRequest,
} from "../../services/httpServices";
import env_variable from "../../config";
import GalleryCosmeticImages from "./GalleryCosmeticImages";
import TabBar from "../navbar/TabBar";

const url = "/api/v1/inspection_gallery";
// Easy ad api url
const easy_api_url = env_variable.easyad_api_url;

function GalleryItem(props) {
  // Current gallery Item has children (inside, outside)
  const [galleryItem, setGalleryItem] = useState({});
  const [carFrameLayout, setCarFrameLayout] = useState("inside");
  // Data according to the frame eg. inside or outside
  const [carFrameData, setCarFrameData] = useState([]);
  // Flag that updates changed data
  const [syncData, setSyncData] = useState(false);
  // Holds dots position that will be created
  const [dotPostData, setDotPostData] = useState(null);
  // Flag for tracking when to submit new dot data
  const [submitNewDot, setSubmitNewDot] = useState(false);
  // Flag that tracks if deleting is active
  const [deleteDot, setDeleteDot] = useState(false);
  // Holds item to be deleted
  const [deleteItem, setDeleteItem] = useState(null);
  // Saves current framelayout id (parent id)
  const [galleryItemParentId, setGalleryItemParentId] = useState("");
  const [id, SetId] = useState("");
  const [inspection, setInspection] = useState("");

  useEffect(() => {
    const getGalleryItem = async () => {
      // Get id from the url params
      const { params } = props.match;
      const id = params.id;
      SetId(params.id);
      // Creates key example "inside_parent_id"
      let parent_key_name = `${carFrameLayout}_parent_id`;
      const response = await http.get(`${easy_api_url}${url}/${id}`);
      const { data: res } = response;
      const { data } = res;
      let frame = data[carFrameLayout] ? data[carFrameLayout] : [];
      setGalleryItem(data);
      setCarFrameData(frame);
      setGalleryItemParentId(data[parent_key_name]);
      setSubmitNewDot(false);
      setInspection(data["inspection"]);
    };
    getGalleryItem();
  }, [syncData]);

  // Updates the frame (inside or outside) when clicked
  const handleClick = (e) => {
    let layout = e.target.name;
    let parent_key_name = `${layout}_parent_id`;
    let frame = galleryItem[layout] ? galleryItem[layout] : [];
    setCarFrameData(frame);
    setCarFrameLayout(layout);
    setGalleryItemParentId(galleryItem[parent_key_name]);
  };

  useEffect(() => {
    const AddNewGalleryItem = async () => {
      if (submitNewDot) {
        let { parent_id, x, y, attach_image } = dotPostData;

        let formData = new FormData();
        formData.append("attach_image", attach_image);
        formData.append("parent_id", parent_id);
        formData.append("x", x);
        formData.append("y", y);
        formData.append("is_gallery", "true");
        formData.append("is_request_url", "true");

        const response = await postFormDataRequest(
          `${easy_api_url}${url}`,
          formData
        );

        if (response) {
          let { status } = response;
          setSyncData(!syncData);
        }
      }
    };
    AddNewGalleryItem();
  }, [submitNewDot, dotPostData]);

  useEffect(() => {
    const deleteSelectedDot = async () => {
      if (deleteItem) {
        const response = await deleteRequest(
          `${easy_api_url}${url}/${deleteItem.id}`
        );
        if (response) {
          let { status } = response;
          setSyncData(!syncData);
          setDeleteDot(false);
        }
      }
    };

    deleteSelectedDot();
  }, [deleteItem]);

  return (
    <main>
      {galleryItem.inspection && (
        <TabBar
          callBackUrl="/admin/inspections"
          tabNumber={2}
          plate={inspection.plate}
          inspectionId={inspection.id}
          brokenImageId={id}
          dimensionId={inspection.plate}
          galleryId={id}
        />
      )}
      <div className="container pt-4 mt-100">
        <div className="w-100 py-3 border-bottom">
          <h1 className="fs-3 text-primary">Auton virhekuvat</h1>
        </div>
        <div className="w-100 py-4">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item me-3" role="presentation">
              <button
                onClick={handleClick}
                name="inside"
                className="nav-link border border-primary shadow-sm active"
                id="pills-inside-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-inside"
                type="button"
                role="tab"
                aria-controls="pills-inside"
                aria-selected="true"
              >
                Sisäpuoli
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={handleClick}
                name="outside"
                className="nav-link border border-primary shadow-sm"
                id="pills-outside-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-outside"
                type="button"
                role="tab"
                aria-controls="pills-outside"
                aria-selected="false"
              >
                Ulkopuoli
              </button>
            </li>
          </ul>
          <div
            className="tab-content"
            id="pills-tabContent"
            style={{ minHeight: "420px" }}
          >
            <div
              className="tab-pane fade show active"
              id="pills-inside"
              role="tabpanel"
              aria-labelledby="pills-inside-tab"
            >
              {carFrameLayout === "inside" && (
                <GalleryCosmeticImages
                  carFrameData={carFrameData}
                  carFrameLayout={carFrameLayout}
                  setSyncData={setSyncData}
                  syncData={syncData}
                  setDotPostData={setDotPostData}
                  deleteDot={deleteDot}
                  setDeleteDot={setDeleteDot}
                  setDeleteItem={setDeleteItem}
                  setSubmitNewDot={setSubmitNewDot}
                  submitNewDot={submitNewDot}
                  galleryItemParentId={galleryItemParentId}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="pills-outside"
              role="tabpanel"
              aria-labelledby="pills-outside-tab"
            >
              {carFrameLayout === "outside" && (
                <GalleryCosmeticImages
                  carFrameData={carFrameData}
                  carFrameLayout={carFrameLayout}
                  setSyncData={setSyncData}
                  syncData={syncData}
                  setDotPostData={setDotPostData}
                  deleteDot={deleteDot}
                  setDeleteDot={setDeleteDot}
                  setDeleteItem={setDeleteItem}
                  setSubmitNewDot={setSubmitNewDot}
                  submitNewDot={submitNewDot}
                  galleryItemParentId={galleryItemParentId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default GalleryItem;
