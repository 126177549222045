import React, { useState, useEffect } from 'react';
import {FaSave} from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import {ClipLoader} from "react-spinners";
import { saveTask, statusText } from "../../services/logisticsService";
import { isoFormatDMY, parseISOString, msToHM, getFormatTimestamp, dateISOToString1, getFormatTimeStringToISO, dateISOToStringT } 
from "../../services/formatService";
const TaskModalTimeSummary = props => {
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [cHour, setCHour] = useState(null);
  const [cMinute, setCMinute] = useState(null);
  const [reason, setReason] = useState(null);
  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [diffTime, setDiffTime] = useState([0,0,0]);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddEventSaving, setIsAddEventSaving] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const [newTime, setNewTime] = useState(null);
  const [showError, setShowError] = useState(false);
  const [latLong, setLatlong] = useState([0,0]);
  const [saveText, setSaveText] = useState('Tallenna')

  useEffect(() => {
    if (props.reason) {
      setReason(props.reason)
    }
    calculateEvents(props.events, props.hourActual, props.minuteActual)
    // const all_events = props.events.filter(
    //   (e) => (e.reason === 'suoritetaan' || e.reason === 'suoritettu' || e.reason === 'tauolla' || e.reason === 'suoritettu_muokattu')
    //   )
    // setAllEvents(all_events)
    // const all_event_times = all_events.map((e) => (e.timestamp))
    // if (all_event_times.length > 1) {
    //   const thisMin = parseISOString(all_event_times[0])
    //   const thisMax = parseISOString(all_event_times[all_event_times.length - 1])
    //   setDiffTime(msToHM(thisMax -  thisMin))
    //   const last_event = all_events[all_events.length - 1]
    //   if (last_event.reason === 'suoritettu') {
    //     setHour(msToHM(thisMax -  thisMin)[0])
    //     setMinute(msToHM(thisMax -  thisMin)[1])
    //     setCHour(msToHM(thisMax -  thisMin)[0])
    //     setCMinute(msToHM(thisMax -  thisMin)[1])
    //   } else {
    //     setHour(null)
    //     setMinute(null)
    //   }
    //   if (props.hourActual || props.minuteActual) {
    //     setHour(props.hourActual)
    //     setMinute(props.minuteActual)
    //   }
    // }
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatlong([position.coords.latitude, position.coords.longitude])
      });
    } 
  }, [props]);

  const calculateEvents = (events, hourActual, minuteActual) => {
    console.log("calculateEvents ??", events)
    if (Array.isArray(events)){
      console.log("events is array")
    } else {
      events = JSON.parse(events)
      console.log("calculateEvents again", events)
    }
    const all_events = events.filter(
      (e) => (e.reason === 'suoritetaan' || e.reason === 'suoritettu' || e.reason === 'tauolla' || e.reason === 'suoritettu_muokattu')
      )
    setAllEvents(all_events)
    const all_event_times = all_events.map((e) => (e.timestamp))
    console.log("all_event_times", all_event_times)
    if (all_event_times.length > 1) {
      const thisMin = parseISOString(all_event_times[0])
      const thisMax = parseISOString(all_event_times[all_event_times.length - 1])
      console.log("Compare thisMin", thisMin)
      console.log("Compare thisMax", thisMax)
      setDiffTime(msToHM(thisMax -  thisMin, true))
      const last_event = all_events[all_events.length - 1]
      if (last_event.reason === 'suoritettu' || last_event.reason === 'suoritettu_muokattu') {
        setHour(msToHM(thisMax -  thisMin, true)[0])
        setMinute(msToHM(thisMax -  thisMin, true)[1])
        setCHour(msToHM(thisMax -  thisMin, true)[0])
        setCMinute(msToHM(thisMax -  thisMin, true)[1])
        setSaveText('Vahvista tehtävä päättyminen')
      } else {
        setHour(null)
        setMinute(null)
      }
      if (hourActual || minuteActual) {
        setHour(hourActual)
        setMinute(minuteActual)
      }
    }
  }

  useEffect(() => {
    console.log("Allevent", allEvents)
    const end_events = allEvents.filter((e) => (e.reason === 'suoritettu'))
    setLastEvent(end_events[end_events.length - 1])
    if (end_events[end_events.length - 1]) {
      console.log("timestamp", end_events[end_events.length - 1].timestamp)
      console.log("dateISOToString1", dateISOToString1(end_events[end_events.length - 1].timestamp))
      console.log("dateISOToStringT", dateISOToStringT(end_events[end_events.length - 1].timestamp))
      setNewTime(dateISOToStringT(end_events[end_events.length - 1].timestamp))
    }
  }, [allEvents])

  useEffect(() => {
    console.log("Input newTime=", newTime)
    if (lastEvent && newTime && newTime > dateISOToStringT(lastEvent.timestamp)) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [newTime])


  const handleSave = () => {
    setIsSaving(true)
    saveTask(props.id, 
      null, 
      null, 
      null,
      ((hour * 3600) + (minute * 60)),
      reason
      )
      .then((response) => {
        // console.log("saveTask response", response)
        console.log("saveTask reason", reason)
        props.setTaskParams({ ...props.taskParams, total_time_reason: reason});
        props.setMinuteActual(minute)
        props.setHourActual(hour)
        setIsSaving(false)
      })
      .then(() => {
        props.closeModal();
      });
  }

  const handleAddNewTime = () => {
    setIsAddEventSaving(true)
    saveTask(props.id, 
      null, 
      null, 
      null,
      null,
      null,
      null,
      'suoritettu_muokattu',
      getFormatTimeStringToISO(newTime),
      latLong
      )
      .then((response) => {
        console.log("saveTask response", response)
        if (response.data.task.events) {
          calculateEvents(response.data.task.events, props.hourActual, props.minuteActual) 
        }
        setIsAddEventSaving(false)
      })
      .then(() => {
        // props.closeModal();
      });
  };



  const checkTimeDiff = (value) => {
    console.log("Input datatime value", value)
    if (value > dateISOToStringT(lastEvent.timestamp)) {
      console.log("value is more then paattyy ")
      // setNewTime(dateISOToString1(lastEvent.timestamp))
    } 
  }


  return (
    <div>
        <div className="row">
          <div className="col-8 text-left">
            <h2>Ajettu kokonaisaika</h2>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <button className="btn btn-info"  onClick={props.closeModal}>X sulje</button>
          </div>
        </div>
        <div className="w-100 rounded-3 p-3">
          {true && <Table bordered hover size="sm">
            <tbody>
            <tr>
                <td width="20%" className="align-items-end hide-on-mobile-nav">Aika</td>
                <td>
                  {allEvents.map((event, index)=> {
                    let stamp_time = (event.timestamp) ? getFormatTimestamp(event.timestamp)[1] : ''
                    return (
                  <span key={index}>
                    {stamp_time}: {statusText(event.reason)}<br/>
                  </span>)})}
                  {(lastEvent && (lastEvent.reason == 'suoritettu' || lastEvent.reason == 'suoritettu_muokattu')) 
                  && <div>
                    <div className="d-flex align-items-center" >
                      <input 
                        type="datetime-local" 
                        className="me-1"
                        value={newTime}
                        onChange={e => setNewTime(e.target.value)}
                        onBlur={e => checkTimeDiff(e.target.value)}
                        />
                      <button
                        onClick={()=>handleAddNewTime()}
                        className="btn btn-secondary btn-sm"
                        disabled={showError}
                      >
                        { isAddEventSaving ? <ClipLoader color={'#ffffff'} loading={true} css={''} size={15}/> : <FaSave color={'#ffffff'} />}
                        <span className="ms-1 text-white" >Tallenna uusi työpäivän päättymisaika</span>
                      </button>
                    </div>
                    {showError && <span style={{fontWeight: 'bold', color: 'red'}}>
                      Ei voi kirjata tulevaa aikaa
                    </span>}
                  </div> 
                  }

                  <br/>
                  <span style={{fontWeight: 'bold'}} >Yht. {diffTime[0]} t. {diffTime[1]} m.</span>
                </td>
              </tr>
              
              <tr>
                <td className=' hide-on-mobile-nav'>
                  Ajettu kokonaisaika<br/>
                  (tunti:minuutti)
                </td>
                <td>
                <div className="d-flex align-items-center justify-content-start " >
                    <div className="" >
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="hour"
                        onChange={e => setHour(e.target.value)}
                        value={hour}
                      /> 
                    </div>
                    <div className="" > : </div>
                    <div className="" >                   
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="minute"
                        onChange={e => setMinute(e.target.value)}
                        value={minute}
                      />
                    </div>
                    
                  </div>                
                </td>
              </tr>
              <tr>
                <td className=' hide-on-mobile-nav'>Syy matkan kestolle <span className='red-color'>*</span></td>
                <td>
                <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="reason"
                    onChange={e => setReason(e.target.value)}
                    placeholder='Syy matkan kestolle..'
                    value={reason}
                  />
                </td>
              </tr>
              {latLong !== [0,0] && 
                <tr>
                  <td className=' hide-on-mobile-nav'>Sijainti</td>
                  <td>{latLong.join(', ')}</td>
              </tr>
              }
              
              <tr>
                <td className=' hide-on-mobile-nav'></td>
                <td className="d-flex justify-content-end" >
                  {/* <button className="btn btn-secondary me-1"  onClick={props.closeModal}>X sulje</button> */}
                  <button
                    onClick={()=>handleSave()}
                    className="btn btn-primary"
                    disabled={!(reason) && (parseInt(cHour) !== parseInt(hour) || parseInt(cMinute) !== parseInt(minute))}
                  >
                    { isSaving ? <ClipLoader color={'#ffffff'} loading={true} css={''} size={15}/> : <FaSave color={'#ffffff'} />}
                    <span className="ms-1 text-white" >{isSaving ? 'Tallentaa' : saveText}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>}
        </div>
      </div>
  )
};

export default TaskModalTimeSummary;