import React , {useEffect, useState} from 'react';
import {
  getCurrentInspection, createSaveResultUrl, apiRequest, changeInspectionAllResults, getInspectionResult
} from '../../services/inspectionService'
import {Card} from 'react-bootstrap';
import SanitizedHTML from 'react-sanitized-html';
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import InspectionItem from './InspectionItem'
import HorizontalLabelPositionBelowStepper from "../common/StepBarHorizontal";
import VerticalLinearStepper from "../common/StepBarVertical";
import InspectionSummary from "./InspectionSummary"
import TabBar from '../navbar/TabBar';
import {saveTaskInspection} from '../../services/logisticsService';

const InspectionDetail = props => {
  const q_params = new URLSearchParams(props.location.search);
  const [pageNumber, setPageNumber] = useState(q_params.get('page') ? parseInt(q_params.get('page')) : 0);
  const [maxPageNumber, setMaxPageNumber] = useState(100);
  const [inspectionAll, setInspectionAll] = useState(getCurrentInspection);
  const [inspectionGroup, setInspectionGroup] = useState(null);
  const [inspection, setInspection] = useState(null);
  const [formResults, setFormResults] = useState({});
  const [stepNames, setStepNames] = useState({});
  const [taskId, setTaskId] = useState({});
  const [mandatoryInputs, setMandatoryInputs] = useState([]);
  const [mandatoryImages, setMandatoryImages] = useState([]);
  const [errorSteps, setErrorSteps] = useState([]);
  const [inspectionStartInfo, setInspectionStartInfo] = useState({
    plate: null,
    make: null,
    model: null,
    model_year: null,
    mileage: null,
    inspectionId: null,
  });

  const addFormResults = (key, value) => {
    console.log("addFormResults");
    let updatedFormResults = formResults
    updatedFormResults[key] = value
    setFormResults(updatedFormResults);
  }

  const clearFormResults = () => {
    console.log("clearFormResults");
    let updatedFormResults = {}
    setFormResults(updatedFormResults);
  }

  useEffect(() => {
    tryParseJson(inspectionAll, pageNumber)
    const q_params = new URLSearchParams(props.location.search);
    setTaskId(q_params.get('task'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);


  const setInspectionFromString = (str) => {
    try {
      let json = JSON.parse(str);
      setInspection(json.attributes)
      console.log("setInspectionFromString", json.attributes);
      return 'ok'
    } catch (error) {
      console.log("setInspectionFromString error", error);
    }
  }

  const tryParseJson = (str, groupNumber) => {
    try {
      let json = JSON.parse(str);
      setMaxPageNumber(json.attributes.inspection_groups.data.length)
      setInspectionGroup(json.attributes.inspection_groups.data[groupNumber])
      setInspection(json.attributes)
      setStepNames(json.attributes.inspection_groups.data.map((x)=>(x.attributes.name)))
      // addFormResults("group_id", json.attributes.inspection_groups.data[groupNumber].id);
      let items = json.attributes.inspection_groups.data[groupNumber].attributes.inspection_items.data;
      addFormResults("id", json.id)
      setInspectionStartInfo({
        plate: json.attributes.plate,
        make: json.attributes.make,
        model: json.attributes.model,
        model_year: json.attributes.model_year,
        mileage: json.attributes.mileage,
        inspectionId: json.id,
      })
      for (let key in items) {
        let item = items[key]
        const inspection_result = item.attributes.inspection_result
        const resultId = inspection_result.data.id;
        const kind = inspection_result.data.attributes.kind;
        const value = inspection_result.data.attributes.value;
        addFormResults(resultId, value)
        if (kind.replace('_only','').replace('_images','') === "state" ) {
          addFormResults(`reason${resultId}`, inspection_result.data.attributes.value_text)
        }
      }
      return json;
    } catch (error) {
      console.log("tryParseJson error", error);
    }
  }

  const groupCard = (inspectionGroup) => {
    if (inspectionGroup) {
      return (
        <Card className='mt-3'>
          {inspectionGroup && <Card.Header>{inspection.plate} {inspectionGroup.attributes.name} ({pageNumber+1}/{maxPageNumber+1})</Card.Header>}
          <Card.Body>
              <div className="text-sm" >
                {inspectionGroup.attributes.name!==inspectionGroup.attributes.description && inspectionGroup.attributes.description}
                <SanitizedHTML html={inspectionGroup.attributes.info} />
              </div>
             {inspectionGroup.attributes.inspection_items.data.map((item) => (
               <InspectionItem
                key={item.id}
                item={item}
                formResults={formResults}
                setFormResults={setFormResults}
                inspectionStartInfo={inspectionStartInfo}
                mandatoryInputs={mandatoryInputs}
                setMandatoryInputs={setMandatoryInputs}
                mandatoryImages={mandatoryImages}
                setMandatoryImages={setMandatoryImages}
                saveResult={saveResult}
               />
               ))} 
          </Card.Body>
          <Card.Footer className="text-center">
            {pageNumber!==0 && <button 
              className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
              onClick={handlePreviousGroup} >
                  <span className="fs-12 me-1">
                    <FaArrowLeft />
                  </span>
                  <span className="me-1">Edellinen</span>
            </button>}
            {(pageNumber+1)<=maxPageNumber &&<button 
              disabled={mandatoryInputs.length > 0 || mandatoryImages.length > 0}
              className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
              onClick={handleNextGroup} >
                  <span className="me-1">Jatka</span>
                  <span className="fs-12 me-1">
                    <FaArrowRight />
                  </span>
            </button>}
          </Card.Footer>
        </Card>
      )
    } else {
      return null
    }
  } 
  

  const handleNextGroup = (e) => {
    saveResult().then((formResults) => {
      const nextPage = pageNumber+1
      changePage(nextPage)
    });
  };

  const handlePreviousGroup = (e) => {
    const prevPage = pageNumber-1
    changePage(prevPage)
  };

  const changePage = (page) => {
    console.log("changePage ",page);
    setPageNumber(page);
    saveTaskInspection(taskId, inspection.phase_id, inspection.id,  page, 'edit', `Change page ${page+1}`)
    if (maxPageNumber > page) {
      tryParseJson(inspectionAll, page)
    } else {
      setInspectionGroup(null);
    }
  }

  const validatePage = (type) => {
    // let tmpMandatoryNameInputs = [];
    let tmpMandatoryInputs = [];
    inspectionGroup.attributes.inspection_items.data.forEach((item) =>  {
      const inspection_result = item.attributes.inspection_result.data.attributes
      if (['both','input'].includes(inspection_result.forced)) {
        let check_value = null
        if (type==='input') {
          check_value = formResults[inspection_result.id]
        } else {
          check_value = inspection_result.value
        }

        if (check_value) {
          console.log('Validate ' + type + ' ' + item.attributes.name + ': passed ' + inspection_result.id);
        } else {
          console.log('Validate ' + type + ' ' + item.attributes.name + ': failed ' + inspection_result.id);
          tmpMandatoryInputs = [...tmpMandatoryInputs, inspection_result.id]
        }
      }
    })
    setMandatoryInputs(tmpMandatoryInputs);
  }


  const validateAllPages = (currentPage) => {
    let tempSteps = []
    if (currentPage < 0) {
      currentPage = inspection.inspection_groups.data.length - 1
    }
    for (let i = 0; i < currentPage; i++) {
      let thisGroup = inspection.inspection_groups.data[i]
      let isPass = true
      thisGroup.attributes.inspection_items.data.forEach((item) =>  {
        const inspection_result = item.attributes.inspection_result.data.attributes
        if (['both','input'].includes(inspection_result.forced)) {
          let check_value = inspection_result.value
          if (!check_value) {
            check_value = formResults[inspection_result.id]
          }
          if (check_value) {
            // console.log('Page Validate ' + ' ' + item.attributes.name + ': passed ' + inspection_result.id);
          } else {
            console.log('Validate ' + ' ' + item.attributes.name + ': failed');
            isPass = false
          }
        }
      });

      if (isPass === false) {
        // console.log('Validate Page: ' + i + ' Failed' );
        // addErrorStep(i)
        tempSteps = [...new Set([...tempSteps, i])]
      } else {
        // console.log('Validate Page: ' + i + ' Passed' );
        tempSteps =  tempSteps.filter(item => item !== i)
        // removeErrorStep(i)
      }
    }
    setErrorSteps(tempSteps)
  }


   // Check when change value of form
  useEffect(() => {
    if (inspectionGroup) {
      validatePage('input');
    }
  }, [formResults, inspectionGroup]);

  // Save everytime when  formResults change
  // useEffect(() => {
  //   if (inspection) {
  //     saveResult();
  //   }
  // }, [formResults]);


  // This laready check because after change page get new formResults also
  // But still sometime it not check
  // Check when open/change page
  // useEffect(() => {
  //   if (inspectionGroup) {
  //     validatePage('value');
  //   }
  // }, [inspectionGroup]);


  useEffect(() => {
    if (inspectionGroup) {
      validateAllPages(-1);
    }
  }, [inspection]);

  const saveResult = async (isClear = true) => {
    let new_inspectionAll = inspectionAll
    for (let key in formResults) {
      let result = formResults[key]
      if (new_inspectionAll) {
        new_inspectionAll = changeInspectionAllResults(new_inspectionAll, pageNumber, key,  result)
      }
    }
    if (new_inspectionAll) {
      setInspectionAll(new_inspectionAll);
    }
    localStorage.setItem('currentInspection', new_inspectionAll); 
    setInspectionFromString(new_inspectionAll);
    apiRequest(createSaveResultUrl() ,"POST", formResults).then((result) => {
      if (isClear === true) { 
        clearFormResults() 
      }
    });

    return formResults
  } 



  return (
    <main>
      <TabBar 
            callBackUrl="/admin" 
            tabNumber={2} 
            plate={inspectionStartInfo.plate}
            inspectionId={inspectionStartInfo.inspectionId}
            brokenImageId=''
            dimensionId='' 
            id={taskId}
            saveResult={saveResult}
            />
      <div className="container mt-100">
        <div className="hide-on-mobile-nav">
          {stepNames.length > 0 
            && <HorizontalLabelPositionBelowStepper 
            stepNames={stepNames} 
            step={pageNumber} 
            changePage={changePage} 
            inspectionStatus={inspection.status}
            errorSteps={errorSteps}
            saveResult={saveResult}
            />}
           
        </div>
        <div className="hide-on-non-mobile">
          {stepNames.length > 0 
            && <VerticalLinearStepper 
            stepNames={stepNames} 
            step={pageNumber} 
            changePage={changePage} 
            inspectionStatus={inspection.status}
            errorSteps={errorSteps}
            saveResult={saveResult}
            />}
        </div>
        {groupCard(inspectionGroup)}
        {(pageNumber === maxPageNumber) && (<div>
          <InspectionSummary 
            inspectionAll={inspectionAll}  
            page={pageNumber} 
            changePage={changePage}
            errorSteps={errorSteps}
            />
          </div>)}
      </div>
      {/* <DropZoneModal /> */}
    </main>
  );
};



export default InspectionDetail;