import React, { useEffect, useState } from "react";
import { inspectionUrl, placeUrl } from "../../services/inspectionService";
import InspectionRow from "./InspectionRow";
import axios from "axios";
import auth from "../../services/authService";
import Select from "react-select";
import { FaPlus, FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import Pagination from "../Pagination";

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return items.slice(startIndex, items.length).slice(0, pageSize);
}

const Inspection = () => {
  const [inspectionParams, setInspectionParams] = useState({
    plate: null,
    place: null,
    status: "waiting_for_inspection",
    size: 10,
  });
  const [inspections, setInspections] = useState([]);
  const [allInspections, setAllInspections] = useState([]);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startBackgroundFetch, setStartBackgroundFetch] = useState(false);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: inspectionParams.size,
    count: inspectionParams.size,
  });

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const token = auth.getToken();
      const url = inspectionUrl(
        inspectionParams.plate,
        inspectionParams.place,
        inspectionParams.status,
        0,
        inspectionParams.size
      );
      const response = await axios.get(url, {
        headers: {
          Authorization: "Token " + token,
          //"Content-Type": "application/json",
        },
      });

      let availableData = response.data.data;
      setInspections(availableData);
    };

    const getPlace = async () => {
      const token = auth.getToken();
      const url = placeUrl();
      const response = await axios.get(url, {
        headers: {
          Authorization: "Token " + token,
          //"Content-Type": "application/json",
        },
      });

      setPlaces(response.data.data);
    };
    getPlace();
    getData().then(() => {
      setLoading(false);
      setStartBackgroundFetch(true);
    });
  }, [inspectionParams]);

  useEffect(() => {
    const getBackgroundData = async () => {
      const token = auth.getToken();
      const url = inspectionUrl(
        inspectionParams.plate,
        inspectionParams.place,
        inspectionParams.status,
        inspectionParams.size,
        ""
      );

      const response = await axios.get(url, {
        headers: {
          Authorization: "Token " + token,
          //"Content-Type": "application/json",
        },
      });

      const foundInspections = response.data.data;
      const all_inspections = [...inspections, ...foundInspections];
      const pSize =
        inspectionParams.size < all_inspections.length
          ? inspectionParams.size
          : all_inspections.length;

      const paginated_inspections = paginate(
        all_inspections,
        paginationParams.currentPage,
        pSize
      );
      setPaginationParams({
        ...paginationParams,
        pageSize: pSize,
        count: all_inspections.length,
      });
      setAllInspections(all_inspections);
      setInspections(paginated_inspections);
    };
    if (startBackgroundFetch) {
      getBackgroundData();
      setStartBackgroundFetch(false);
    }
  }, [startBackgroundFetch]);

  const handleChange = (e) => {
    setInspectionParams({
      ...inspectionParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePlace = (e) => {
    setInspectionParams({ ...inspectionParams, place: e.value });
  };

  const handleChangeStatus = (e) => {
    setInspectionParams({ ...inspectionParams, status: e.value });
  };

  const placeOptions = places.map(function (place) {
    const place_detail = place.attributes;
    return { value: place_detail.id, label: place_detail.name };
  });

  const statusOptions = [
    { value: "waiting", label: "Odottaa" },
    { value: "waiting_for_inspection", label: "Odottaa tarkastusta" },
    { value: "waiting_for_acceptance", label: "Odottaa hyväksymistä" },
    { value: "accepted", label: "Valmis" },
  ];

  const handlePageChange = (page) => {
    setPaginationParams({ ...paginationParams, currentPage: page });
    const paginated_inspections = paginate(
      allInspections,
      page,
      paginationParams.pageSize
    );
    setInspections(paginated_inspections);
  };

  const handleLogOut = () => {
    auth.logout();
  };

  return (
    <main>
      <div className="container">
        <h1 className="fs-3 text-primary my-3">logistiikka</h1>
        <div className="w-100 border rounded-3 p-3 mt-3">
          <div className="row">
            <div className="col-sm-3 col-md-3">
              <div className="mb-3">
                <label htmlFor="plate" className="form-label">
                  Rekisterinumero
                </label>
                <input
                  type="text"
                  className="form-control no-shadow-btn"
                  name="plate"
                  onChange={handleChange}
                  value={inspectionParams["plate"]}
                />
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <label className="form-label">Toimipiste</label>
              <Select
                name="place"
                options={placeOptions}
                onChange={handleChangePlace}
                // value={inspectionParams["place"]}
                placeholder="Toimipiste..."
                isSearchable
                value={placeOptions.label}
              />
            </div>
            <div className="col-sm-3 col-md-3">
              <label className="form-label">Tilanne</label>
              <Select
                name="status"
                options={statusOptions}
                onChange={handleChangeStatus}
                placeholder="Tlanne..."
                isSearchable
                value={statusOptions.filter(
                  (option) => option.value === inspectionParams.status
                )}
              />
            </div>
            <div className="col-sm-3 col-md-3 text-end">
              <Link
                to={{
                  pathname: "/admin/inspection_start",
                  state: {
                    displayType: "new",
                    params_place: inspectionParams.place,
                    params_plate: inspectionParams.plate,
                    placeOptions: placeOptions,
                    places: places,
                  },
                }}
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap mt-3"
              >
                <span className="fs-12 me-1">
                  <FaPlus />
                </span>
                <span className="me-1">Uusi Tarkastus </span>
              </Link>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-sm-3 col-md-3">
                  <div className="mb-3">
                    <label htmlFor="plate" className="form-label">
                      Kpl/sivu
                    </label>
                    <select
                      name="size"
                      className="form-select"
                      onChange={handleChange}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 my-3 border rounded-3 p-3">
          <div className="table-responsive">
            <table className="table table-borderless table-hover">
              <thead>
                <tr>
                  <th style={{ width: "60px" }}></th>
                  <th></th>
                  <th>Rekisterinumero</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Toimipiste</th>
                  <th>Tarkistus vaiheet</th>
                  <th>Tilanne</th>
                  <th></th>
                  <th>Tarkastuspäivä</th>
                </tr>
              </thead>
              <tbody className="fs-12">
                {loading ? (
                  <tr>
                    <td colSpan="9">
                      <div className={"w-100 text-center"}>
                        <ScaleLoader
                          color={"blue"}
                          loading={loading}
                          css={""}
                          size={150}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  inspections.map((inspection) => (
                    <InspectionRow
                      key={inspection.id}
                      inspection={inspection}
                      setInspections={setInspections}
                      inspections={inspections}
                    />
                  ))
                )}
              </tbody>
            </table>
            <div className="my-3">
              <Pagination
                itemsCount={paginationParams.count}
                pageSize={paginationParams.pageSize}
                currentPage={paginationParams.currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Inspection;
