import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CancelIcon from "@material-ui/icons/Cancel";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import MapIcon from '@material-ui/icons/Map';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1020,
    position: "fixed",
    top: 72,
  },
}));

const TabBar = (props) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = React.useState(0);
  const [plate, setPlate] = React.useState("");
  const [id, setId] = React.useState("");
  const [inspectionId, setInspectionId] = React.useState("");
  const [callBackUrl, setCallBackUrl] = React.useState("/admin");

  useEffect(() => {
    setId(props.id);
    setPlate(props.plate);
    setTabNumber(props.tabNumber);
    setInspectionId(props.inspectionId);
    if (props.callBackUrl) setCallBackUrl(props.callBackUrl);


  }, [props]);

  const handleChange = (event, newValue) => {
    setTabNumber(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <div className="d-block d-sm-none align-self-center my-2">
          <Link to="/admin" className="text-decoration-none">
            {plate && `Ajoneuvo: ${plate}`}
          </Link>
        </div>
        <Tabs
          value={tabNumber}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            icon={<CancelIcon color="secondary" />}
            label={plate ? `${plate}` : `${id}`}
            to={callBackUrl}
            component={Link}
            disabled={inspectionId === ""}
          />
          <Tab
            label="Tehtävän tiedot"
            icon={<AssignmentTurnedInIcon />}
            to={`/admin/task/${id}`}
            component={Link}
            disabled={id === ""}
          />
          <Tab
            label="Task logger"
            icon={<LibraryAddCheckIcon />}
            to={`/admin/task_logger/${id}`}
            component={Link}
            disabled={id === ""}
          />
          <Tab
            label="Kartta"
            icon={<MapIcon />}
            to={`/admin/task_map/${id}`}
            component={Link}
            disabled={id === ""}
          />

          <Tab
            label="Sulje"
            icon={<CancelIcon color="secondary" />}
            to={callBackUrl}
            component={Link}
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TabBar;
