export function parseISOString(s) {
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function isoFormatDMY(d) {  
  function pad(n) {return (n<10? '0' :  '') + n}
  return pad(d.getUTCDate()) + '.' + pad(d.getUTCMonth() + 1) + '.' + d.getUTCFullYear() + ' ' + pad(d.getUTCHours()) + ':' +  pad(d.getUTCMinutes());
}

export function isoFormatDate(d) {  
  function pad(n) {return (n<10? '0' :  '') + n}
  return pad(d.getUTCDate()) + '.' + pad(d.getUTCMonth() + 1) + '.' + d.getUTCFullYear();
}


export function dateISOToString1(s) { 
  // 2021-09-02T05:39:11.000Z
  let d = parseISOString(s)
  // yyyy-MM-ddThh:mm 
  function pad(n) {return (n<10? '0' :  '') + n}
  return d.getUTCFullYear() + '-' + pad(d.getUTCMonth() + 1) + '-' + pad(d.getUTCDate()) + 'T' + pad(d.getUTCHours()) + ':' +  pad(d.getUTCMinutes());
}

export function dateISOToStringT(s) { 
  // 2021-09-02T05:39:11.000Z
  let  d = new Date(s)
  // yyyy-MM-ddThh:mm 
  function pad(n) {return (n<10? '0' :  '') + n}
  return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate()) + 'T' + pad(d.getHours()) + ':' +  pad(d.getMinutes());
}

export function tzUtcToLocalHm(s) {
  // '2022-08-08T09:00:00.000Z'
  let str_local = ''
  let dt = new Date();
  if (s && s !== '') {
    dt = new Date(s)
  }

  let hour = dt.getHours().toString().padStart(2, '0')
  let min = dt.getMinutes().toString().padStart(2, '0')
  return hour + ':' + min;
}

export function dtUtcToLocalString(input_dt) {
  let str_local = ''
  let dt = new Date();
  if (input_dt && input_dt !== '') {
    dt = new Date(input_dt)
  }

  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  let hour = dt.getHours().toString().padStart(2, '0')
  let min = dt.getMinutes().toString().padStart(2, '0')
  let sec = dt.getSeconds().toString().padStart(2, '0')
  return d + "." + m + "." + y + " " + hour + ":" + min;
}


export function getFormatTimestamp(input_dt) {
  let str_utc = ''
  let str_local = ''
  let dt = new Date();
  if (input_dt && input_dt !== '') {
    dt = new Date(input_dt)
  }

  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  let hour = dt.getHours().toString().padStart(2, '0')
  let min = dt.getMinutes().toString().padStart(2, '0')
  let sec = dt.getSeconds().toString().padStart(2, '0')
  str_local = d + "." + m + "." + y + " " + hour + ":" + min + ":" + sec;
  
  d = dt.getUTCDate().toString().padStart(2, '0')
  m = (dt.getUTCMonth() + 1).toString().padStart(2, '0')
  y = dt.getFullYear()
  hour = dt.getUTCHours().toString().padStart(2, '0')
  min = dt.getUTCMinutes().toString().padStart(2, '0')
  sec = dt.getUTCSeconds().toString().padStart(2, '0')
  str_utc = y + "-" + m + "-" + d + " " + hour + ":" + min + ":" + sec+ ' UTC';

  return [str_utc, str_local]
}


export function getFormatTimeStringToISO(input_dt) {
  let str_iso = ''
  let dt = new Date();
  if (input_dt && input_dt !== '') {
    dt = new Date(input_dt)
  }

  // const offset = (new Date()).getTimezoneOffset();
  // console.log("timezoneOffset", offset)
  // var timezoneOffsetText =  `${offset<0? '+':'-'}${pad(parseInt(Math.abs(offset/60)), 2)}${pad(Math.abs(offset%60), 2)}`


  let d = dt.getUTCDate().toString().padStart(2, '0')
  let m = (dt.getUTCMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  let hour = dt.getUTCHours().toString().padStart(2, '0')
  let min = dt.getUTCMinutes().toString().padStart(2, '0')
  let sec = dt.getUTCSeconds().toString().padStart(2, '0')
  str_iso = y + "-" + m + "-" + d + " " + hour + ":" + min + ":00.000+00:00"

  return str_iso
}

function pad(number, length){
  var str = "" + number
  while (str.length < length) {
      str = '0'+str
  }
  return str
}


export function dtToYMD(dt) {
  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  return y + "-" + m + "-" + d;
}

export function dtToDMY(dt) {
  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  return d + '.' + m + '.' + y;
}

export function tzToHm(s) {
  // '2022-08-08T09:00:00.000Z'
  let dt = parseISOString(s)
  let hour = dt.getUTCHours().toString().padStart(2, '0')
  let min = dt.getUTCMinutes().toString().padStart(2, '0')
  return hour + ':' + min;
}
 
export function msToHM (ms, isQuater = false) {
  // 1- Convert to seconds:
  var seconds = ms / 1000;
  
  // For case use only quater minute 0, 15, 30, 45
  var t_minutes = parseInt(seconds / 60)
  // var round_up_min = (((t_minutes + 7.5)/15 | 0) * 15) % 60;
  var round_up_min = (Math.ceil(t_minutes / 15) * 15) % 60 ;
  // console.log("round_up_min=", round_up_min)

  // 2- Extract hours:
  var hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours

  // 3- Extract minutes:
  var minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute

  // 4- Keep only seconds not extracted to minutes:
  seconds = parseInt(seconds % 60);

  // console.log("real hours=", `${hours}:${minutes} `)

  // For case use only quater minute 0, 15, 30, 45
  if (isQuater === true) {
    if (round_up_min < minutes) {
      hours += 1 
    } 
    minutes = round_up_min
    // console.log("isQuater hours=", `${hours}:${minutes} `)
  }
  return [hours, minutes, seconds]
}

export function secToHMString (s) {
  return secToHourMinute(s)[0] + ':' + secToHourMinute(s)[1]
}

export function secToHourMinute(d) {
  if (d) {
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    return [h, m]
  } else {
    return [0, 0]
  }
}

export function dayForInput(dt = null) {
  if (dt === null) { 
    dt = new Date(); 
  } 
  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  return `${y}-${m}-${d}`
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function dtToMonthYear(dt) {
  let m = capitalizeFirstLetter(dt.toLocaleString('fi-FI', { month: 'long' }))
  let y = dt.getFullYear()
  return `${m} ${y}`
}

export function weekNumber(currentDate) {
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) /(24 * 60 * 60 * 1000));       
  return Math.ceil(days / 7);
}

export function getAllWeekdays(selecedDate) {
  console.log("getAllWeekdays", selecedDate)
  console.log("getAllWeekdays getDate", selecedDate.getDate())
  let weekdays = []
  let dt = new Date(selecedDate)
  dt.setDate(selecedDate.getDate() - (selecedDate.getDay() - 1))
  console.log("firstDay=", dtToDMY(dt))
  for (let i = 0; i < 5; i++) {
    let d = new Date(dt)
    d.setDate(dt.getDate() + i)
    weekdays.push(d)
  }
  console.log("weekdays=", weekdays)
  return weekdays
}

export function firstDayOfMonth(dt) {
  let d = dt.getDate()
  let m = dt.getMonth()
  let y = dt.getFullYear()
  let firstDay =  new Date(y, m, 1)
  firstDay.setDate(firstDay.getDate() - (firstDay.getDay() - 1));
  
  return firstDay;
}

export function plusDays(dt, days) {
  console.log("plusDays dt=", dt)
  console.log("plusDays days=", days)
  var tempDate = new Date();
  tempDate.setDate(dt.getDate()+ days);
  console.log("tempDate result=", tempDate)
  return tempDate;
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function isPresent(string) {
  return string !== null && string !== undefined && string !== ''
}

export function diffTimeToHMString(start, end) {
  let result = ''
  if (start && end) {
    let start_time = Date.parse(`01/01/2011 ${start.substring(0,5)}:00`)
    let end_time = Date.parse(`01/01/2011 ${end.substring(0,5)}:00`)
    let diff = end_time - start_time
    let times = msToHM(diff)
    //  add 0 if only one digit
    times[0] = times[0].toString().padStart(2, '0')
    times[1] = times[1].toString().padStart(2, '0')
    if (times[0] && times[1] && isNaN(times[0]) === false && isNaN(times[1]) === false) {
      result = `${times[0]}:${times[1]}`
    } else {
      result = `00:00`
    }
  } 
  return result
}

export function formatActualWorkTimeList(allTimeList) {
  let result = []
  // sort by record_time aplhabetically
  allTimeList.sort((a, b) => (a.time > b.time) ? 1 : -1)  
  let start = null;
  let end = null;
  let id = null;
  let date = null;
  let previous = null;
  allTimeList.forEach((item, index) => {
    // console.log(index, item.record_type, item.time)
    id = result.length
    date = item.date
    if (item.record_type === 'start') {
      start = item.time
    } else {
      end = item.time
    } 
    // 1. if first item is stop, then add 00:00 start
    if (index === 0 && item.record_type === 'stop') {
      // console.log(index, "case 1", start, end)
      result.push({id: id, date: date, start_time: '00:00', end_time: end})
      start = null;
      end = null;
    }

    // 2. Get start and end time in same day
    if (start !== null && end !== null) {
      // console.log(index, "case 2 ", start, end)
      result.push({
        id: id,
        start_time: start,
        end_time: end,
        date: date
      })
      start = null;
      end = null;
    }

    // 3. If last record is start time, then set end time to 23:59
    if (index === allTimeList.length - 1 && item.record_type === 'start') {
      // console.log(index, "case 3 last record ", start, end)
      result.push({
        id: id,
        start_time: start,
        end_time: '23:59',
        date: date
      })
    } 

    // 4. This record_type is stop and previous is stop , replace old end time with this end time
    if (previous !== null && item.record_type === 'stop' && previous.record_type === 'stop') {
      // console.log(index, "case 4, update previous end time ")
      result[id - 1].end_time = end
      start = null;
      end = null;
    }

    // 5. This record_type is start and previous is start, do nothing
    if (previous !== null && item.record_type === 'start' && previous.record_type === 'start') {
      // console.log(index, "case 5, update start time to previous")
      result[id].start_time = previous.time
    } 

    previous = item
  })
  
  return result
}

export function stringDtFiToDt(date1) {
  // Convert dates to a format JavaScript can understand
  // exapmle: date1 = '01.01.2021 00:00:00', date2 = '01.01.2021 08:00:00'
  const [day1, month1, rest1] = date1.split(".");
  const [year1, time1] = rest1.split(" ");
  const jsDate1 = new Date(`${year1}-${month1}-${day1}T${time1}`);
  return jsDate1
}