import React, { useState, useEffect } from 'react';
import { getTask, statusFormOptions, saveTask, getAdditionalSales  } from "../../services/logisticsService";
import TabBar from '../navbar/TabBar';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import {FaSave, FaPencilAlt, FaReceipt, FaTachometerAlt, FaTimes, FaCheck, FaEye} from "react-icons/fa";
import { 
  parseISOString, 
  isoFormatDMY, 
  isoFormatDate, 
  dateISOToString1, 
  secToHourMinute, 
  dtUtcToLocalString } 
from "../../services/formatService";
import {ClipLoader} from "react-spinners";
import Modal from 'react-modal';
import TaskModalTimeSummary from './TaskModalTimeSummary'
import TaskModalPicture from './TaskModalPicture';
import InputHourMinute from '../inputs/InputHourMinute';
import { ButtonGroup , ToggleButton } from 'react-bootstrap'
import { ContactSupportOutlined } from '@material-ui/icons';


const TaskItem = props => {
  const [id, setId] = useState('');
  const [taskParams, setTaskParams] = useState(null);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [hourActual, setHourActual] = useState(null);
  const [minuteActual, setMinuteActual] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFinishTask, setIsFinishTask] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPictureOpen, setIsModalPictureOpen] = useState(false);
  const [reason, setReason] = useState(null);
  const [drivingSec, setDrivingSec] = useState(0); 
  const [deliverySec, setDeliverySec] = useState(0); 
  const [travelSec, setTravelSec] = useState(0); 
  const [waitingSec, setWaitingSec] = useState(0); 
  const [isDelivered, setIsDelivered] = useState(null); 
  const [isScrive, setIsScrive] = useState(null); 
  const [isComprehensive, setIsComprehensive] = useState(false);
  const [driverNote, setDriverNote] = useState({
    paymentInfo: null,
    scrive: null,
    insuranceDetail: null,
    insuranceCompany: null,
    changeCar: null,
    comprehensive: null,
    deliverMileage: null
  });
  const [modalPictureType, setModalPictureType] = useState(null);
  const [taskTypes, setTaskTypes] = useState({});
  const [latLong, setLatlong] = useState([0,0]);
  const [additionalSales, setAdditionalSales] = useState(null);

  useEffect(() => {
    getTask(props.match.params.id).then((response) => {
      console.log("getTask response", response)
      if (response) {
        setTaskParams(response)
      }
      const hourMinute = secToHourMinute(response.total_task_time_second)
      setHour(hourMinute[0])
      setMinute(hourMinute[1])

      const hourMinuteActual = secToHourMinute(response.total_actual_time_second)
      setHourActual(hourMinuteActual[0])
      setMinuteActual(hourMinuteActual[1])
      console.log("responsedriver_note ", response.driver_note)
      setDriverNote(response.driver_note)
      if (response.driver_note) {
        setIsScrive(response.driver_note.scrive)
        setIsComprehensive(response.driver_note.comprehensive)
      }
      if (response.total_time_list) {        
        const total_time_list = response.total_time_list
        if (total_time_list.driving) {setDrivingSec(total_time_list.driving)}
        if (total_time_list.delivery) {setDeliverySec(total_time_list.delivery)}
        if (total_time_list.travel) {setTravelSec(total_time_list.travel)}
        if (total_time_list.waiting) {setWaitingSec(total_time_list.waiting)}
        if (total_time_list.is_delivered) {setIsDelivered(total_time_list.is_delivered)}
      }
      setTaskTypes(response.task_types)
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatlong([position.coords.latitude, position.coords.longitude])
      });
    } 

    getAdditionalSales().then((response) => {
      console.log("getAdditionalSales response", response)
      if (response) {
        setAdditionalSales(response.data)
      }
    });
  }, [props]);


  useEffect(() => {
    if (isFinishTask === true) {

    }
  }, [isFinishTask]);

  const handleChangeStatus = (e) => {
    setTaskParams({ ...taskParams, status: e.value });
    saveTask(taskParams.id, 
      e.value, null, null, null, null, null, null, null, latLong).then((response) => {
      console.log("saveTask response", response)
      if (response && response.data.status === "SUCCESS") {
        console.log("response.data.task ", response.data.task)
        setTaskParams(response.data.task)
      }
    });
    if (e.value === 'suoritettu' ) {
      setIsModalOpen(true);
    }

  };

  const handleChange = (e) => {
    setDriverNote({ ...driverNote, [e.target.name]: e.target.value });
  };
  
  const handleCheckbox = (e) => {
    setDriverNote({ ...driverNote, [e.target.name]: e.target.checked });
    console.log("driverNote ", driverNote)
  };

  const handleTaskTypes = (e) => {
    console.log("taskTypes", taskTypes)
    console.log("handleTaskTypes value", e.target.value)
    console.log("handleTaskTypes", e.target.checked)
    setTaskTypes({ ...taskTypes, [e.target.value]: e.target.checked });
  }

  const handelChangeInfo = (e) => {
    setTaskParams({ ...taskParams, transport_info: e.target.value });
  }



  const handleSave = (e) => {
    setIsSaving(true)
    saveTask(taskParams.id, 
      null, 
      null, 
      null, 
      null, 
      null, 
      {
        driving: drivingSec,
        delivery: deliverySec,
        travel: travelSec,
        waiting: waitingSec,
        is_delivered: isDelivered
      },
      null,
      null,
      null,
      driverNote,
      taskTypes
      ).then((response) => {
      console.log("saveTask response", response)
      setIsSaving(false)
    });
  };



  const openModal = () => {
    setIsModalOpen(true);
  }
  const openModalPicture = (type) => {
    setModalPictureType(type)
    setIsModalPictureOpen(true);
  }

  const afterOpenModal = () => {}
  const afterCloseModal = () => {}

  const closeModal = () => {
    setIsModalOpen(false);
  }
  const closeModalPicture = () => {
    setIsModalPictureOpen(false);
  }

  const customStyles = {
    content: {
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      // marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
      width: '100%',
      overlay: {zIndex: 1060}
    },
  };

  const clickIsDelivered = (e) => {
    let new_value = "x"
    // To make null case click same button again
    if (e.target.value == 1 ) {
      new_value = isDelivered == 1  ?  null : 1
    } else if (e.target.value == 0) {
      new_value = isDelivered == 0  ?  null : 0
    }
    if (new_value !== "x") {
      setIsDelivered(new_value);
    }   
  }

  const clickIsScrive = (e) => {
    let new_value = "x"
    // To make null case click same button again
    if (e.target.value == 1 ) {
      new_value = isScrive == 1  ?  null : 1
    } else if (e.target.value == 0) {
      new_value = isScrive == 0  ?  null : 0
    }
    if (new_value !== "x") {
      setIsScrive(new_value);
      setDriverNote({ ...driverNote, scrive: new_value });
    }   
  }

  const clickIsComprehensive = (e) => {
    let new_value = "x"
    // To make null case click same button again
    if (e.target.value == 1 ) {
      new_value = isComprehensive == 1  ?  null : 1
    } else if (e.target.value == 0) {
      new_value = isComprehensive == 0  ?  null : 0
    }
    if (new_value !== "x") {
      setIsComprehensive(new_value);
      setDriverNote({ ...driverNote, comprehensive: new_value });
    }   
  }

  
  return (
    <main style={{ minHeight: '80vh' }}>
      {taskParams
        ? (<TabBar
          tabNumber={1}
          plate={`${taskParams.target_reg_num}`}
          id={props.match.params.id}
        />)
        : (<TabBar
          tabNumber={1}
          plate=''
          id={props.match.params.id}
        />)}
      <div className="container mt-100">
        <div className="w-100 rounded-3 p-3">
          {taskParams && <Table bordered hover size="sm">
            <thead>
              <tr>
                <th colSpan="2">{`${taskParams.target_reg_num}  ${taskParams.target_make}`} {latLong.join(',')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th width="20%">Tila</th>
                <td>
                  <Select
                    name="status"
                    options={statusFormOptions.filter(option => option.value !== 'all')}
                    onChange={handleChangeStatus}
                    placeholder='Tlanne...'
                    isSearchable
                    value={statusFormOptions.filter(option => option.value === taskParams.status)}
                  />
                </td>
              </tr>
              <tr>
              <th>Tehtävä</th>
                <td>
                  <label className='me-5'>
                    <input
                      type="checkbox"
                      name="taskTypePickup"
                      checked={taskTypes.pickup}
                      value="pickup"
                      onChange={handleTaskTypes}
                    />
                    &nbsp;Nouto
                  </label>
                  <label className='me-5'>
                    <input
                      type="checkbox"
                      name="taskTypeDelivery"
                      checked={taskTypes.delivery}
                      value="delivery"
                      onChange={handleTaskTypes}
                    />
                    &nbsp;Toimitus
                  </label>
                  <label className='me-5'>
                    <input
                      type="checkbox"
                      name="taskTypeMove"
                      checked={taskTypes.move}
                      value="move"
                      onChange={handleTaskTypes}
                    />
                    &nbsp;Siirto
                  </label>
                  <label className='me-5'>
                    <input
                      type="checkbox"
                      name="taskTypeOther"
                      checked={taskTypes.other}
                      value="other"
                      onChange={handleTaskTypes}
                    />
                    &nbsp;Muu työ
                  </label>                                    
                </td>
              </tr>
              <tr>
                <th>Tavoiteaika <br />(tunti:minuutti)</th>
                <td>
                  <div className="d-flex align-items-center justify-content-start " >
                    <div className="" >
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="hour"
                        onChange={e => setHour(e.target.value)}
                        value={hour}
                        disabled={true}
                      /> 
                    </div>
                    <div className="" > : </div>
                    <div className="" >                   
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="minute"
                        onChange={e => setMinute(e.target.value)}
                        value={minute}
                        disabled={true}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Tehtävän kokonaisaika <br />(tunti:minuutti)</th>
                <td>
                  <div className="d-flex align-items-center justify-content-start " >
                    <div className="" >
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="actual_hour"
                        value={hourActual}
                        disabled={true}
                      /> 
                    </div>
                    <div className="" > : </div>
                    <div className="" >                   
                      <input
                        type="number"
                        className="form-control no-shadow-btn"
                        name="actual_minute"
                        value={minuteActual}
                        disabled={true}
                      />
                    </div>
                    <div >&nbsp;&nbsp;</div>
                    <div className="flex-glow">
                      <input
                      type="text"
                      className="form-control no-shadow-btn"
                      name="reason"
                      value={taskParams.total_time_reason}
                      disabled={true}
                    />
                    </div>
                    <div >  
                      <button
                        onClick={openModal}
                        className="btn btn-primary align-items-center ms-1"
                      >
                        <FaPencilAlt color={'#ffffff'} />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>ToimitusaikaX</th>
                <td>
                  { taskParams.delivery_time &&  isoFormatDMY(parseISOString(taskParams.delivery_time)) }
                  { false &&  <input
                    type="datetime-local"
                    name="toimitus_aika"
                    value={dateISOToString1(taskParams.delivery_time)}
                /> }
                </td>
              </tr>

              <tr>
                <th>Aika nyt</th>
                <td>
                  {dtUtcToLocalString(new Date())} <br />
                </td>
              </tr>
              <tr>
                <th>Ajoaika<br />(tunti:minuutti)</th>
                <td>
                  <InputHourMinute sec={drivingSec} disabled={false} setChangeSec={setDrivingSec}/>
                </td>
              </tr>
              <tr>
                <th>Luovutusaika <br />(tunti:minuutti)</th>
                <td>
                  <InputHourMinute sec={deliverySec} disabled={false} setChangeSec={setDeliverySec}/>
                </td>
              </tr>
              <tr>
                <th>Matkustusaika <br />(tunti:minuutti)</th>
                <td>
                  <InputHourMinute sec={travelSec} disabled={false} setChangeSec={setTravelSec}/>
                </td>
              </tr>
              <tr>
                <th>Odotusaika <br />(tunti:minuutti)</th>
                <td>
                  <InputHourMinute sec={waitingSec} disabled={false} setChangeSec={setWaitingSec}/>
                </td>
              </tr>
              <tr>
                <th>Yhteensä <br />(tunti:minuutti)</th>
                <td>
                  <InputHourMinute sec={(drivingSec + deliverySec + travelSec + waitingSec)} disabled={true}/>
                </td>
              </tr>
              <tr>
                <th>Toimitettu</th>
                <td>
                  <div >
                    <ButtonGroup toggle className="w-100 mb-1 button-group">
                    <ToggleButton
                          key="delivery1"
                          type="radio"
                          variant="light"
                          value={1}
                          checked={isDelivered === 1}   
                          onClick={e => clickIsDelivered(e)}
                          className="input-state"
                        >
                          <FaCheck/> Kyllä
                        </ToggleButton>
                        <ToggleButton
                          key="delivery2"
                          type="radio"
                          variant="light"
                          value={0}
                          checked={isDelivered === 0}
                          onClick={e => clickIsDelivered(e)}
                          className="input-state"
                        >
                          <FaTimes/> Ei
                        </ToggleButton>
                    </ButtonGroup>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Maksu
                {taskParams.receipt_url &&
                  <a href={taskParams.receipt_url} target="_blank">
                    <FaEye className='ms-2' />
                  </a>
                  }
                </th>
                <td className='d-flex align-items-center justify-content-start'>
                <input
                  type="text"
                  className="form-control"
                  name="paymentInfo"
                  placeholder='Maksutiedot e.g. päätteeseen... '
                  value={driverNote.paymentInfo}
                  onChange={handleChange}
                />
                <div >  
                      <button
                        onClick={() => openModalPicture('receipt')}
                        className="btn btn-primary align-items-center ms-1"
                      >
                        <FaReceipt color={'#ffffff'} />
                      </button>
                </div>
                </td>
              </tr>
              <tr>
                <th>Mittarilukema
                {taskParams.mileage_url &&
                  <a href={taskParams.mileage_url} target="_blank">
                    <FaEye className='ms-2' />
                  </a>
                  }
                </th>
                <td className='d-flex align-items-center justify-content-start'>
                <input
                  type="text"
                  className="form-control"
                  name="deliverMileage"
                  placeholder='Toimitettu mittarilukema... '
                  value={driverNote.deliverMileage}
                  onChange={handleChange}
                />
                <div >  
                      <button
                        onClick={() => openModalPicture('mileage')}
                        className="btn btn-primary align-items-center ms-1"
                      >
                        <FaTachometerAlt color={'#ffffff'} />
                      </button>
                    </div>
                </td>
              </tr>
              <tr>
                <th>Scrive</th>
                <td>
                  <div >
                    <ButtonGroup toggle className="w-100 mb-1 button-group">
                    <ToggleButton
                          key="scrive1"
                          type="radio"
                          variant="light"
                          value={1}
                          checked={isScrive === 1}   
                          onClick={e => clickIsScrive(e)}
                          className="input-state"
                        >
                          <FaCheck/> Kyllä
                        </ToggleButton>
                        <ToggleButton
                          key="scrive2"
                          type="radio"
                          variant="light"
                          value={0}
                          checked={isScrive === 0}
                          onClick={e => clickIsScrive(e)}
                          className="input-state"
                        >
                          <FaTimes/> Ei
                        </ToggleButton>
                    </ButtonGroup>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Vakuutustapa</th>
                <td>
                <input
                  type="text"
                  className="form-control"
                  name="insuranceDetail"
                  value={driverNote.insuranceDetail}
                  onChange={handleChange}
                  list="insuranceOptions" 
                />
                <datalist id="insuranceOptions">
                    <option value="Autollecom tehnyt" />
                    <option value="Asiakas hoitaa itse" />
                    <option value="Ei" />
                </datalist>
                </td>
              </tr>
              <tr>
                <th>Vakuutusyhtiö</th>
                <td>
                <input
                  type="text"
                  className="form-control"
                  name="insuranceCompany"
                  value={driverNote.insuranceCompany}
                  onChange={handleChange}
                  list="insuranceCompanies"
                />
                <datalist id="insuranceCompanies">
                    <option value="Fennia" />
                    <option value="Lähitapiola" />
                    <option value="OP" />
                    <option value="Pop" />
  
                </datalist>
                </td>
              </tr>
              <tr>
                <th>Kasko tehnyt</th>
                <td>
                  <div >
                    <ButtonGroup toggle className="w-100 mb-1 button-group">
                    <ToggleButton
                          key="compre1"
                          type="radio"
                          variant="light"
                          value={1}
                          checked={isComprehensive === 1}   
                          onClick={e => clickIsComprehensive(e)}
                          className="input-state"
                        >
                          <FaCheck/> Kyllä
                        </ToggleButton>
                        <ToggleButton
                          key="compre2"
                          type="radio"
                          variant="light"
                          value={0}
                          checked={isComprehensive === 0}
                          onClick={e => clickIsComprehensive(e)}
                          className="input-state"
                        >
                          <FaTimes/> Ei
                        </ToggleButton>
                    </ButtonGroup>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Vaihturi</th>
                <td>
                <input
                  type="text"
                  className="form-control"
                  name="changeCar"
                  value={driverNote.changeCar}
                  onChange={handleChange}
                />
                </td>
              </tr>
              <tr>
              <th>Lisämyynti</th>
                <td>
                  {additionalSales && additionalSales.map((sale, index) => (
                    <label key={index} className='me-5'>
                    <input
                      type="checkbox"
                      name={sale.name}
                      checked={driverNote[sale.name]}
                      onChange={handleCheckbox}
                    />
                    &nbsp;{sale.label}
                  </label>    
                  ))}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button
                    onClick={handleSave}
                    className="w-100 h-100 btn btn-primary align-items-center"
                  >
                    { isSaving ? <ClipLoader color={'#ffffff'} loading={true} css={''} size={15}/> : <FaSave color={'#ffffff'} />}
                    <span className="ms-1 text-white" >{isSaving ? 'Tallentaa' : 'Tallenna'}</span>
                  </button>
                </td>
              </tr>
              <tr>
                <th>Nouto osoite</th>
                <td>
                  {taskParams.pickup_address && taskParams.pickup_address}&nbsp;
                  {taskParams.pickup_postcode && taskParams.pickup_postcode}&nbsp;
                  {taskParams.pickup_location && taskParams.pickup_location}&nbsp;
                </td>
              </tr>
              <tr>
                <th>Toimitus osoite</th>
                <td>
                  {taskParams.delivery_address && taskParams.delivery_address}&nbsp;
                  {taskParams.delivery_postcode && taskParams.delivery_postcode}&nbsp;
                  {taskParams.delivery_location && taskParams.delivery_location}&nbsp;
                </td>
              </tr>
              <tr>
                <th>Kohde</th>
                <td>
                  {`${taskParams.target_reg_num}`}&nbsp;
                  {taskParams.target_type && taskParams.target_type}&nbsp;
                  <br/>
                  {taskParams.target_make && taskParams.target_make}&nbsp;
                  {taskParams.target_model && taskParams.target_model}&nbsp;
                  {taskParams.target_color && taskParams.target_color}&nbsp;
                </td>
              </tr>
              <tr>
                <th>Kohde asiakas</th>
                <td>
                  {(taskParams.customer_firstname && taskParams.customer_lastname)  && `${taskParams.customer_firstname} ${taskParams.customer_lastname}`}
                  <br/>
                  {(taskParams.customer_company && taskParams.customer_b_code) && `${taskParams.customer_company} ${taskParams.customer_b_code}`}
                  <br/>
                  {(taskParams.customer_address && taskParams.customer_postcode && taskParams.customer_city) && `${taskParams.customer_address} ${taskParams.customer_postcode} ${taskParams.customer_city}`}
                  <br/>
                  {(taskParams.customer_phone && taskParams.customer_email) && `${taskParams.customer_phone} ${taskParams.customer_email}`}
                </td>
              </tr>
              <tr>
                <th>Myyntitili asiakas</th>
                <td>
                  {taskParams.account_customer_firstname && taskParams.account_customer_firstname}&nbsp;
                  {taskParams.account_customer_lastname && taskParams.account_customer_lastname}&nbsp;
                  <br/>
                  {taskParams.account_customer_address && taskParams.account_customer_address}&nbsp;
                  {taskParams.account_customer_postcode && taskParams.account_customer_postcode}&nbsp;
                  {taskParams.account_customer_city && taskParams.account_customer_city}&nbsp;
                  <br/>
                  {taskParams.account_customer_phone && taskParams.account_customer_phone}&nbsp;
                  {taskParams.account_customer_email && taskParams.account_customer_email}&nbsp;
                </td>
              </tr>
              <tr>
                <th>Vaihto</th>
                <td>
                  {taskParams.exchange_reg_num && taskParams.exchange_reg_num}&nbsp;
                  {taskParams.exchange_variety && taskParams.exchange_variety}&nbsp;
                  <br/>
                  {taskParams.exchange_make && taskParams.exchange_make}&nbsp;
                  {taskParams.exchange_model && taskParams.exchange_model}&nbsp;
                  {taskParams.exchange_type && taskParams.exchange_type}&nbsp;
                  <br/>
                  {taskParams.exchange_color && taskParams.exchange_color}&nbsp;
                </td>
              </tr>
              <tr>
                <th>Tehtävä lisätiedot</th>
                <td>{taskParams.more_info}</td>
              </tr>
              <tr>
                <th>Toimitus info</th>
                <td>{taskParams.transport_info}</td>
              </tr>
              {/* <tr>
                <th>Kuljetus info</th>
                <td>
                  <input
                    type="text"
                    className="form-control no-shadow-btn"
                    name="info"
                    onChange={handelChangeInfo}
                    value={taskParams.kuljetus_info}
                  />
                </td>
              </tr> */}
              <tr>
                <th>Ajon Id</th>
                <td>{taskParams.id}</td></tr>
            </tbody>
          </Table>}
        </div>
        <div className="w-100 rounded-3 p-3">
          {taskParams && <Table bordered hover size="sm">
            <thead>
              <tr>
                <th colSpan="2">Kohde tiedot : {`${taskParams.target_reg_num}  ${taskParams.target_make}`}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="20%" >Merkki</td>
                <td>{taskParams.target_make}</td>
              </tr>
              <tr>
                <td>Malli</td>
                <td>{taskParams.target_model}</td>
              </tr>
              <tr>
                <td>Laji</td>
                <td>{taskParams.target_variety}</td>
              </tr>
              <tr>
                <td>Tyyppi</td>
                <td>{taskParams.target_type}</td>
              </tr>
              <tr>
                <td>Vari</td>
                <td>{taskParams.target_color}</td>
              </tr>
              <tr>
                <td>Ensi Rekis</td>
                <td>
                  { taskParams.target_first_reg }
                </td>
              </tr>
              <tr>
                <td>Viime Tarkastus</td>
                <td>
                  { taskParams.target_last_inspection }
                </td>
              </tr>
              <tr>
                <td>Mileage</td>
                <td>{taskParams.target_mileage}</td>
              </tr>
            </tbody>
          </Table>}
        </div>
        {taskParams && <Modal
            isOpen={isModalOpen}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            onRequestClose={closeModal}
            contentLabel={`Ajettu Kokonaisaika`}
            ariaHideApp={false}
            style={customStyles}
            >
            <TaskModalTimeSummary 
              closeModal={closeModal} 
              id={taskParams.id}
              status={taskParams.status}
              events={taskParams.events}
              hourActual={hourActual}
              minuteActual={minuteActual}
              reason={taskParams.total_time_reason}
              taskParams={taskParams}
              setTaskParams={setTaskParams}
              setMinuteActual={setMinuteActual}
              setHourActual={setHourActual}
            />
          </Modal>}
          {taskParams && <Modal
            isOpen={isModalPictureOpen}
            onRequestClose={closeModalPicture}
            contentLabel={`Lisää kuva`}
            ariaHideApp={false}
            style={customStyles}
            >
            <TaskModalPicture 
              closeModal={closeModalPicture} 
              id={taskParams.id}
              modalPictureType={modalPictureType}
            />
          </Modal>}
      </div>
    </main>
  );
};

export default TaskItem;