import React , {useState} from 'react';

const InputDate = props => {
  const [textValue, setTextValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
  });

  const handleChange = (e) => {
    setTextValue({ ...textValue, value: e.target.value });
    props.setFormResults({ ...props.formResults, [e.target.name]: e.target.value });
  };

  const notValidStyle = {borderColor: 'red', borderWidth: 1}
  
  return (
    <>
      <input
        type="date"
        className="form-control no-shadow-btn mb-1"
        value={textValue.value}
        name={props.item.inspection_result.data.id}
        onChange={handleChange}
        style={props.mandatoryInputs.includes(props.item.inspection_result.data.id) ? notValidStyle : null}
      />
    </>
  );
};


export default InputDate;