import React , {useState,useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import {ClipLoader} from "react-spinners";
import { FaTimes, FaPlus, FaAngleLeft } from "react-icons/fa";
import { uploadReceipt, deleteReceipt, confirmInvoice} from '../../services/invoiceService';
import pdfIcon from "../../images/pdf-icon.svg";
import { FaFilePdf, FaPaperPlane } from "react-icons/fa";

const InvoiceAttachImage = props => {
  const {
    acceptedFiles, 
    fileRejections,
    getRootProps, 
    getInputProps
  } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, application/pdf",
    multiple: true
  });
  const [fileUploaded, setfileUploaded] = useState(false);
  const [fileUploading, setfileUploading] = useState(false);
  const [tempFiles, setTempFiles] = useState([]);
  const [countChangingFileAction, setCountChangingFileAction] = useState(0);
  const [currentPictureUrl, setCurrentPictureUrl] = useState(props.receiptUrl);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(props.pdfUrl);
  const [uploadedPicture, setUploadedPicture] = useState(null);
  const [uploadedPdf, setuploadedPdf] = useState(null);
  const [uploadResultText, setuploadResultText] = useState(null);
  const [uploadedReceipts, setUploadedReceipts] = useState(props.receiptUrls);
  const [isComfirming, setIsComfirming] = useState(null);

  let files = tempFiles.map((file, idx) => {
    let imgSrc = URL.createObjectURL(file);
    if (file.type === "application/pdf") {
      imgSrc = pdfIcon;
    }
    return (
    <div  key={idx} >
      {/* <li>
        {file.path} - {file.size} bytes
      </li> */}
      <div
        className="position-relative border p-2 m-1"
        style={{
          height: "100px",
          width: "1 00px",
        }}
      >
        <img
          src={imgSrc}
          alt={file.name}
          className="cover-img"
        />
        <div
          onClick={() => remove(idx)}
          className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 rounded-circle text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
        >
          <FaTimes />
        </div>
      </div>
    </div>
  )});

  let showReceipts = uploadedReceipts.map((file, idx) => {
    let imgSrc = file.url;
    if (imgSrc.includes('.pdf')) {
      imgSrc = pdfIcon;
    } 
    return (
      <div key={idx} >
      <div
        className="position-relative border p-2 m-1"
        style={{
          height: "100px",
          width: "100px",
        }}
      >
        <a href={file.url} target="_blank" rel="noreferrer">
          <img
            src={imgSrc}
            alt="Kuitti"
            className="cover-img"
          />
        </a>
        <div
          onClick={() => handleDeleteReceipt(file.file_id)}
          className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 rounded-circle text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
        >
          <FaTimes />
        </div>
      </div>
    </div>
    )
  });

  useEffect(() => {
    console.log('isComfirming', isComfirming);
  }, [isComfirming]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log("New files are drop", acceptedFiles)
    }
    setuploadResultText("");
    setTempFiles(acceptedFiles);

    if (acceptedFiles.length > 0) {
      handleUploadImages();
    }
  }, [acceptedFiles]);

  const remove = idx => {
    acceptedFiles.splice(idx, 1);        // remove the file from the array
    setTempFiles(acceptedFiles)
    // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
  };

  const handleConfirm = async _ => {
    setIsComfirming(true);
    //  do handleUploadImages if there is any file
    if (acceptedFiles.length > 0) {
      await handleUploadImages();
    }
    console.log('handleConfirm done');
    let result = await confirmInvoice(props.invoiceId);
    console.log('handleConfirm result', result);
    if (result && result.result === 'done') {
      setuploadResultText("Kululasku on lähetetty, voit sulkea tämän ikkunan.");
      setIsComfirming(false);
    } else {
      setIsComfirming(null);
      setuploadResultText("Laskun vahvistus epäonnistui");
    }
  }

  const handleUploadImages = async _ => {    
    setfileUploading(true)
    console.log('handleUploadImages amount', acceptedFiles.length);
    let success_file_names = [];
    for (let key in acceptedFiles) {
      let file = acceptedFiles[key]
      let result = await uploadReceipt(file, props.invoiceId)
      if (result && result.data && result.data.result === 'Success') {
        setUploadedReceipts(result.data.invoice_receipts);
        console.log('handleUploadImages success', result.data.invoice_receipts.length);
        success_file_names.push(file.name);
        setuploadResultText(`${file.name} ladattu onnistuneesti (${file.size})`);
      } else {
        setuploadResultText(`Tiedoston ${file.name} lataus epäonnistui`);
      }
    }
    setuploadResultText(`Tiedostot (${success_file_names.length}): ${success_file_names.join(', ')} on ladattu`);
    console.log('handleUploadImages done');
    setfileUploaded(true);
    setfileUploading(false);
    setTimeout(function() {
      removeAllFiles();
    }, 10);
  }

  const removeAllFiles = () => {
    files = null;
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    // acceptedFiles = [];
    setTempFiles(acceptedFiles);
    files = null;
    setfileUploaded(false);
     // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
    // props.closeModal();
  }

  const handleDeleteReceipt = fileId => {
    console.log('deleteReceipt idx:', fileId);
    deleteReceipt(fileId, props.invoiceId)
      .then((result) => {
        console.log('deleteReceipt status:', result.data);
        setUploadedReceipts(result.data.invoice_receipts);
        setuploadResultText(`Kuitti poistettu`);
      }
    );
  }

  // const uploadFile = (file) => {
  //   setfileUploaded(false);
	// 	const formData = new FormData();

	// 	formData.append('file', file);
	// 	formData.append('invoice_id', props.invoiceId);
  //   setuploadResultText(`Loading file ${file.name}, size: ${file.size}`);
  //   uploadReceipt(file, props.invoiceId)
  //     .then((result) => {
	// 			console.log('uploadReceipt status:', result.data);
  //       setuploadResultText(`Upload result: ${JSON.stringify(result.data)}`); 
  //       // setUploadedPicture(result.data.attach_picture_url);
  //       // setuploadedPdf(result.data.attach_pdf_url);
  //       setUploadedReceipts(result.data.invoice_receipts);
  //       if (!result.data.attach_picture_url && !result.data.attach_pdf_url) {
  //         setuploadResultText("Lataus epäonnistui!!!, valitse kuva ja yritä uudelleen.");
  //       } else {
  //         setuploadResultText("Kululasku on lähetetty, voit sulkea tämän ikkunan.");
  //         setCurrentPictureUrl(null);
  //         setCurrentPdfUrl(null);
  //       }
	// 		})
	// 		.catch((error) => {
  //       setuploadResultText(`Lataus epäonnistui, ${error}`);
	// 		}).finally(()=>{
  //       setfileUploaded(true);
  //       setfileUploading(false);
  //       setTimeout(function() {
  //         removeAllFiles();
  //       }, 10);
  //     });
	// };
	
  return (
      <div >
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="mb-3 text-primary">Lisää kuittikuva</h4>
            <span>Laku nro.: {props.invoiceNumber} / id: {props.invoiceId}</span>
            <section className="container">
              {(uploadedReceipts.length > 0) &&
                <aside>
                  <h5>Ladatut kuitit ({uploadedReceipts.length})</h5>
                  <div className="w-100 d-flex flex-wrap">{showReceipts}</div>
                </aside>}
              {currentPictureUrl && <img src={currentPictureUrl}  width="100"/>}
              {currentPdfUrl && ( <a target='_blank' href={currentPdfUrl}>
                              <FaFilePdf />
                              Näytä kuitti (PDF)
                            </a>) }
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Vedä ja pudota kuvan tähän tai klikkaa tästä valitaksesi kuvan</p>
                <p>(Vain jpg, jpeg, png kuvat tai pdf-tiedostot hyväksytään)</p>
              </div>
              {acceptedFiles.length > 0 && 
              <aside>
                <h5>Ladattavat tiedostot ({acceptedFiles.length})</h5>
                <div className="w-100 d-flex flex-wrap">{files}</div>
              </aside>}
              <div className="modal-footer">
              {(acceptedFiles.length > 0) &&
                <button
                type="button"
                className="btn btn-danger"
                onClick={handleUploadImages}
                disabled={acceptedFiles.length < 1 || fileUploading || fileUploaded}
              >
                {(fileUploading || fileUploaded)
                          ? (fileUploaded ? 'Ladattu' : <ClipLoader color={'#9B9B9B'} loading={(fileUploading)} css={''} size={15}/>)
                          : <><FaPlus color={'#ffffff'} /> Lisää kuitit</> }   
              </button>}
            </div>
              <center>
                <h4 className='text-primary mt-1'>{uploadResultText}</h4>                            
              </center>
            </section>
          </div>
          <div className="modal-footer">
          <div className="w-100 d-flex align-items-center justify-content-center">
            {(isComfirming === false) 
              ? 
              <button 
                className="btn btn-primary text-white ms-1 px-5" 
                onClick={props.closeModal}
              >
              <><FaTimes /> Sulje</>
              </button>
              :
              <>
                <button 
                  className="btn btn-primary text-white ms-2 me-1 px-5" 
                  onClick={() => props.setIsStep2(false)}>
                  <><FaAngleLeft color={'#ffffff'} /> Takaisin</>
                </button>
                <button 
                  className="btn btn-danger text-white ms-1 px-5" 
                  onClick={handleConfirm}
                  disabled={fileUploading || isComfirming || uploadedReceipts.length < 1}>
                  <><FaPaperPlane color={'#ffffff'} /> Vahvista</>
                </button>
              </>}
          </div>
          </div>
        </div>
      </div>
  );
};



export default InvoiceAttachImage;