import { postRequest, getRequest , deleteRequest, postFormDataRequest, updateRequest} from "./httpServices";
import config from "../config";
import auth from "./authService";
const mainUrl = config.easyad_api_url;

export async function getInvoices(email, startDate, endDate) {
  let params = {}
  let url = `${mainUrl}/api/v1/invoice?email=${email}&from=${startDate}&to=${endDate}`;  
  let result = await getRequest(url, params);
  if (result) {
    return result['data'];
  } else {
    return null;
  }
}

export async function getInvoice(invoice_id) {
  let params = {}
  let url = `${mainUrl}/api/v1/invoice/${invoice_id}`;  
  let result = await getRequest(url, params);
  if (result) {
    return result['data'];
  } else {
    return null;
  }
}

export async function createInvoice(invoice) {
  let url = `${mainUrl}/api/v1/invoice`;  
  const formData = new FormData();
  formData.append('invoice', JSON.stringify(invoice))

  let result = await postFormDataRequest(url, formData)
  if (result) {
    return result['data'];
  } else {
    return null;
  }
}

export async function updateInvoice(invoice) {
  const url = `${mainUrl}/api/v1/invoice/${invoice.id}`;  
  const params = {invoice: JSON.stringify(invoice)}
  return updateRequest(url, params)
}

export async function confirmInvoice(invoice_id) {
  const url = `${mainUrl}/api/v1/confirm_invoice?invoice_id=${invoice_id}`;
  let params = {}
  let result = await getRequest(url, params);
  if (result) {
    return result['data'];
  } else {
    return null;
  }
}

export async function uploadReceipt(file, invoice_id) {
  const formData = new FormData();
  formData.append('file',file)
  formData.append('invoice_id',invoice_id)
  const url = `${mainUrl}/api/v1/invoice/save_receipt_file`;  
  return postFormDataRequest(url, formData)
}

export function deleteReceipt(file_id, invoice_id) {
  const url = `${mainUrl}/api/v1/delete_invoice_receipt_file?file_id=${file_id}&invoice_id=${invoice_id}`;
  return deleteRequest(url)
}

export function deleteInvoice(invoice_id) {
  const url = `${mainUrl}/api/v1/invoice/${invoice_id}`;  
  return deleteRequest(url)
}

export function netvisorStatusText(status) {
  switch (status) {
    case 'open':
      return 'uusi lasku'
    case 'infactualverification':
      return 'asiatarkastuksessa'
    case 'approved':
      return 'hyväksynnässä'
    case 'accepted':
      return 'hyväksytty'
    case 'acceptorrejected':
      return 'hylätty'      
    case 'contentsupervisorrejected':
      return 'hylätty'      
    default:
      return status
  }
}

