import React, { Component } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InspectionPdfMain from "./InspectionPdfMain";
import { Card } from "react-bootstrap";
import { FaSave, FaArrowLeft, FaFilePdf } from "react-icons/fa";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { parseISOString, isoFormatDMY, dtUtcToLocalString } from "../../services/formatService";
import { saveInspection, getInspectionFromId} from "../../services/inspectionService";

class InspectionSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      info: "",
      make: "",
      mileage: "",
      model: "",
      model_year: "",
      phase_id: "",
      phase_name: "",
      phase_step: "",
      place_id: "",
      place_locality: "",
      place_name: "",
      plate: "",
      status: "",
      created_at: "",
      updated_at: "",
      isComplete: false,
      inspection: {},
    };
  }

  componentDidMount() {
    // Do this componentDidMount only first time
    console.log("componentDidMount only first time");
    const inspectionAll = this.props.inspectionAll;
    this.tryParseJson(inspectionAll);
    this.init();
  }

  init() {}

  componentDidUpdate(prevProps) {
    // Do this componentDidUpdate only props change
    if (prevProps.inspectionAll !== this.props.inspectionAll) {
      console.log("componentDidUpdate only props change");
      this.tryParseJson(this.props.inspectionAll);
    }
  }

  getLastestInspection = (id) => {
    getInspectionFromId(id).then((result) => {
      console.log("getLastestInspection getInspectionFromId result", result)
      if (result.data && result.data.data[0]) {
        this.setState({
          inspection: result.data.data[0].attributes,
        })
      }
    })
  }

  tryParseJson = (str) => {
    try {
      let json = JSON.parse(str);
      const info = json.attributes;
      this.getLastestInspection(info.id);
      console.log("oldinspection resultxx", info)
      this.setState({
        id: info.id,
        // inspection: info,
        plate: info.plate ? info.plate:'',
        make: info.make ? info.make:'',
        mileage: info.mileage ? info.mileage:'',
        model: info.model ? info.model:'',
        model_year: info.model_year ? info.model_year:'',
        phase_name: info.phase_name,
        place_name: info.place_name,
        info: info.info,
        updated_at: info.updated_at,
        phase_step: info.phase_step,
        isComplete: info.status === "accepted",
      });
    } catch (error) {
      console.log("tryParseJson error", error);
    }
  };

  handleCheckComplete = (event) => {
    this.setState({ isComplete: event.target.checked });
  };

  handleBack = () => {
    this.props.changePage(this.props.page - 1);
  };

  handleSaveInspection = () => {
    console.log("handleSaveInspection", this.state.id, this.state.isComplete);
    if (this.state.isComplete === true) {
      let body = {
        inspection: {
          status: "accepted",
        },
      };
      saveInspection(this.state.id, body).then((result) => {
        console.log("saveInspection result", result);
      });
    }
  };

  render() {
    return (
      <div>
        <div>
          <Card className="mt-3">
            <Card.Header>
              Yhteenveto ({this.props.page + 1}/{this.props.page + 1})
            </Card.Header>
            <Card.Body>
              <div>{`${this.state.plate} ${this.state.make} ${this.state.model} ${this.state.model_year}`}
              {this.state.mileage && `${this.state.mileage} km`}
              </div>
              <div>
                {this.state.phase_step} {this.state.phase_name} /{" "}
                {this.state.place_name}
              </div>
              <div>
                Tarkastaja / Merkintä: {this.state.info} Päivitä:
                {dtUtcToLocalString(parseISOString(this.state.updated_at))}
              </div>
              <div className="text-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleCheckComplete}
                      checked={this.state.isComplete}
                      name="checkedA"
                      color="info"
                      disabled={this.props.errorSteps.length > 0}
                    />
                  }
                  label="Hyväksy tehtävä"
                />
              </div>
            </Card.Body>
            <Card.Footer className="text-center">
              <button
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
                onClick={() => this.handleBack()}
              >
                <span className="fs-12 me-1">
                  <FaArrowLeft />
                </span>
                <span className="ms-1">Edellinen</span>
              </button>
              <button
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
                onClick={() => this.handleSaveInspection()}
              >
                <span className="fs-12 me-1">
                  <FaSave />
                </span>
                <span className="me-1">Tallenna</span>
              </button>
            </Card.Footer>
          </Card>
        </div>
        <div>
          <h5>Yhteenveto Pdf</h5>
          <PDFViewer className={"w-100 height-100vh hide-on-mobile-nav"}>
            <InspectionPdfMain
              inspection={this.state.inspection}
              inspectionString={this.props.inspectionAll}
            />
          </PDFViewer>
          <PDFDownloadLink
            document={
              <InspectionPdfMain
                inspection={this.state.inspection}
                inspectionString={this.props.inspectionAll}
              />
            }
            fileName={`tarkastus_${this.state.plate}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <button className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap hide-on-non-mobile-nav">
                  <span className="fs-12 me-1">
                    <FaFilePdf />
                  </span>
                  <span className="me-1">Download Pdf</span>
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </div>
    );
  }
}

export default InspectionSummary;
