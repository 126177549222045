import React, { Component } from 'react';
import { ScaleLoader } from "react-spinners";
import { getTasks, startTodayTime, getDailyAllowance } from "../../services/logisticsService";
import TaskSelector from './TaskSelector';
import TaskTable from './TaskTable';
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import { FaPlus, FaPlay , FaStop } from "react-icons/fa";
import { MdLocationOff, MdLocationOn } from "react-icons/md";
import Modal from 'react-modal';
import TaskModalNewTask from './TaskModalNewTask';
import { dtUtcToLocalString, secToHourMinute, getFormatTimestamp, msToHM, parseISOString, capitalizeFirstLetter } 
  from "../../services/formatService";
import { AlertDayEnd } from './AlertDayEnd';
import { AlertDayStart } from './AlertDayStart';
//  TaskModalNewTask
class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      loading: true,
      status: 'odottaa_toimitusta',
      user_email: '',
      isModalOpen: false,
      todayStartTime: new Date(),
      todayTotalSecond: 0,
      istarted: null,
      showDayMoney: false,
      dayMoney: null,
      showAlertDayStart: false,
      alertTasks: [],
      daySummary: null,
      latLong: [0,0],
    }
  }

  componentDidMount() {
    const currentUser = auth.getCurrentUser();
    const email = currentUser ? currentUser.email : null;
    this.setState({
      user_email: email
    }, () => {
      getTasks(this.state.user_email, this.state.status).then((response) => {
        this.updateTasks(
          response
        );
      });
      startTodayTime(this.state.user_email).then((response) => {
        console.log("startTodayTime response", response);
        if (response.type === 'start') {
          this.setState({ istarted: true })
          this.setState({ todayStartTime: Date.parse(response.time), dayMoney: response.daily_allowance }, () => {
            this.setState({ todayTotalSecond: parseInt(Date.now()) - parseInt(this.state.todayStartTime) })
          });
          getTasks(this.state.user_email, 'odottaa_toimitusta').then((waitting_tasks) => {
            getTasks(this.state.user_email, 'suoritetaan').then((working_tasks) => {
              this.setState({ alertTasks: [...waitting_tasks, ...working_tasks] })
              this.setShowAlertDayStart(true);
            });
          });
        } else {
          this.setState({ istarted: false })
        }
      });
    });
    this.interval = setInterval(() => {
      if (this.state.istarted === false) {
        // clearInterval(this.interval);
        this.setState({ todayStartTime: Date.now() })
      }
      this.setState({ todayTotalSecond: parseInt(Date.now()) - parseInt(this.state.todayStartTime) })
    }, 10000);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log("latLong 2 !!", position.coords.latitude, position.coords.longitude)
        this.setState({ latLong: [position.coords.latitude, position.coords.longitude] })
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTasks = (data) => {
    this.setState({
      tasks: data,
      loading: false,
    });
  }

  beginFetch = () => {
    this.setState({ loading: true })
  }


  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  afterOpenModal = () => {}
  afterCloseModal = () => {
    console.log("afterCloseModal")
    window.location = "/admin";
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  startDay = (type) => {
    console.log("startDay", type);
    
    const tasks =  this.state.alertTasks.filter((t) => t.status === 'suoritetaan');
    if (type === 'stop') {
      console.log('Stop working tasks amount:', tasks.length)
      if (tasks.length > 0) {
        const confirmBox = window.confirm(
          `Tehtävät ${ tasks.map((t) => t.id).join(',')} muutetaan suljetuiksi. Muista täyttää tiedot?`
        )
        if (!confirmBox) {
          return;
        }
      }
    }
      
    console.log('Continue!!!!!!!!')
    this.setState({ istarted: type==='start' ? true : false })
    startTodayTime(this.state.user_email, this.state.todayStartTime, type, this.state.latLong).then((response) => {
      console.log("startTodayTime stamp response", response);
      if (type==='start' && response.time) {
        this.setState({ todayStartTime: Date.parse(response.time) })
      } else {
        this.setState({ todayStartTime: Date.now() })
      }
      this.setState({ daySummary: response }, () => {
        this.setState({ showDayMoney: type === 'stop' ? true : false })
      });
    });
    if (type === 'start') {
      this.setState({ showDayMoney: false })
      getTasks(this.state.user_email, 'odottaa_toimitusta').then((waitting_tasks) => {
        getTasks(this.state.user_email, 'suoritetaan').then((working_tasks) => {
          this.setState({ alertTasks: [...waitting_tasks, ...working_tasks] })
          this.setShowAlertDayStart(true);
        });
      });
    } else {
      this.setShowAlertDayStart(false);

    }
  }

  setShowDayMoney = (isShow, option) => {
    this.setState({ showDayMoney: isShow })
    let newDayMoney = null;
    if (option === 1) {
      newDayMoney = 'kokopäiväraha'
    } else if (option === 2) {
      newDayMoney = 'osapäiväraha'
    } 

    this.setState({ dayMoney: newDayMoney, showDayMoney: isShow }, () => {
      getDailyAllowance(this.state.user_email, this.state.dayMoney).then((response) => {
        console.log("getDailyAllowance New value response", response);
      });
    });
  }
  setShowAlertDayStart = (isShow) => {
    this.setState({ showAlertDayStart: isShow })
  }

  render() {
    const {isModalOpen} = this.state

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        overlay: {zIndex: 1060}
      },
    };

    return (
      <main style={{ minHeight: '80vh' }}>
        <div className="container">
          <div className='pt-1'>
            <AlertDayStart 
              show={this.state.showAlertDayStart} 
              setShow={this.setShowAlertDayStart}
              tasks={this.state.alertTasks}
              key="alert1"
              />
            <AlertDayEnd 
              show={this.state.showDayMoney} 
              setShow={this.setShowDayMoney}
              daySummary={this.state.daySummary}
              message='Mikä on päiväraha tänään??'
              option1='Kokopäiväraha' 
              option2='Osapäiväraha' 
              variant="success" 
              />
          </div>
          <div className="w-100 d-flex align-items-center  p-3">
            { (this.state.istarted===false) && <button
              type="button"
              className="btn btn-success text-white fs-14 rounded-pill me-2 text-nowrap flex-fill"
              disabled={false}
              onClick={() => this.startDay('start')}
            >
                          <span className="me-1">
                            <FaPlay />
                          </span>
                          <span className="me-1">Työpäivän aloitus</span>
                          {this.state.latLong.join('') !== '00' ? <MdLocationOn /> : <MdLocationOff /> }
                          <br />
                          <span className="me-1">({dtUtcToLocalString(this.state.todayStartTime)})</span>
              </button>  }     
              { (this.state.istarted===true) && <button
              type="button"
              className="btn btn-danger text-white fs-14 rounded-pill me-2 text-nowrap flex-fill"
              disabled={false}
              onClick={() => this.startDay('stop')}
            >
               <span className="me-1">
                            <FaStop />
                          </span>
                          <span className="me-1">Työpäivän lopetus</span>
                          {this.state.latLong.join('') !== '00' ? <MdLocationOn /> : <MdLocationOff /> }
                          <br />
                          <span className="me-1">
                            Aloitus: {dtUtcToLocalString(this.state.todayStartTime)}<br />
                            Yht.{this.state.todayTotalSecond && msToHM(this.state.todayTotalSecond)[0]} tunti {msToHM(this.state.todayTotalSecond)[1]} min
                          </span><br />
              </button>}     
          </div>
          <div className="w-100 d-flex align-items-center justify-content-between">
            
            <div>
              <h1 className="fs-3 text-primary my-3">Tehtävät</h1>
            </div>
            <div>
               <button
              type="button"
              className="btn btn-secondary text-white fs-14 rounded-pill me-2 text-nowrap "
              // onClick={()=> window.open(`http://localhost:3011/inspections/view_images/${props.item.inspection_result.data.id}`, 'view_image', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, height=600, width=900')}
              onClick={this.openModal}
            >
                          <span className="fs-12 me-1">
                            <FaPlus />
                          </span>
                          <span className="me-1">Uusi tehvävä</span>
              </button>              

            </div>
            </div>
          <div className="w-100 border rounded-3 p-3 mt-3">
            <TaskSelector updateTasks={this.updateTasks} beginFetch={this.beginFetch} />
          </div>
          <div className="w-100 pt-3 rounded ">
            {this.state.loading ?
              <div className={'w-100 text-center'}>
                <ScaleLoader color={'blue'} loading={this.state.loading} css={''} size={150} />
              </div> :
              <TaskTable tasks={this.state.tasks} key="taskTable1"/>
            }
          </div>

          <Modal
            isOpen={isModalOpen}
            onAfterOpen={this.afterOpenModal}
            onAfterClose={this.afterCloseModal}
            onRequestClose={this.closeModal}
            contentLabel={`Example Modal vvv`}
            ariaHideApp={false}
            style={customStyles}
            >
            <TaskModalNewTask 
            closeModal={this.closeModal} 
            />
          </Modal>
        </div>
      </main>
    );
  }
}

export default Task;