import React, { useState, useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import {ClipLoader} from "react-spinners";
import { FaTimes } from "react-icons/fa";
import { uploadPicture } from '../../services/logisticsService';
import pdfIcon from "../../images/pdf-icon.svg";

const TaskModalPicture = (props) => {
  const {
    acceptedFiles, 
    fileRejections,
    getRootProps, 
    getInputProps
  } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, application/pdf"
  });

  const [fileUploaded, setfileUploaded] = useState(false);
  const [fileUploading, setfileUploading] = useState(false);
  const [tempFiles, setTempFiles] = useState([]);
  const [countChangingFileAction, setCountChangingFileAction] = useState(0);

  const modelLabel = {
    receipt: 'Kuvamaksusta',
    mileage: 'Mittarilukema'
  }
  let files = tempFiles.map((file, idx) => {
    let imgSrc = URL.createObjectURL(file);
    if (file.type === "application/pdf") {
      imgSrc = pdfIcon;
    }
    return (
    <div key={idx} >
      {/* <li>
        {file.path} - {file.size} bytes
      </li> */}
      <div
        className="position-relative border p-2 m-1"
        style={{
          height: "200px",
          width: "200px",
        }}
      >
        <img
          src={imgSrc}
          alt={file.name}
          className="cover-img"
        />
        <div
          onClick={() => remove(idx)}
          className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 rounded-circle text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
        >
          <FaTimes />
        </div>
      </div>
    </div>
  )});

  

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log("New files are drop", acceptedFiles)
    }
    setTempFiles(acceptedFiles);
  }, [acceptedFiles]);

  const remove = idx => {
    acceptedFiles.splice(idx, 1);        // remove the file from the array
    setTempFiles(acceptedFiles)
    // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
  };


  const handleUploadImages = () => {
    setfileUploading(true)
    for (let key in acceptedFiles) {
      let file = acceptedFiles[key]
      uploadFile(file);
    }
  }


  
  const removeAllFiles = () => {
    files = null;
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    // acceptedFiles = [];
    setTempFiles(acceptedFiles);
    files = null;
    setfileUploaded(false);
     // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
    props.closeModal();
  }


  const uploadFile = (file) => {
    setfileUploaded(false);
		const formData = new FormData();

		formData.append('file', file);
		formData.append('invoice_id', props.id);


    uploadPicture(file, props.id, props.modalPictureType)
      .then((result) => {
				console.log('uploadPicture status:', result.data);  
			})
			.catch((error) => {
				console.error('Error:', error);
			}).finally(()=>{
        setfileUploaded(true);
        setfileUploading(false);
        setTimeout(function() {
          removeAllFiles();
        }, 4000);
      });
	};
	
  return (
      <div >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-8 text-left">
                <h2>Tehtävä {props.id}  {modelLabel[props.modalPictureType]} </h2>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <button className="btn btn-info"  onClick={props.closeModal}>X sulje</button>
              </div>
            </div>
            <section className="container">
              {props.receiptUrl && <img src={props.receiptUrl}  width="100"/>}
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Vedä ja pudota kuvan tähän tai klikkaa tästä valitaksesi kuvan</p>
                <p>(Vain jpg, jpeg, png kuvat tai pdf-tiedostot hyväksytään)</p>
              </div>
              <aside>
                <h5>Tiedostot ({acceptedFiles.length})</h5>
                <h6>{fileUploaded && <p>Lataus onnistui</p>}</h6>
                <div className="w-100 d-flex flex-wrap">{files}</div>
              </aside>
            </section>
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-primary"
              onClick={handleUploadImages}
              disabled={acceptedFiles.length < 1 || fileUploading || fileUploaded}
            >
              {(fileUploading || fileUploaded)
                        ? (fileUploaded ? 'Lataus onnistui, Sulkeutuu pian' : <ClipLoader color={'#9B9B9B'} loading={(fileUploading)} css={''} size={15}/>)
                        : ' Lähetä kuvat'}   
            </button>
          </div>
        </div>
      </div>
  );
};
export default TaskModalPicture;