import React, { useState, useEffect, useRef } from "react";
import { FaTimes, FaTrashAlt, FaSave } from "react-icons/fa";

import http, {
  postFormDataRequest,
  deleteRequest,
} from "../../services/httpServices";
import env_variable from "../../config";
import TabBar from "../navbar/TabBar";
import { useDropzone } from "react-dropzone";
import SalesImageModal from "./SalesImageModal";

const url = "/api/v1/inspection_gallery";
// Easy ad api url
const easy_api_url = env_variable.easyad_api_url;

function SalesGalleryItem(props) {
  const modalButton = useRef(null);
  const [salesImages, setSalesImages] = useState([]);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [tempFiles, setTempFiles] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filesToBeSubmitted, setFilesToBeSubmitted] = useState([]);
  const [parentId, setParentId] = useState("");
  const [syncData, setSyncData] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [inspection, setInspection] = useState({ id: "", plate: "" });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    const getSalesImages = async () => {
      // Get id from the url params
      const { params } = props.match;
      const id = params.id;
      setParentId(id);

      const response = await http.get(
        `${easy_api_url}${url}/${id}?template=sales`
      );
      const { data } = response;
      setSalesImages(data);
      setInspection(data[0]["inspection"]);
    };
    getSalesImages();
  }, [syncData]);

  useEffect(() => {
    setTempFiles(true);
  }, [tempFiles]);

  useEffect(() => {
    const addNewImage = async (formData) => {
      const response = await postFormDataRequest(
        `${easy_api_url}${url}`,
        formData
      );
      const { data } = response;
      const { status, data: savedData } = data;
      if (status === "created") {
        setSyncData(true);
      }
    };
    if (filesToBeSubmitted.length > 0) {
      filesToBeSubmitted.map((file) => {
        const formData = new FormData();

        formData.append("attach_image", file);
        formData.append("parent_id", parentId);
        formData.append("x", "0");
        formData.append("y", "0");
        formData.append("is_gallery", "true");
        formData.append("is_request_url", "true");

        addNewImage(formData);
      });
      acceptedFiles.splice(0, acceptedFiles.length);
      setFilesToBeSubmitted([]);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
    }
  }, [filesToBeSubmitted]);

  useEffect(() => {
    const deleteSalesImage = async () => {
      if (deleteItem) {
        const response = await deleteRequest(
          `${easy_api_url}${url}/${deleteItem}`
        );
        if (response) {
          let { status } = response;
          setSyncData(!syncData);
          setDeleteItem(null);
        }
      }
    };

    deleteSalesImage();
  }, [deleteItem]);

  let files = [...acceptedFiles];

  const handleOnDeleteTempImage = (index) => {
    acceptedFiles.splice(index, 1);
    setTempFiles(!tempFiles);
  };

  const handleImageOnClick = (e) => {
    let imgUrl = e.target.src;
    setModalImageUrl(imgUrl);
    modalButton.current.click();
  };

  const handleDeleteAllImages = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    setTempFiles(!tempFiles);
  };

  const handleSaveImages = () => {
    setFilesToBeSubmitted([...acceptedFiles]);
    setIsSubmitting(true);
  };

  const handleDeleteOnClick = (id) => {
    setDeleteItem(id);
  };

  return (
    <main>
      <TabBar
        callBackUrl="/admin/inspections"
        tabNumber={3}
        plate={inspection.plate}
        inspectionId={inspection.id}
        brokenImageId={parentId}
        dimensionId={inspection.plate}
        galleryId={parentId}
      />
      <div className="container mt-100">
        <div className="w-100 py-3 border-bottom">
          <h1 className="fs-3 text-primary">Auton myyntikuvat</h1>
          <section className="container">
            {isSubmitting ? (
              <div className="w-100 d-flex align-items-center justify-content-center py-5">
                <div
                  class="spinner-border border-primary"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span class="visually-hidden">Ladataan...</span>
                </div>
              </div>
            ) : (
              <div className="py-3">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>
                    Vedä ja pudota tiedostoja tähän tai klikkaa tästä
                    valitaksesi tiedostot
                  </p>
                </div>
                {acceptedFiles.length > 0 && (
                  <>
                    <div className="w-100 border rounded p-2 my-3">
                      <h3 className="fs-5 text-primary">Esikatselut</h3>
                      <div className="d-flex flex-wrap">
                        {files.map((file, index) => (
                          <div
                            key={file.name}
                            className="position-relative border p-2 m-1"
                            style={{
                              height: "200px",
                              width: "200px",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              className="cover-img"
                            />
                            <div
                              onClick={() => handleOnDeleteTempImage(index)}
                              className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 rounded-circle text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
                            >
                              <FaTimes />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center ">
                      <button
                        onClick={handleSaveImages}
                        type="button"
                        className="btn btn-primary text-white me-2 d-flex align-items-center justify-content-center"
                      >
                        <span className="d-block me-1 mb-1">
                          <FaSave />
                        </span>
                        <span className="d-block">Tallenna kuvat</span>
                      </button>
                      <button
                        onClick={handleDeleteAllImages}
                        type="button"
                        className="btn btn-danger d-flex align-items-center justify-content-center"
                      >
                        <span className="d-block me-1 mb-1">
                          <FaTrashAlt />
                        </span>
                        <span className="d-block">Poista kaikki kuvat</span>
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </section>
        </div>
        <div></div>
        <div className="w-100 py-4">
          <div
            className="tab-content"
            id="pills-tabContent"
            style={{ minHeight: "420px" }}
          >
            <div>
              <button
                ref={modalButton}
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#salesImageModal"
              ></button>
              <h3 className="fs-4 my-2 text-primary">Tallennetut kuvat</h3>
              <div className="row my-3">
                {salesImages.map((s_img) => (
                  <>
                    {s_img.gallery && (
                      <div
                        key={s_img.gallery.id}
                        className="col-sm-12 col-md-6 col-lg-4"
                      >
                        <div className="position-relative w-100 height-220-260 border rounded p-2">
                          <img
                            className="cover-image rounded"
                            src={s_img.gallery.attach_image_url}
                            alt={s_img.gallery.parent.plate}
                            onClick={handleImageOnClick}
                          />
                          <div
                            onClick={() =>
                              handleDeleteOnClick(s_img.gallery.id)
                            }
                            className="position-absolute top-0 end-0 box-size-30 bg-danger-15-transparent m-1 rounded-circle text-white fs-4 d-flex align-items-center justify-content-center cursor-pointer"
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SalesImageModal imgUrl={modalImageUrl} />
    </main>
  );
}

export default SalesGalleryItem;
