import React, { useState, useEffect, useRef } from 'react';
import { dtToMonthYear, firstDayOfMonth, weekNumber, 
  dtToYMD, addDays, dtToDMY, tzToHm, getAllWeekdays, capitalizeFirstLetter, 
  formatActualWorkTimeList, diffTimeToHMString, getFormatTimestamp, stringDtFiToDt } from '../../services/formatService';
import { getMonthSchedule, updatedSchedules, copyScheduleWeek, saveWorkActual } from '../../services/logisticsService';
import {FaTrashAlt, FaSave, FaPlus, FaCopy, FaPaste, FaTimes, FaPause, 
  FaEdit, FaCalendarWeek, FaAngleDoubleDown, FaAngleDoubleUp, FaCheck, 
  FaArrowDown, FaArrowUp, FaStar, FaStarHalf} 
from 'react-icons/fa';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Collapse } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import { InfoOutlined } from '@material-ui/icons';
import { MdLocationOn, MdLocationOff } from 'react-icons/md';

export const WorkCalendar = props => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [userEmail, setUserEmail] = useState(props.email);
  const [selectedActionDay, setSelectedActionDay] = useState(new Date());
  const [schedules, setSchedules] = useState([]);
  const [actualRecords, setActualRecords] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editStartTime, setEditStartTime] = useState(null);
  const [editEndTime, setEditEndTime] = useState(null);
  const [scheduleType, setScheduleType] = useState('w');
  const [saveIcon, setSaveIcon] = useState('FaSave');
  const [copiedWeek, setCopiedWeek] = useState(null);
  const [pasteWeek, setPasteWeek] = useState(null);
  const [dailyAllowance, setDailyAllowance] = useState(null);
  const [editStartActualTime, setEditStartActualTime] = useState(null);
  const [editEndActualTime, setEditEndActualTime] = useState(null);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openActual, setOpenActual] = useState(true);
  const [additionalTimeInput, setAdditionalTimeInput] = useState([]);
  const [dayTaskDescription, setDayTaskDescription] = useState('');
  const [actualErrorMessage, setActualErrorMessage] = useState(null);
  const [actualTimeDetails, setActualTimeDetails] = useState([]);
  // Stage 
  // '' or 'save' = button show Tallena
  // 'saving' = button show Loading icon
  // 'saved' = button show Tallennettu
  const [saveScheduleStage, setSaveScheduleStage] = useState('');
  const [saveActualStage, setSaveActualStage] = useState('');
  const [latLong, setLatlong] = useState([0,0]);
  const [saveActualButtonDisabled, setSaveActualButtonDisabled] = useState(false);

  const scrollToBottom = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
    console.log("scrollToBottom");
  }

  const radioScheduleTypes = [
    { name: 'Työpäivä', value: 'w', class: 'outline-success' },
    { name: 'Loma', value: 'h', class: 'outline-danger' },
  ];

  const radioAllowanceTypes = [
    { name: 'Koko päiväraha', value: 'kokopäiväraha', class: 'outline-primary' },
    { name: 'Osa päiväraha', value: 'osapäiväraha', class: 'outline-primary' },
    { name: 'Ei päiväraha', value: null, class: 'outline-primary' },
  ];

  useEffect(() => {   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLatlong([position.coords.latitude, position.coords.longitude])
      });
    } 
  }, [props]);

  useEffect(() => {
    getMonthSchedule(userEmail, selectedDay).then(result => {
      console.log("getMonthSchedule result",result);
      setSchedules(result.schedules);
      setActualRecords(result.actual);
      setActualTimeDetails(result.actual_detail);

      scrollToBottom();
    });
  }, [userEmail, selectedDay]);
  

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 2 seconds set the show value to normal
      if (saveScheduleStage==='saved') {
        setSaveScheduleStage('');
      }
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }, [saveScheduleStage]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 2 seconds set the show value to normal
      if (saveActualStage==='saved') {
        setSaveActualStage('');
      }
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }, [saveActualStage]);

  useEffect(() => {
    selectActionDate(selectedActionDay)
  } , [actualRecords]);


  useEffect(() => {
  } , [editEndActualTime]);

  useEffect(() => {
    checkAdditionalTimeValueChange();
  } , [additionalTimeInput]);

  
  
  useEffect(() => {
    if (editMode) {
      selectActionDate(selectedActionDay)
      scrollToBottom();
    }
  } , [editMode]);

  const nextMonth = () => {
    setSelectedDay(new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, 1));
  }

  const prevMonth = () => {
    setSelectedDay(new Date(selectedDay.getFullYear(), selectedDay.getMonth() - 1, 1));
  }

  const thisMonth = () => {
    setSelectedDay(new Date());
  }

  const selectActionDate = (dt) => {
    setSelectedActionDay(dt);
    setSaveActualButtonDisabled(false);
    setActualErrorMessage(null);
    let sch =  schedules.find(schedule => schedule.schedule_date === dtToYMD(dt));
    if (sch) {
      setEditStartTime(tzToHm(sch.start_time) + ":00");
      setEditEndTime(tzToHm(sch.end_time) + ":00");
      setScheduleType(sch.schedule_type);
      setSaveIcon('FaSave');
    } else {
      setEditStartTime("09:00:00");
      setEditEndTime("17:00:00");
      setScheduleType("w");
      setSaveIcon('FaPlus');
    }
    let actual = actualRecords.find(actual => actual.date === dtToYMD(dt));
    let actualDetail = actualTimeDetails.filter(actual => actual.date === dtToYMD(dt));
    if (actual && actualDetail.length > 0) {
      console.log("selectActionDate actual", actual);
      setDailyAllowance(actual.daily_allowance);
      setEditStartActualTime(actual.min.substring(0, 5) + ":00");
      setEditEndActualTime(actual.max.substring(0, 5) + ":00");
      setAdditionalTimeInput([]);
      if (actual.task_detail) {
        setDayTaskDescription(actual.task_detail)
      } else {
        setDayTaskDescription('')
      }
      setAdditionalTimeInput(formatActualWorkTimeList(actualTimeDetails.filter(a => a.date === dtToYMD(dt))))
    } else {
      setDailyAllowance(null);
      setEditStartActualTime("00:00:00");
      setEditEndActualTime("00:00:00");
      setAdditionalTimeInput([{id: 0, start_time: "00:00:00", end_time: "00:00:00"}])
      setDayTaskDescription('');
    }
  }

  const checkSchedule = (dt, schedules) => {
    let sch =  schedules.find(schedule => schedule.schedule_date === dtToYMD(dt));
    return sch ? dateColor(sch.schedule_type) : ''
  }

  const checkActual = (dt) => {
    let actual = actualRecords.find(actual => actual.date === dtToYMD(dt));
    return actual ? `${actual.min}-${actual.max}` : null
  }

  const checkDailyAllowance = (dt) => {
    let actual = actualRecords.find(actual => actual.date === dtToYMD(dt));
    if (actual) {
      switch(actual.daily_allowance) {
        case 'kokopäiväraha':
      return  <FaStar  className="text-warning" />;
        case 'osapäiväraha':
      return <FaStarHalf className="text-warning" />;
        default:
      return null;
      }
    } else {
      return null;
    }
  }
  const dateColor = (t) => {
    switch(t) {
      case 'w':
        return 'bg-success text-white';
      case 'h':
        return 'bg-danger text-white';
      default:
        return 'bg-success  text-white';
    }
  }
  const handleApplyToWeekdays = (e) => {
    const confirmBox = window.confirm(
      `Haluatko käyttää klo ${editStartTime}-${editEndTime}? kaikki arkipäivät samalle viikolle?`
    )
    if (confirmBox === true) {
      let toUpdateSchedules = []
      getAllWeekdays(selectedActionDay).forEach(day => {
        let sch =  schedules.find(schedule => schedule.schedule_date === dtToYMD(day));
        if (sch) {
          toUpdateSchedules.push({action: 'delete', id: sch.schedule_id});
        }
        toUpdateSchedules.push({
          action: 'add', 
          schedule_date: dtToYMD(day), 
          start_time:  `${dtToYMD(day)}T${editStartTime}:00.000Z`,
          end_time:  `${dtToYMD(day)}T${editEndTime}:00.000Z`,
          schedule_type: scheduleType,
          email: userEmail
        })
      });
      updatedSchedules(toUpdateSchedules).then((result) => {
        setSelectedDay(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), 1));
      });
    }
  }

  const handleCopyWeek = (week) => {
    setCopiedWeek(week);
  }

  const handleClickDailyAllowance = (e) => {
    let new_value = "x"
    // To make null case click same button again
    if (e.target.value == 'kokopäiväraha' ) {
      new_value = dailyAllowance == 'kokopäiväraha'  ?  null : 'kokopäiväraha'
    } else if (e.target.value == 'osapäiväraha') {
      new_value = dailyAllowance == 'osapäiväraha'  ?  null : 'osapäiväraha'
    } else if (e.target.value == '') { 
      new_value = null;
    }
    // This is more simple but not working becase e.target.value is change from null to empty 
    // and ButtonGroup is making 2 clicks on same button.
    // new_value = dailyAllowance == e.target.value  ?  null : e.target.value
    if (new_value !== "x") {
      setDailyAllowance(new_value);
    }  
  }

  const handlePasteWeek = (week) => {
    setPasteWeek(week);
    copyScheduleWeek(selectedDay.getFullYear(), copiedWeek, week).then((result) => {
      setCopiedWeek(null);
      setSelectedDay(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), 1));
    });
  }

  const weekTr = (startDate) => {
    let dates = [];
    let maxDate = null;
    let trBgClass = '';
    dates.push(startDate);  
    for (var i = 1; i < 7; i++){
      let tempDate = addDays(startDate, i);
      dates.push(tempDate);  
      maxDate = tempDate;
    }
    if (selectedActionDay && startDate <= selectedActionDay && maxDate >= selectedActionDay) {
      trBgClass = 'bg-primary-200';
    } 
    return <tr className={trBgClass}>
            <td>
              {editMode 
                ? <>
                  {copiedWeek 
                    ? <>
                      {copiedWeek === weekNumber(startDate) 
                      ? <span className="text-primary text-sm">{weekNumber(startDate)}</span>
                      : <button 
                        className='btn btn-sm btn-success text-white'
                        onClick={() => handlePasteWeek(weekNumber(startDate))} > 
                        <FaPaste />{weekNumber(startDate)}</button>
                      }
                      </>
                    : <button className='btn btn-sm btn-danger text-white'
                    onClick={() => handleCopyWeek(weekNumber(startDate))} > <FaCopy />{weekNumber(startDate)}</button>
                  }</>
                :  <span className="text-primary text-sm">{weekNumber(startDate)}</span>
              }
            </td>
            {dates.map((d) => (
            <td className={'text-end position-relative ' + (selectedActionDay && (dtToDMY(d)===dtToDMY(selectedActionDay)) ? 'bg-primary' : '')}  >
              <span 
                className={d.getMonth()===selectedDay.getMonth() ? (checkSchedule(d, schedules) + ' p-1 rounded') : ' text-secondary rounded'}
                style={{cursor:'pointer'}}
                onClick={() => selectActionDate(d)}
              >
                {d.getDate().toString()}
              </span>
              {checkDailyAllowance(d) && <span className="position-absolute top-0 end-0">{checkDailyAllowance(d)}</span>}
              {checkActual(d) ? <div className="text-xs position-absolute top-0 end-0 p-0">{checkActual(d)}</div> : null}
            </td>))}
    </tr>
  }

  const handleDeleteClick = ()  => {
    const confirmBox = window.confirm(
      `Haluatko poistaa ${dtToDMY(selectedActionDay)}?`
    )
    if (confirmBox === true) {
      let sch = schedules.find(schedule => schedule.schedule_date === dtToYMD(selectedActionDay));
      if (sch) {
        updatedSchedules([{action: 'delete', id: sch.schedule_id}]).then((result) => {
          setSchedules(schedules.filter(element => element.schedule_date !== dtToYMD(selectedActionDay)));
      }) }  
    }
  }

  const handleDeleteActualClick = ()  => {
    const confirmBox = window.confirm(
      `Haluatko poistaa Toteutunut aika, PVM: ${dtToDMY(selectedActionDay)}?`
    )
    if (confirmBox === true) {
      let params = {
        date: dtToYMD(selectedActionDay),
        start_time: `${dtToYMD(selectedActionDay)} 00:00:00`,
        end_time: `${dtToYMD(selectedActionDay)} 00:00:00`,
        daily_allowance: null,
      }
      saveWorkActual(params).then((result) => {
        setActualRecords([...actualRecords.filter(element => element.date !== dtToYMD(selectedActionDay))]);
        setDailyAllowance(null);
        setEditStartActualTime("00:00:00");
        setEditEndActualTime("00:00:00");
      });
    }
  }

  const checkAdditionalTimeValueChange = () => {
    let prev_end = null;
    let errorMessage = null;
    additionalTimeInput.forEach((item) => {
      if (prev_end) {
        if (prev_end > item.start_time) {
          errorMessage = `Aloitusaika pitää suurempi kuin edellisen lopetusaika (${prev_end.substring(0, 5)} ja ${item.start_time.substring(0, 5)})`;
          return false;
        }
      }
      if (item.start_time >= item.end_time) {
        errorMessage = `Lopetusaika pitää suurempi kuin aloitusaika (${item.start_time.substring(0, 5)} ja ${item.end_time.substring(0, 5)}))`;
        return false;
      }

      // Check if start_time and end_time can bo in the future 
      const today = new Date();
      //  compareDate is selectedActionDay at start_time
      let now = getFormatTimestamp('');
      let compareDateStart = selectedActionDay.setHours(item.start_time.substring(0, 2), item.start_time.substring(3, 5), 0, 0);
      compareDateStart = getFormatTimestamp(compareDateStart)
      let compareDateEnd = selectedActionDay.setHours(item.end_time.substring(0, 2), item.end_time.substring(3, 5), 0, 0);
      compareDateEnd = getFormatTimestamp(compareDateEnd)

      // if (compareDateStart[1] >= now[1] || compareDateEnd[1] >= now[1]) {
      if (stringDtFiToDt(compareDateStart[1]) >= stringDtFiToDt(now[1]) || stringDtFiToDt(compareDateEnd[1]) >= stringDtFiToDt(now[1])) {
        errorMessage = `Aika ei voi olla tulevaisuudessa`;
      }
      prev_end = item.end_time;
    });
    if (errorMessage) {
      setActualErrorMessage(errorMessage);
      setSaveActualButtonDisabled(true);
    } else {
      setActualErrorMessage(null);
      setSaveActualButtonDisabled(false);
    }
  }

  const handleAdditionalTimeChange = (e, id, type) => {
    // change value in additionalTimeInput to t.target.value by id 
    setAdditionalTimeInput(additionalTimeInput.map((item) => {
      if (item.id === id) {
        item[type] = e.target.value;
      } else {
        item[type] = item[type];
      }
      return item;
    }));
  }


  const handleAdditionalTimeDelete = (id) => {
    let additionalTime = additionalTimeInput.find(t => t.id === id);
    // Delete from additionalTimeInput
    if (id === 0) {
      handleDeleteActualClick();
    } else {
      setAdditionalTimeInput(additionalTimeInput.filter(element => element.id !== id));
    }
    setSaveActualButtonDisabled(false);
  }

  const addActualTimeInput = () => {
    let new_inputs = [];
    if (additionalTimeInput.length < 1) {
      new_inputs = [{id: 0, start_time: `${editStartActualTime.substring(0, 5)}:00`, end_time: `${editEndActualTime.substring(0, 5)}:00`}];
    } else {
      new_inputs = additionalTimeInput
    }
    setAdditionalTimeInput([...new_inputs, {id: new_inputs.length, start_time: "00:00:00", end_time: "00:00:00"}]);
    setSaveActualButtonDisabled(true);
  }

  const handleMoveTime = (move, id) => {
    console.log("handleMoveTime", move, id);
    // move up or down time input in additionalTimeInput
    let new_inputs = additionalTimeInput.map((item) => {
      if (move === 'up' && item.id === id) {
        item.id = item.id - 1;
      } else if (move === 'down' && item.id === id) {
        item.id = item.id + 1;
      } else if (move === 'up' && item.id === id - 1) {
        item.id = item.id + 1;
      } else if (move === 'down' && item.id === id + 1) {
        item.id = item.id - 1;
      } else {
        item.id = item.id;
      }
      return item;
    });
    new_inputs.sort((a, b) => a.id - b.id);
    setAdditionalTimeInput(new_inputs);
  }

  const saveActual = () => {
    if (additionalTimeInput.length > 0 && additionalTimeInput[0].end_time.substring(0, 5) === '00:00') {
      console.log('skip save end_time is 00:00',);
      return;
    } 
    setSaveActualStage('saving');
    let params = {
      date: dtToYMD(selectedActionDay),
      start_time: `${dtToYMD(selectedActionDay)} ${editStartActualTime.substring(0, 5)}:00`,
      end_time: `${dtToYMD(selectedActionDay)} ${editEndActualTime.substring(0, 5)}:00`,
      daily_allowance: dailyAllowance,
      additional_time: additionalTimeInput,
      day_task_description: dayTaskDescription,
      location: latLong.join(','),
    }
    saveWorkActual(params).then((result) => {
      console.log('saveActual saveWorkActual result', result.status);

      let newTimeDetails = actualTimeDetails.filter(element => element.date !== dtToYMD(selectedActionDay));
      console.log('saveActual newTimeDetails', newTimeDetails);
      additionalTimeInput.forEach((item) => {
        newTimeDetails.push({date: dtToYMD(selectedActionDay), time: item.start_time.substring(0, 5), record_type: 'start'});
        newTimeDetails.push({date: dtToYMD(selectedActionDay), time: item.end_time.substring(0, 5), record_type: 'stop'});
      });
      setActualTimeDetails(newTimeDetails);
      let start = '00:00';
      let end = '00:01';
      console.log('after saveWorkActual additionalTimeInput:', additionalTimeInput);
      if (additionalTimeInput.length > 0 && additionalTimeInput[0].start_time) {
        // Start is frist start_time in additionalTimeInput
        start = additionalTimeInput[0].start_time.substring(0, 5);
        // End is last end_time in additionalTimeInput
        end = additionalTimeInput[additionalTimeInput.length-1].end_time.substring(0, 5);
      } else {
        console.log('saveActual add more input ??');
        setAdditionalTimeInput([{id: 0, start_time: `${editStartActualTime}:00`, end_time: `${editEndActualTime}:00`}]);
        start = editStartActualTime;
        end = editEndActualTime;
      }
      if (result.status === 'added') {
        console.log('saveActual added Case A');
        setActualRecords([...actualRecords, {
          date: dtToYMD(selectedActionDay), 
          min: start, 
          max: end, 
          daily_allowance: dailyAllowance,
          task_detail: dayTaskDescription,
        }]);
      } else {
        console.log('saveActual Case B');
        setActualRecords(actualRecords.map(record => {
          if (record.date === dtToYMD(selectedActionDay)) {
            return {
              date: dtToYMD(selectedActionDay),
              min: start,
              max: end,
              daily_allowance: dailyAllowance,
              task_detail: dayTaskDescription,
            }
          }
          return record;
        }));
      }
      setSaveActualStage('saved');
    });
  }

  const saveSchedule = () => {
    setSaveScheduleStage('saving');
    let sch = schedules.find(schedule => schedule.schedule_date === dtToYMD(selectedActionDay));
    if (sch) {
      let params = {action: 'edit', id: sch.schedule_id, start_time: editStartTime, end_time: editEndTime, schedule_type: scheduleType}
      updatedSchedules([params]).then((result) => {
        setSaveIcon('FaSave');
        setSchedules(schedules.map(element => {
          if (element.schedule_date === dtToYMD(selectedActionDay)) {
            element.start_time = `${dtToYMD(selectedActionDay)}T${editStartTime}:00.000z`;
            element.end_time = `${dtToYMD(selectedActionDay)}T${editEndTime}:00.000z`;
            element.schedule_type = scheduleType;
          }
          return element;
        }));
        setSaveScheduleStage('saved');
      });
    } else {
      let params = {
        action: 'add', 
        schedule_date: dtToYMD(selectedActionDay), 
        start_time:  `${dtToYMD(selectedActionDay)}T${editStartTime}:00.000Z`,
        end_time:  `${dtToYMD(selectedActionDay)}T${editEndTime}:00.000Z`,
        schedule_type: scheduleType,
        email: userEmail
      }
      updatedSchedules([params]).then((result) => {
        setSaveIcon('FaSave');
        setSchedules(schedules.concat(result.result));
        setSaveScheduleStage('saved');
      });
    } 
  }  
  
  const saveButton = (action) => {
    switch(action) {
      case 'saving':
        return <><PulseLoader color={'white'} loading={true} css={''} size={12}/></>
      case 'saved':
        return <><FaCheck className="text-white" /> Tallennettu</>
      case 'add':
        return <><FaPlus className="text-white" /> Lisää aika {latLong.join('') !== '00' ? <MdLocationOn /> : <MdLocationOff /> } </>
      default:
        return <><FaSave className="text-white" /> Tallenna {latLong.join('') !== '00' ? <MdLocationOn /> : <MdLocationOff /> } </>
    }
  }

  const buttonMove = (move, id) => {
    if (move === 'down') {
      return <div><button type="button" className={`btn btn-outline-primary btn-sm me-1 px-1`} 
                     onClick={() => handleMoveTime("down", id)}>
                      <FaArrowDown /></button></div>
    } else if (move === 'up') {
      return <div className={'text-end'} ><button type="button" className={`btn btn-outline-primary btn-sm me-1 px-1`}
                      onClick={() => handleMoveTime("up", id)}>
                      <FaArrowUp /></button></div>
    } else if (move === 'both') {
      return <div className={'d-flex'} ><button type="button" className={`btn btn-outline-primary btn-sm me-1 px-0`} 
                      onClick={() => handleMoveTime("down", id)}>
                      <FaArrowDown /></button>
              <button type="button" className={`btn btn-outline-primary btn-sm px-0 me-1`}
                      onClick={() => handleMoveTime("up", id)}>
                      <FaArrowUp /></button></div>
    }
  }


  const buttonGroupTag = (name) => {
    if (name === 'FaSave') {
      return (<>
        <button 
          onClick={handleApplyToWeekdays} 
          className='btn btn-sm btn-outline-primary me-1 work_calendar_time'>
          <FaCalendarWeek />
        </button>
        <button type="button"
          onClick={() => handleDeleteClick()}
          className="btn btn-danger btn-sm text-white me-1 work_calendar_time">
          <FaTrashAlt />
        </button>  
        <button type="button"
          onClick={() => saveSchedule()}
          className="btn btn-primary btn-sm text-white me-1 work_calendar_time">
          {saveButton(saveScheduleStage)}
        </button>  
      </>)
    } else if (name === 'FaPlus') {
      return (<button type="button"
        onClick={() => saveSchedule()}
        className="btn btn-primary btn-sm text-white me-1 work_calendar_time">
        {saveButton('add')}
        </button> )
    }
  }
  
  const buttonGroupActualTag = (actual) => {
    if (actual) {
      return (<div>
        <button type="button"
          disabled={saveActualButtonDisabled}
          onClick={() => addActualTimeInput()}
          className="btn btn-success btn-sm text-white me-1 work_calendar_time">
          <FaPlus /> Lisää toinen aika
        </button>  
        <button type="button"
          disabled={saveActualButtonDisabled}
          onClick={() => saveActual()}
          className="btn btn-primary btn-sm text-white me-1 work_calendar_time">
          {saveButton(saveActualStage)}
        </button>  
      </div>)
    } else {
      return (<button type="button"
        disabled={saveActualButtonDisabled}
        onClick={() => saveActual()}
        className="btn btn-primary btn-sm text-white me-1 work_calendar_time">
        {saveButton('add')} 
        </button> )
    }
  }

  const dayDetail = (dt, schedules) => {
    let sch =  schedules.find(schedule => schedule.schedule_date === dtToYMD(dt));
    let actual = actualRecords.find(actual => actual.date === dtToYMD(dt));
    const SaveIconComponent =  `${saveIcon}`;
    return (<><div className="d-flex justify-content-between align-items-center ">
      <div>
       <b className='text-primary'>PVM: {dtToDMY(dt)} </b>
      </div>
      </div>
      <div className="w-100">
        <table className="table table-sm table-striped mb-2">
          <tbody>
            { dt && <tr>
              <td >
                <div className="d-flex justify-content-between align-items-center w-100  my-1">
                  <span className="text-primary">Tavoite aika:</span>
                  <button type="button"
                      aria-expanded={openSchedule}
                      onClick={() => setOpenSchedule(!openSchedule)}
                      className={`btn btn-outline-primary btn-sm ms-2`}>
                      {!openSchedule 
                      ? <><FaAngleDoubleDown /> Näytä</> 
                      : <><FaAngleDoubleUp /> Piilota</>}
                  </button>     
                </div>
                <Collapse in={openSchedule}>
                {editMode 
                  ?<div  className=''>
                    <div className='row align-items-center w-100' >
                      <div className='col-12 col-lg-3 d-flex align-items-center justify-content-between my-2'>
                        <input  
                          type="time" 
                          value={editStartTime}
                          onChange={(e) => setEditStartTime(e.target.value)}
                          className='flex-fill work_calendar_time'/>
                        <input  
                          type="time" 
                          value={editEndTime}
                          onChange={(e) => setEditEndTime(e.target.value)}
                          className='flex-fill work_calendar_time ms-1'/>
                      </div>
                      <div className='col-12 col-lg-6 my-2'>
                        <ButtonGroup className='my-1'>
                          {radioScheduleTypes.map((radio, idx) => (
                            <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={radio.class}
                            name="radio"
                            value={radio.value}
                            checked={scheduleType === radio.value}
                            onChange={(e) => setScheduleType(e.currentTarget.value)}
                            className="btn-sm work_calendar_time"
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div className='col-12 col-lg-3 text-end my-2'> 
                        {buttonGroupTag(saveIcon)}
                      </div>
                    </div> 
                   </div>
                  :<span>
                        {sch && tzToHm(sch.start_time) + ' - ' + tzToHm(sch.end_time)}
                        {sch 
                          && <button className={`btn btn-sm ms-1 ${(scheduleType === 'w') ? 'btn-success' : 'btn-danger'}`}>
                              {scheduleType && (scheduleType === 'w' ? 'Työpäivä' : 'Loma')}
                             </button>}
                    </span>
                  }</Collapse>
              </td>
            </tr>}
            { dt && <tr>
              <td>
                <div className="d-flex justify-content-between align-items-center w-100 my-1">
                  <span className="text-primary">Toteutunut aika: {additionalTimeInput.length}</span>
                  <button type="button"
                      aria-expanded={openActual}
                      onClick={() => setOpenActual(!openActual)}
                      className={`btn btn-outline-primary btn-sm ms-2`}>
                      {!openActual 
                      ? <><FaAngleDoubleDown /> Näytä</> 
                      : <><FaAngleDoubleUp /> Piilota</>}
                  </button>     
                </div>
                <Collapse in={openActual}>
                {editMode 
                  ? <div className=''>
                      <div className='row align-items-center'>
                        <div className='col-12'>
                          {additionalTimeInput.length < 1 &&
                          <div className='actual_time_input d-flex align-items-center justify-content-between my-2'>
                            <input  
                              type="time" 
                              value={editStartActualTime}
                              onChange={(e) => setEditStartActualTime(e.target.value)}
                              className='flex-fill work_calendar_time'/>
                            <input  
                              type="time" 
                              value={editEndActualTime}
                              onChange={(e) => setEditEndActualTime(e.target.value)}
                              className='flex-fill work_calendar_time ms-1'/>
                            <button type="button"
                              onClick={() => handleDeleteActualClick()}
                              className="btn btn-danger btn-sm text-white ms-1 work_calendar_time">
                              <FaTrashAlt />
                              <span> {diffTimeToHMString(editEndActualTime.substring(0,5), editStartActualTime.substring(0,5))}</span>
                            </button> 
                          </div>}
                          {additionalTimeInput.map((input, idx) => {
                            return (
                              <div key={idx} className='actual_time_input d-flex align-items-center justify-content-between my-2'>
                                {additionalTimeInput.length > 1 && (
                                  idx === 0 
                                  ? buttonMove('down', input.id) 
                                  : (idx === additionalTimeInput.length - 1 
                                    ? buttonMove('up', input.id) 
                                    : buttonMove('both', input.id))
                                )}                                
                                <input  
                                  type="time" 
                                  value={input.start_time}
                                  onChange={(e) => handleAdditionalTimeChange(e, input.id, 'start_time')}
                                  className='flex-fill work_calendar_time'/>
                                <input  
                                  type="time" 
                                  value={input.end_time}
                                  onChange={(e) => handleAdditionalTimeChange(e, input.id, 'end_time')}
                                  className='flex-fill work_calendar_time ms-1'/>
                                <button type="button"
                                  onClick={() => handleAdditionalTimeDelete(input.id)}
                                  className="btn btn-danger btn-sm text-white ms-1 work_calendar_time">
                                  <FaTrashAlt />
                                  <span> {diffTimeToHMString(input.start_time,input.end_time)}</span>
                                </button> 
                              </div>
                            ) 
                          })}
                        </div>
                        <div className='col-12 col-lg-4 my-2'>
                          <ButtonGroup toggle>
                            {radioAllowanceTypes.map((radio, idx) => (
                              <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant={radio.class}
                                name="radio"
                                value={radio.value}
                                checked={dailyAllowance === radio.value}
                                onClick={handleClickDailyAllowance}
                                className="btn-sm work_calendar_time"
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </div>
                        <div className='col-12 col-lg-5 text-end my-2'>
                          <textarea
                            rows="2"
                            className="form-control no-shadow-btn  mb-1 input, image, both"
                            placeholder='Tehtäväkuvaus'
                            value={dayTaskDescription}
                            onChange={(e) => setDayTaskDescription(e.target.value)}
                          />
                        </div>
                        <div className='col-12 col-lg-3 text-end my-2'>
                          {actualErrorMessage && <span className='text-danger'>{actualErrorMessage}</span>}
                          {buttonGroupActualTag(actual)}
                        </div>
                    </div>
                    </div>
                  : <span>
                    {actual && actual.min + ' - ' + actual.max}
                    {(actual && actual.daily_allowance)
                      && <button className={`btn btn-sm ms-1 btn-primary text-white`}>
                          {capitalizeFirstLetter(actual.daily_allowance)}
                          </button>}
                    <button className='btn btn-warning text-white btn-sm ms-2' onClick={() => setEditMode(true)}  >
                      <FaEdit />Korjaus
                    </button>     
                    </span>}
                  </Collapse>
              </td>
            </tr>}
          </tbody>
          </table>
      </div>  
      </>)
  }
 

  return (
  <div>
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="text-primary my-3">Työaikataulu</h4>
      <button type="button"
          onClick={() => setEditMode(!editMode)}
          className={`btn text-white ${editMode ? 'btn-danger' : 'btn-warning'}`}>
            {editMode 
            ? <><FaPause /> Poistu muokkaustilasta</> 
            : <><FaEdit /> Muokkaa aikatauluja</>}
        </button>   
    </div>
    <div className="table-responsive">  
      <table className="table table-striped table-borderless table-hover">
        <thead>
          <tr>
            <th colSpan={8} >
              <div className='d-flex justify-content-between'>
                <div className='text-primary'>{dtToMonthYear(selectedDay)}</div>
                <div>
                  <button className='btn btn-sm btn-primary text-white ms-1' onClick={prevMonth} >{'<'}</button>
                  <button className='btn btn-sm btn-primary text-white ms-1' onClick={thisMonth}>{'Tänään'}</button>
                  <button className='btn btn-sm btn-primary text-white ms-1' onClick={nextMonth}>{'>'}</button>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th>
            {copiedWeek 
              &&<button className='btn btn-sm btn-danger text-white' onClick={()=>setCopiedWeek(null)}><FaTimes />Peruuta kopiointi</button>}
            </th>
            {['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'].map((d) => (<th className="text-end">{d}</th>))}
          </tr>
        </thead>
        <tbody>
          { schedules && weekTr(firstDayOfMonth(selectedDay)) }
          { schedules && weekTr(addDays(firstDayOfMonth(selectedDay),7)) }
          { schedules && weekTr(addDays(firstDayOfMonth(selectedDay),14)) }
          { schedules && weekTr(addDays(firstDayOfMonth(selectedDay),21)) }
          { schedules && weekTr(addDays(firstDayOfMonth(selectedDay),28)) }
          { schedules && weekTr(addDays(firstDayOfMonth(selectedDay),35)) }
        </tbody>
      </table>
    </div>
    <div className="table-responsive mb-2">
      {selectedActionDay && dayDetail(selectedActionDay, schedules)}
    </div>
  </div>);
}

