import React , { useState, useEffect }from "react";
import {getCurrentUser} from "../services/authService";
import {WorkCalendar} from "../components/schedules/WorkCalendar.jsx";
import {FaAngleDoubleDown, FaAngleDoubleUp} from 'react-icons/fa';
import { Button, Collapse } from 'react-bootstrap';

const UserProfile = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className='container'>
      <div className="d-flex justify-content-start align-items-center">
        <h4 className="text-primary my-3">Käyttäjätiedot</h4>
        <button type="button"
            aria-controls="collapse-profile"
            aria-expanded={open}
            onClick={() => setOpen(!open)}
            className={`btn btn-outline-primary btn-sm ms-2`}>
            {!open 
            ? <><FaAngleDoubleDown /> Näytä</> 
            : <><FaAngleDoubleUp /> Piilota</>}
        </button>   
      </div>
        <Collapse in={open}>
            <div className="table-responsive" id="collapse-profile">
                <table className="table table-striped table-borderless table-hover">
                    <thead>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Nimi</td>
                        <td>{getCurrentUser().fullname}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{getCurrentUser().email}</td>
                    </tr>
                    <tr>
                        <td>Puhelin</td>
                        <td>{getCurrentUser().phone}</td>
                    </tr>
                    <tr>
                        <td>Roolit</td>
                        <td>{getCurrentUser().business_roles?.join(', ')}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Collapse>
        <WorkCalendar email={getCurrentUser().email} />
    </div>
  );
};

export default UserProfile;
