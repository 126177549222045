import React , { useState, useEffect }from "react";
import {getCurrentUser} from "../../services/authService";
import {getInvoices, deleteInvoice, netvisorStatusText} from "../../services/invoiceService";
import { ScaleLoader } from "react-spinners";
import Table from 'react-bootstrap/Table';
import { FaEdit, FaTrashAlt, FaPlus, FaEye } from "react-icons/fa";
import { dayForInput } from "../../services/formatService";
import InvoiceModalForm from "./InvoiceModalForm";
import Modal from "react-modal"

const Invoice = props => {
  const [userEmail, setUserEmail] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(dayForInput());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('new');
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [city, setCity] = useState(null);
  const [iban, setIban] = useState(null);
  const [postNumber, setpostNumber] = useState(null);
  const [addressLine, setAddressLine] = useState(null);
  const [reloadList, setReloadList] = useState(0);


  useEffect(() => {
    scrollToTop();
    setUserEmail(getCurrentUser().email)
    let d = new Date();
    d.setMonth(d.getMonth() - 2);
    setStartDate(dayForInput(d));
  }, [props]);


  useEffect(() => {
    if (userEmail && startDate && endDate) {
      setLoading(true);
      getInvoices(userEmail, startDate, endDate).then((result)=>{
        if (result && result.message === 'success') {
          setInvoices(result.data);
          if (result.data && result.data[0]) {
            setCity(result.data[0].buyer.city)
            setIban(result.data[0].buyer.iban)
            setAddressLine(result.data[0].buyer.address_line)
            setpostNumber(result.data[0].buyer.post_number)
          } 
        } 
        setLoading(false);
      });
    }
  }, [userEmail, startDate, endDate, reloadList]);


  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  const containerDivStyle = {
    fontSize: '12px',
    minHeight: '100vh'
  };


  const editInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setModalType('edit');
    setIsModalOpen(true);
  }

  const newInvoice = () => {
    setSelectedInvoice(null);
    setModalType('new');
    setIsModalOpen(true);
  }
  const handleDelete = (id) => {
    const confirmBox = window.confirm(
      "Haluatko poistaa?"
    )
    if (confirmBox === true) {
      deleteInvoice(id).then((result) => {
        console.log("deleteInvoice result", result)
        setReloadList(reloadList + 1)
      })
    }
  }

  const afterOpenModal = () => {}
  
  const afterCloseModal = () => {}

  const closeModal = () => {
    setIsModalOpen(false);
    setReloadList(reloadList + 1)
  }

  const modalStyles = {
    content: {
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      overlay: {zIndex: 1060}
    },
  };

  return (
    <div className='container mh' style={containerDivStyle}>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="text-primary my-3">Kululasku</h1>
        <button
              type="button"
              className="btn btn-secondary text-white rounded-pill me-2 text-nowrap "
              onClick={newInvoice}
            >
                <span className="fs-12 me-1 d-flex align-items-center">
                  <FaPlus />&nbsp;&nbsp;Uusi lasku
                </span>
              </button>          
      </div>
      <div className="container bg-white">
        {errorText &&  <div className="text-danger">{errorText}</div>}
        <div className="d-flex align-items-center ">
          <input
            type="date"
            className="form-control no-shadow-btn"
            name="invoiceStartDate"
            id="invoiceStartDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            />
          <input
            type="date"
            className="form-control no-shadow-btn"
            name="invoiceEndDate"
            id="invoiceEndDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            />
        </div>
        {invoices && <Table hover size="sm">
            <thead>
              <tr>
                <th></th>
                <th >Pvm</th>
                <th className="hide-on-mobile-nav">lasku</th>
                <th style={{ textAlign: "right" }}>€</th>
                <th >Ostopaikka</th>
                <th className="">Tila</th>
                <th className="">Netvisor</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
                  <tr>
                    <td colSpan="9">
                      <div className={"w-100 text-center"}>
                        <ScaleLoader
                          color={"blue"}
                          loading={true}
                          css={""}
                          size={150}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  invoices.slice(0, 50).map((invoice, idx) => (
                  <tr key={idx}>
                      {(invoice.netvisor_id && invoice.status === 'confirm') ?
                        <td>
                          <button type="button"
                            className="btn btn-secondary text-white btn-sm me-1 mt-1"
                            onClick={() => editInvoice(invoice)}>
                            <FaEye />
                          </button> 
                        </td> : 
                        <td>
                          <button
                            type="button"
                            className="btn btn-warning text-white btn-sm me-1 mt-1"
                            onClick={() => editInvoice(invoice)}
                            >
                            <FaEdit /> 
                          </button>
                          <button type="button"
                            onClick={() => handleDelete(invoice.id)}
                              className="btn btn-danger text-white btn-sm me-1 mt-1">
                              <FaTrashAlt />
                          </button> 
                        </td>
                      }
                    <td > {invoice.invoice_date}</td>
                    <td className="hide-on-mobile-nav"> {invoice.invoice_number}</td>
                    <td style={{ textAlign: "right" }}>{invoice.amount.toLocaleString("fi-FI",{minimumFractionDigits: 2})}</td>
                    <td>{invoice.shop_name}</td>
                    <td className="">{invoice.status}</td>
                    <td className="">{invoice.netvisor_id}/{netvisorStatusText(invoice.netvisor_approval_status)}</td>
                  </tr>
                  ))
                )}
            </tbody>
          </Table>}
          {true && <Modal
            isOpen={isModalOpen}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            onRequestClose={closeModal}
            contentLabel={`Ajettu Kokonaisaika`}
            ariaHideApp={false}
            style={modalStyles}
            >
            <InvoiceModalForm 
              closeModal={closeModal} 
              modalType={modalType}
              invoice={selectedInvoice}
              city={city}
              postNumber={postNumber}
              iban={iban}
              addressLine={addressLine}
            />
          </Modal>}
      </div>
    </div>
  );
};

export default Invoice;
