import React from "react";
import { Switch, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Inspection from "./components/inspections/Inspection";
import InspectionDetail from "./components/inspections/InspectionDetail";
import InspectionStart from "./components/inspections/InspectionStart";
import InspectionItemPush from "./components/inspections/InspectionItemPush";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import UserProfile from "./pages/UserProfile";
import InspectionGallery from "./pages/InspectionGallery";
import GalleryItem from "./components/galleries/GalleryItem";
import SalesGalleryItem from "./components/galleries/SalesGalleryItem";
import SalesImagesMain from "./components/sales_images/SalesImagesMain";
import Task from "./components/tasks/Task";
import TaskItem from "./components/tasks/TaskItem";
import TaskLogger from "./components/tasks/TaskLogger";
import TaskMap from "./components/tasks/TaskMap";
import Report from "./components/reports/Report"
import Invoice from "./components/invoices/Invoice"

function App() {
  const { pathname } = useLocation();

  return (
    <>
      <Navbar pathname={pathname} />
      <Switch>
        <ProtectedRoute
          key = "route1"
          path="/admin/tasks"
          component={Task}
        />
        <ProtectedRoute
          key = "route2"
          path="/admin/task/:id"
          component={TaskItem}
        />
        <ProtectedRoute
          key = "route3"
          path="/admin/task_logger/:id"
          component={TaskLogger}
        />
        <ProtectedRoute
          key = "route4"
          path="/admin/task_map/:id"
          component={TaskMap}
        />
        <ProtectedRoute
          key = "route5"
          path="/admin/inspections"
          component={Inspection}
        />
        <ProtectedRoute
          key = "route6"
          path="/admin/inspection_form"
          component={InspectionDetail}
        />
        <ProtectedRoute
          key = "route7"
          path="/admin/inspection_start"
          component={InspectionStart}
        />
        <ProtectedRoute
          key = "route8"
          path="/admin/inspection/:id/:task/:page"
          component={InspectionItemPush}
        />
        <ProtectedRoute
          key = "route9"
          path="/admin/cosmetic-remarks/:id"
          component={GalleryItem}
        />
        <ProtectedRoute
          key = "route10"
          path="/admin/cosmetic-remarks"
          component={InspectionGallery}
        />
        <ProtectedRoute 
          key = "route11"
          path="/admin/salesimages" component={SalesImagesMain} />

        <ProtectedRoute
          key = "route12"
          path="/admin/salesimage/:id"
          component={SalesGalleryItem}
        />
        <ProtectedRoute 
          key = "route13"
          path="/admin/user" component={UserProfile} />
        <ProtectedRoute 
          key = "route14"
          path="/admin/report" component={Report} />
        <ProtectedRoute 
          key = "route15"
          path="/admin/invoice" component={Invoice} />
        <ProtectedRoute 
          key = "route16"
          path="/admin" component={Task} />
        <ProtectedRoute 
          key = "route17"
          path="/home" component={Home} />
      </Switch>
      <Login />
      <Footer />
    </>
  );
}

export default App;
