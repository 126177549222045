import React , {useState}  from 'react';
import Select from 'react-select';
import {checkPlateUrl, apiRequest, createUrl, getGalleryIdFormInspection} from '../../services/inspectionService';
import auth from "../../services/authService";
import { withRouter } from 'react-router-dom';
import {InputGroup , FormControl, Button} from 'react-bootstrap'
import {FaSave, FaSearch, FaClipboardCheck, FaImages, FaRuler} from  "react-icons/fa";
import { ScaleLoader } from "react-spinners";
import {saveTaskInspection} from '../../services/logisticsService';

const InspectionStartForm = props => {

  const currentUser = auth.getCurrentUser();
  const email = currentUser ? currentUser.email : null;
  const inputClasses = "form-control border-start-0 border-end-0 border-top-0 rounded-0 no-shadow-btn";
  const [loading, setLoading] = useState(false);
  const [formParams, setFormParams] = useState(() => {
    if (props.id) {
      return {
        plate: props.plate,
        make: props.make,
        model: props.model,
        vm: props.vm,
        mileage: props.mileage,
        info: (props.info) ? props.info : email,
        place_name: props.place_name,
        phase_name: props.phase_name,
        taskId:  props.taskId,
      }
    } else {
      return {
        place: props.place,
        phase: props.phase,
        plate: props.plate,
        make: null,
        model: null,
        vm: null,
        mileage: null,
        info: email,
        place_name: null,
        phase_name: null,
        placeOptions: props.placeOptions,
        taskId:  props.taskId
      }
    }
});

  let phaseOptions = null
  let phaseFilteredOptions = null
  if (props.places) {
      phaseOptions = props.places.map((p) => {
      return {
        label: p.attributes.name,  
        link: p.id,
        options: p.attributes.check_phases.data.map((s) => {
          return { label: `${s.attributes.step} ${s.attributes.name}` , value: s.id, link: p.id}
        })
      }
    })
    phaseFilteredOptions = phaseOptions.filter((o) => o.link === formParams.place)
    if (formParams.phase) {
      phaseFilteredOptions = phaseFilteredOptions[0].options.filter(option => option.value === formParams.phase)
    }
  } 
    
  const checkPlate = async (plate) => { 
    setLoading(true);
    apiRequest(checkPlateUrl(plate)).then((result) => {  
      console.log(result);
      if (result && result.type === "vehicle_datum") {
        const info = result.attributes
        setFormParams({ ...formParams, make: info.make });
        setFormParams({ ...formParams, model: info.model });
        setFormParams({ ...formParams, vm: info.model_year });
      } else {
        setFormParams({ ...formParams, make: "-" });
        setFormParams({ ...formParams, model: "-" });
        setFormParams({ ...formParams, vm: "-" });
      }
      setLoading(false);
    });
  }

  const handleChange = (e) => {
    setFormParams({ ...formParams, [e.target.name]: e.target.value });
  };

  const handleChangePlate = (plate) => {
    checkPlate(plate);
  }

  const handleChangeSelect = (selector, event) => {
    if (event) { 
      if (selector==="place") { clearSelected("phase") }
      setFormParams({ ...formParams, [selector]: event.value }) 
    } else {
      clearSelected(selector);
    } ;
  };

  const clearSelected = (selector) => {
    setFormParams({ ...formParams, [selector]: null});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const {place, phase, plate,  mileage} = event.target.elements

    let body = {
      place_id: place.value,
      phase_id: phase.value,
      plate: plate.value,
      mileage: mileage.value
    }

    //  In case after create , navigate to edit page 
    // apiRequest(createUrl(),"POST", body).then((result) => {  
    //   console.log(result);
    //   props.history.push(
    //     {
    //       pathname: "/admin/inspection_start",
    //       state: {
    //         displayType: "edit" ,
    //         plate: result.attributes.plate,
    //         place_name: result.attributes.place_name,
    //         phase_name: result.attributes.phase_name,
    //         make: result.attributes.make,
    //         model: result.attributes.model,
    //         vm: result.attributes.model_year,
    //         mileage: result.attributes.mileage,
    //         id: result.id,
    //         info: result.attributes.info,
    //       }
    //     }
    //   )
    // });

    //  In case after create, navigate to start Inspect immdietly
    apiRequest(createUrl(),"POST", body).then((result) => {  
      localStorage.setItem('currentInspection', JSON.stringify(result));
      // Start Save to Logistics Task
      //  saveTaskInspection(taskId, phaseId, inspectionId, step, status = null, description = null)
      saveTaskInspection(formParams.taskId, result.attributes.phase_id, result.id, 1, 'new', 'new').then(
        (result2) => { 
          console.log("Save to Logistics Task result:", result2);
        }
      )

    }).then(() => {
      props.history.push(`/admin/inspection_form?task=${formParams.taskId}`)
    });


  }

  const handleEdit = (id) => {
    console.log("handleEdit");
    let body = {
      inspection: {
        mileage: formParams.mileage,
        info: formParams.info
      }
    }
    apiRequest(createUrl() + "/" + id, "PUT", body).then((result) => {  
      console.log(result);
    }).then(() => {
      console.log("Done Edit");
    });
  }

  const handleEditInspection = (id) => {
    let body = {
      inspection: {
        mileage: formParams.mileage,
        info: formParams.info
      }
    }
    apiRequest(createUrl() + "/" + id, "PUT", body).then((result) => {  
      localStorage.setItem('currentInspection', JSON.stringify(result));
      return result.attributes.inspection_groups.data.length
    }).then((pageAmount) => {
      let page = props.page;
      if ( props.page >= pageAmount) {
        page = props.page - 1
      } 
      let url = `/admin/inspection_form?task=${formParams.taskId}`
      if (props.page) {
        url += `&page=${page}`
      } 
      props.history.push(url)
    });
  }

  if (props.page && props.id) {
    handleEditInspection(props.id);
    return (<div className="w-100 d-flex align-items-center justify-content-center">
              <ScaleLoader
                color={"blue"}
                loading={true}
                css={""}
                size={150}
              />
    </div>);
  } else {
    return (
      <div className="w-100">
          <form  onSubmit={handleSubmit} action="#">
            <div className="form-floating ps-0 mb-2">
              { props.id
                ? <><input
                    readOnly
                    className={inputClasses}
                    id="place"
                    name="place"
                    placeholder="Toimipiste"
                    value={formParams.place_name}
                  />
                  <label htmlFor="place">Toimipiste</label></>
                : <Select
                  name="place"
                  id="place"
                  options={formParams.placeOptions}
                  onChange={event => {
                      handleChangeSelect("place", event);
                      // clearSelected("phase");
                    }}
                  placeholder='Toimipiste...'
                  isSearchable
                  isClearable
                  value={formParams.placeOptions.filter(option => option.value === formParams.place)}
                />}
            </div>
            <div className="form-floating ps-0  mb-2">
              { props.id
                ? <><input
                    readOnly
                    className={inputClasses}
                    id="phase"
                    name="phase"
                    value={formParams.phase_name}
                  /><label htmlFor="phase">Tarkistus vaihe</label></>
                : <Select
                name="phase"
                options={phaseFilteredOptions}
                onChange={event => handleChangeSelect("phase", event)}
                placeholder={`Tarkistus vaihe...`}
                isSearchable
                isClearable
                value={phaseFilteredOptions.filter(option => option.value === formParams.phase)}
              />}
            </div>

            <div className="form-floating ps-0">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Rekisterinumero"
                  aria-label="Rekisterinumero"
                  aria-describedby="basic-addon2"
                  id="plate"
                  name="plate"
                  value={formParams.plate}
                  readOnly={props.id ? true : false}
                  onChange={handleChange}
                />
                {/* <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={() => { handleChangePlate(formParams.plate) }} >
                    {loading ? (
                        <ClipLoader
                          color={"#9B9B9B"}
                          loading={loading}
                          css={""}
                          size={15}
                        />
                      ) : (
                        <FaSearch />
                      )}
                  </Button>
                </InputGroup.Append> */}
              </InputGroup>
            </div>
            <div className="form-floating ps-0">
              <input
                   type="hidden"
                  className="form-control border-start-0 border-end-0 border-top-0 rounded-0 no-shadow-btn"
                  id="make"
                  name="make"
                  onChange={handleChange}
                  value={formParams.make}
                />
              <label htmlFor="make">Merkki</label>
            </div>
            <div className="form-floating ps-0">
              <input
                   type="hidden"
                  className={inputClasses}
                  id="model"
                  name="model"
                  placeholder="Malli"
                  onChange={handleChange}
                  value={formParams.model}
                />
              <label htmlFor="model">Malli</label>
            </div>
            <div className="form-floating ps-0">
              <input
                type="hidden"
                className={inputClasses}
                id="vm"
                name="vm"
                onChange={handleChange}
                value={formParams.vm}
              />
              <label htmlFor="vm">Vuosi Malli</label>
            </div>
            <div className="form-floating ps-0">
              <input
                  type="text"
                  className={inputClasses}
                  id="mileage"
                  name="mileage"
                  onChange={handleChange}
                  value={formParams.mileage}
                />
              <label htmlFor="mileage">Mittarilukema</label>
            </div>
            <div className="form-floating ps-0">
              <input
                type="text"
                className={inputClasses}
                id="info"
                name="info"
                value={formParams.info}
                onChange={handleChange}
              />
              <label htmlFor="note">Tarkastaja / Merkintä</label>
            </div>
            <div className="w-100 my-3 d-flex justify-content-center">
            {props.id
                ? <div className="row w-100">
                  <div className="col-md-6 col-xs-12  mb-2">
                      <button 
                        type="button"  
                        onClick={() => { handleEdit(props.id) }}
                        className="btn btn-primary text-white rounded-pill me-2 mt-1 w-100 align-items-center">
                          <FaSave className="me-1" /> Tallenna
                      </button>
                  </div>
                  <div className="col-md-6 col-xs-5 mb-2">
                      <button
                      disabled={formParams.phase}
                      type="button"
                      onClick={() => { handleEditInspection(props.id) }}
                      className="btn btn-primary text-white rounded-pill me-2 mt-1 w-100"
                      >
                        <FaClipboardCheck className="me-1" /> Aloita tehtävä
                      </button>
                  </div>
                </div>
                : <input 
                  type="submit" 
                  value={`Aloita tehtävä`} 
                  disabled={!formParams.phase}
                  className="btn btn-primary text-white rounded-pill d-flex align-items-center" />} 
            </div>
          </form>   

        </div>
        
    );

  }
};


export default withRouter(InspectionStartForm);